<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <section class="c-section bg-white1 d-flex justify-center">
            <div class="c-contanier">
                <v-row>
                    <v-col
                        cols="12"
                        lg="10"
                        class="d-flex mx-auto"
                    >
                        <!-- terms -->
                        <div class="terms">
                            <div class="terms__title-1 le-heading4 text-orange1">
                                {{ $t('privacy.pageTitle') }}
                            </div>

                            <div class="terms__pra">
                                {{ $t('privacy.text') }}<br />
                                {{ $t('privacy.text2') }}
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">{{ $t('privacy.pra.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra.titleText') }}</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    {{ $t('privacy.pra.text') }}<br />
                                    {{ $t('privacy.pra.text2') }}<br />
                                    {{ $t('privacy.pra.text3') }}
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">{{ $t('privacy.pra2.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra2.titleText') }}</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    {{ $t('privacy.pra2.text') }}<br />
                                    {{ $t('privacy.pra2.text2') }}<br />
                                    {{ $t('privacy.pra2.text3') }}
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">{{ $t('privacy.pra3.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra3.titleText') }}</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    {{ $t('privacy.pra3.text') }}
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">{{ $t('privacy.pra4.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra4.titleText') }}</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    {{ $t('privacy.pra4.text') }}
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">{{ $t('privacy.pra5.title') }}</span> <span class="terms__title-3__text">{{ $t('privacy.pra5.titleText') }}</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    {{ $t('privacy.pra5.text') }}
                                </div>
                            </div>

                            <br />
                            <br />
                            <br />

                            <!-- GDPR에 관한 개인정보취급방침 (KOR)-->
                            <div class="terms__title-1 le-heading4 text-orange1">
                                GDPR에 관한 개인정보취급방침
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 1조</span> <span class="terms__title-3__text">기본방침</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    주식회사 레코스(이하 ‘당사’)는 EU의 일반데이터 보호규칙(이하 ‘GDPR’)이 적용되는 개인데이터 취급에 대하여 GDPR을 바탕으로 개인정보취급방침을 정하여 사내체제의 구축, 개인정보의 보호, 적정관리, 적정이용을 실시하여 개인데이터 취급에 만전을 기하겠습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 2조</span> <span class="terms__title-3__text">수집하는 개인데이터</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">당사는 당사 사이트 이용자로부터 이하의 개인데이터를 취득하는 경우가 있습니다.</span>
                                    </div>

                                    <ul class="terms__list-1 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">a.</span> <span class="list__text">이름</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">b.</span> <span class="list__text">메일주소</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">c.</span> <span class="list__text">유저명 및 비밀번호</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">d.</span> <span class="list__text">IP주소</span>
                                            </div>
                                        </li>
                                    </ul>

                                    <div class="list__item-box">
                                        <span class="list__marker">2.</span> <span class="list__text">당사는 당사 사이트를 통하여 여권정보나 건강정보 등 민감한 개인데이터를 수집하는 것은 적용 개인정보보호 법령으로 정하는 경우를 제외하고는 없습니다.</span>
                                    </div>

                                    <div class="list__item-box">
                                        <span class="list__marker">3.</span> <span class="list__text">당사가 개인데이터를 수집하는 것은 당사 사이트 이용자에 의한 이하의 행위가 있는 경우입니다.</span>
                                    </div>

                                    <ul class="terms__list-1 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">a.</span> <span class="list__text">당사 사이트에서 등록을 한 경우</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">b.</span> <span class="list__text">당사 사이트를 이용하는 경우</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 3조</span> <span class="terms__title-3__text">개인데이터 처리의 목적</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">당사는 이용자와의 계약 이행에 있어서 다음의 각 호의 이용목적을 달성하지 위해, GDPR 제6조 1.(b)를 바탕으로 이용자의 개인정보를 취급합니다.</span>
                                    </div>

                                    <br />

                                    <div class="list__item-box">
                                        <span class="list__text">(1) 처리의 목적</span>
                                    </div>

                                    <div class="list__item-box">
                                        <span class="list__text">당사는 다음의 목적을 위해서만 개인데이터를 수집하여 처리합니다.</span>
                                    </div>

                                    <ul class="terms__list-1 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">a.</span> <span class="list__text">당사 서비스가 이용자에게 제일 효과적인 방법으로 표시되게 하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">b.</span> <span class="list__text">당사 서비스의 변경에 대해서 이용자에게 알리기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">c.</span> <span class="list__text">이용자의 계정을 관리하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">d.</span> <span class="list__text">당사 서비스를 이용자에게 제공하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">e.</span> <span class="list__text">당사의 방침과 규정 등에 대해서 이용자에게 알리기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">f.</span> <span class="list__text">부정행위의 감시나, 의심되는 행동 혹은 잠재적 위법행위 또는 당사의 방침이나 규약 등에 대한 위반행위의 조사 등에 의한 안전성이나 보안을 향상하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">g.</span> <span class="list__text">서비스 및 광고를 제공, 개선, 창출을 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">h.</span> <span class="list__text">데이터 분석, 조사, 감사 등의 목적으로 개인정보를 사용하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">i.</span> <span class="list__text">사업의 계속성을 확보하기 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">j.</span> <span class="list__text">각 종 문의대응을 위해</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">k.</span> <span class="list__text">앞의 각 호에 관련하는 업무를 위해</span>
                                            </div>
                                        </li>
                                    </ul>

                                    <div class="list__item-box">
                                        <span class="list__marker">2.</span> <span class="list__text">당사는 데이터주체의 동의, 당사 또는 제3자에 의해 추구되는 정당한 이익, 데이터주체와 당사와의 사이에서의 계약의 이행 또는 계약체결준비, 또는 당사가 부담하는 법적의무의 준수를 법적근거로서 개인데이터를 취급합니다.</span>
                                    </div>

                                    <div class="list__item-box">
                                        <span class="list__marker">3.</span> <span class="list__text">당사에 의한 개인데이터의 취급이 데이터주체의 동의에 기반하는 경우, 동의에 대해서 데이터주체는 언제든지 이를 철회할 수 있습니다. 단, 철회 전의 동의에 기반하는 개인데이터의 취급의 적법성이 동의 철회에 의해 영향을 받지는 않습니다.</span>
                                    </div>

                                    <div class="list__item-box">
                                        <span class="list__marker">4.</span> <span class="list__text">취득한 개인데이터를 위의 목적 이외에서 취급한 경우는 이 개인정보보호방침을 개정하여 알리겠습니다.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 4조</span> <span class="terms__title-3__text">개인데이터 제공 및 공유</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">당사는 개인데이터를 위의 처리 목적을 위하여 다음의 제3자와 공유할 필요가 있는 경우가 있습니다. 이것이 필요한 경우 당사는 적용 개인정보보호법령을 준수합니다.</span>
                                    </div>

                                    <ul class="terms__list-1 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">a.</span> <span class="list__text">당사의 그룹회사</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">b.</span> <span class="list__text">변호가, 변리사, 공인회계사 등의 직업전문가</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">c.</span> <span class="list__text">금융기관</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">d.</span> <span class="list__text">현재, 과거, 미래의 사원</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">e.</span> <span class="list__text">서비스 제공자, 공급자</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 5조</span> <span class="terms__title-3__text">개인데이터의 안전관리</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">당사는 개인데이터를 취급할 시는 적절한 관리를 실시하여 외부로부터 부정 접근, 분실, 파괴, 위조 및 누설 등의 위험방지에 노력하여 적절하고 합리적인 레벨의 안전대책을 실시합니다. 만일 사고가 발행한 경우는 신속하게 처리를 강구합니다.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 6조</span> <span class="terms__title-3__text">개인데이터의 보관</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">당사는 적절한 보안을 확립한 방법으로 개인데이터를 보관합니다. 당사에서는 이 개인정보보호방침의 이용목적을 당성하지 위해 필요한 기간에 한하여 개인데이터를 보관하고 불필요하게 된 경우는 신속하게 제거합니다. 구체적인 기간은 개인데이터의 취득 및 취급 목적, 개인데이터의 성질, 법률상 또는 업무상의 개인데이터보관의 필요성을 고려하여 결정됩니다.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 7조</span> <span class="terms__title-3__text">쿠키(Cookie)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">웹사이트를 방문하면 많은 경우 Cookie를 이용하여 이용자의 브라우저에 정보를 보관 또는 정보를 취득합니다. 취득된 정보에는 이용자에 관한 것, 우선설정, 디바이스에 관한 정보가 포함되어 있는 경우가 있습니다. 많은 경우 웹사이트의 동작을 이용자의 기대대로 하기 위해 사용되고 있습니다. 취득된 정보는 일반적을 이용자를 특정할 수 있는 것은 아닙니다만, 웹사이트의 경험 개인화에 도움이 됩니다. 당사에서는 이용자의 프라이버시를 존중하여 일부의 Cookie를 사용하지 않도록 이용자가 선택할 수 있게 합니다. 당사의 웹사이트에 표시되는 카테고리명을 클릭하면 상세정보가 표시되어 당사의 기본설정을 변경할 수 있습니다. Cookie의 종류에 따라서는 차단하게 되면 당사의 사이트를 사용하거나 당사가 제공하는 서비스에 영향이 미치는 경우가 있으니 주의 부탁드립니다.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 8조</span> <span class="terms__title-3__text">이의 신청</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">이용자는 GDPR등에 따라 당사의 개인정보취급에 관한 나라나 지역 또는 국제조직 등의 감독기관에 이의 신청을 할 권리가 있습니다. 당사로서는 이용자가 감독기관에 이의 신청을 하기 전에 당사에 대응할 기회를 주시면 좋겠습니다. 가능하시다면 아래의 문의처로 사전에 연락을 부탁드립니다.</span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 9조</span> <span class="terms__title-3__text">문의처</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">질문이나 불편하신 점이 있는 경우 또는 이 개인정보취급방침 제8조에 기재의 권리를 행사하길 희망하시는 경우는 다음 연락처로 문의를 부탁드립니다.</span>
                                    </div>

                                    <br />

                                    <div class="list__item-box">
                                        <span class="list__text">
                                            문의처 주식회사 레코스 담당<br />
                                            주소 서울특별시 마포구 마포대로 86 창강빌딩 4층 421호<br />
                                            전화번호 02-716-5571 (평일 9:00~18:00, 주말/공휴일 제외)
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제 10조</span> <span class="terms__title-3__text">일반조항</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <div class="list__item-box">
                                        <span class="list__text">이 개인정보취급방침은 2023년 10월 31일부터 시행합니다. 당사는 법령을 바탕으로 혹은 당사의 방침에 의해 이 개인정보취급방침을 변경할 가능성이 있습니다. 단, 당사는 데이터주체의 개인데이터를 동의를 얻지 않고 새로운 방법으로 이용하는 것은 없습니다.</span>
                                    </div>
                                </div>
                            </div>
                            <!-- // GDPR에 관한 개인정보취급방침 (KOR)-->
                        </div>
                        <!-- // terms-->
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
export default {
    name: 'AboutPage',
    components: {
        //
    },

    metaInfo() {
        //
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
