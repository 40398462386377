// import axios from 'axios';
import api from '@/api';
import {
//
} from './mutation-types';

export default {
    // eslint-disable-next-line no-unused-vars
    issueBadge ({ commit }, issue) {
        console.log('action! issue', issue);

        // return new Promise((resolve) => {
        //     const mockResponse = {
        //         status: 400,  // 400 응답을 강제로 줌
        //         data: 'Bad Request',
        //     };

        //     resolve(mockResponse);  // 여기에 resolve를 사용해서 then 블록으로 보내기
        // });


        // https://lecos.co.kr/api/v1/OpenBadges
        return api.post('/api/v1/OpenBadges', issue, {
            headers: {
                'Accept-Language': issue.lang
            },
        });
    },
};
