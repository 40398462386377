<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="수령부터 관리까지"
            sub-title="쉽고 빠른 배지 수령 및 공유"
            bg-color="blue12"
        />

        <!-- ANCHOR: 쉽고 빠른 배지 -->
        <section class="c-section bg-blue12 d-flex justify-center">
            <div class="c-contanier">
                <div
                    class="d-flex flex-column step-wrap"
                    :class="{ 'gap-12': $vuetify.breakpoint.mdAndUp, 'gap-10': $vuetify.breakpoint.smAndDown }"
                >
                    <!-- 수여 알림 메일 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 01
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    수여 알림 메일 확인
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        발행인으로부터 전송된 수여 알림 메일에서 배지 수령 시작
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 배지 지갑 등록 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 02
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    배지 지갑 등록
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        처음 수령시에 이름과 비밀번호를 입력하여 나만의 배지 지갑 작성
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 발행 완료 메일 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 03
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-3.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    발행 완료 메일 확인
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        수령 절차 완료 후, 발행 완료 메일이 전송됨
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- 수령 배지 확인 -->
                    <div
                        class="d-flex"
                        :class="{ 'flex-column': $vuetify.breakpoint.xsOnly }"
                    >
                        <div class="step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center">
                            Step 04
                        </div>

                        <v-card 
                            class="d-flex flex-grow-1"
                            :class="{
                                'pt-15 pb-15 pl-10 pr-10': $vuetify.breakpoint.mdAndUp,
                                'pt-10 pb-10 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                                'pt-10 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div 
                                class="d-flex align-center justify-center"
                                :class="{
                                    'mr-10': $vuetify.breakpoint.mdAndUp,
                                    'mr-5': $vuetify.breakpoint.smOnly,
                                    'mr-3': $vuetify.breakpoint.xsOnly,
                                }"
                            >
                                <v-img
                                    :src="require('@/assets/img/management-share-4.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 88 : ($vuetify.breakpoint.smAndDown ? 68 : 68)"
                                    alt=""
                                />
                            </div>

                            <div>
                                <p
                                    class="le-heading5 text-blue3"
                                    :class="{ 'mb-5': $vuetify.breakpoint.mdAndUp, 'mb-4': $vuetify.breakpoint.smOnly, 'mb-3': $vuetify.breakpoint.xsOnly }"
                                >
                                    수령 배지 확인
                                </p>

                                <div class="d-flex flex-column gap-3">
                                    <p class="le-text-body1">
                                        배지 지갑에 로그인하여 배지 상세내용 확인 및 공유 가능
                                    </p>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 레코스 오픈배지 지갑 기능 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    레코스 오픈배지 지갑 기능
                </h2>

                <div
                    class="d-flex flex-column mx-auto gap-10"
                    style="max-width: 790px;"
                >
                    <!-- 모으기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-1.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                모으기
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    전 세계에서 발행한 오픈배지를 하나의 지갑에 모으고 관리
                                </p>
                            </div>
                        </div>
                    </v-card>

                    <!-- 공개하기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-2.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                공개하기
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    내가 공개하고 싶은 오픈배지만 공개 가능
                                </p>

                                <p class="le-text-body1">
                                    공개 지갑으로 나만의 포트폴리오 작성
                                </p>
                            </div>
                        </div>
                    </v-card>

                    <!-- 공유하기 -->
                    <v-card
                        class="d-flex"
                        :class="{
                            'pa-10': $vuetify.breakpoint.mdAndUp,
                            'pt-7 pb-7 pl-5 pr-5': $vuetify.breakpoint.smOnly,
                            'pt-8 pb-8 pl-3 pr-3': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div 
                            class="d-flex align-center justify-center"
                            :class="{
                                'mr-10': $vuetify.breakpoint.mdAndUp,
                                'mr-5': $vuetify.breakpoint.smOnly,
                                'mr-3': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <v-img
                                :src="require('@/assets/img/management-function-3.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 140 : ($vuetify.breakpoint.smAndDown ? 96 : 96)"
                                :height="$vuetify.breakpoint.mdAndUp ? 104 : ($vuetify.breakpoint.smAndDown ? 72 : 72)"
                                alt=""
                            />
                        </div>

                        <div>
                            <p
                                class="le-heading5 text-blue3" 
                                :class="{
                                    'mb-5': $vuetify.breakpoint.mdAndUp,
                                    'mb-3': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                공유하기
                            </p>

                            <div class="d-flex flex-column gap-3">
                                <p class="le-text-body1">
                                    언제 어디서나 SNS에 업로드하고 URL로 공유 가능
                                </p>
                            </div>
                        </div>
                    </v-card>
                </div>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getManagementMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'ManagementPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getManagementMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
