// import axios from 'axios';
// import api from '@/api';
import {
//
} from './mutation-types';

export default {
    // fetchNoticeItem({ commit, state }, id) {
    //     const noticeItem = state.noticeItems.find(item => item.id === id);
    //     if (noticeItem) {
    //         commit('SET_NOTICE_ITEM', noticeItem);
    //     }
    // }
};
