<template>
    <div
        class="page-title text-center d-flex flex-column align-center justify-center"
        :class="`bg-${bgColor}`"
    >
        <div class="c-contanier">
            <p class="le-heading4 text-blue3">
                {{ mainTitle }}
            </p>

            <p
                class="le-heading2"
                :class="$vuetify.breakpoint.smAndDown ? 'mt-7' : 'mt-9'"
                v-html="subTitle"
            >
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle',
    props: {
        mainTitle: {
            type: String,
            required: true
        },
        subTitle: {
            type: String,
            required: true
        },
        bgColor: {
            type: String,
            default: 'white1'
        }
    }
};
</script>
