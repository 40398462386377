<template>
    <div class="h-100 bg-gray7 d-flex align-center justify-center">
        <div>
            <div class="d-flex align-center justify-center">
                <div>
                    <v-img
                        :src="require('@/assets/img/404-item-1.png')"
                        :width="$vuetify.breakpoint.mdAndUp ? 223 : ($vuetify.breakpoint.smAndDown ? 179 : 40)"
                        alt=""
                    />
                </div>
            </div>

            <p 
                class="le-heading3 text-center"
                :class="{
                    'mt-10': $vuetify.breakpoint.smAndUp,
                    'mt-8': $vuetify.breakpoint.xsOnly
                }"
            >
                죄송합니다.<br>
                페이지를 찾을 수 없습니다.
            </p>

            <div 
                class="d-flex align-center justify-center"
                :class="{
                    'mt-16': $vuetify.breakpoint.smAndUp,
                    'mt-11': $vuetify.breakpoint.xsOnly
                }"
            >
                <v-btn
                    :to="{ name: 'HomePage' }"
                    color="orange1"
                    :large="$vuetify.breakpoint.mdAndUp"
                    depressed
                    class="text-white1"
                    exact
                >
                    메인으로
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFoundPage',
    components: {        
        // Swiper,
    },
    metaInfo() {
        return {
            title: 'Page Not Found',
            titleTemplate: '%s | Learn for the Future', 
            meta: [
                // { charset: 'utf-8'},

                // SEO setting
                // { name: 'description', content: this.$t('meta.description'), vmid: 'description'},
                // { name: 'keywords', content: this.$t('meta.keywords') }, 
                // { name: 'author', content: this.$t('meta.author') },

                // SNS (Naver 블로그, 카카오톡 미리보기 설정)
                // { property: 'og:title', content: this.$t('meta.og.title'), vmid: 'og:title' },
                // { property: 'og:type', content: 'website', vmid: 'og:type' },
                // { property: 'og:image', content: ogImage, vmid: 'og:image' },
                // { property: 'og:description', content: this.$t('meta.og.description'), vmid: 'og:description' },
                
                // mobile
                // { name: 'viewport', content: 'width=device-width, initial-scale=1'}
            ],

            script: [
                { src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js', async: true, defer: true }
            ],
        };
    },

    data: function() {
        return {
            //
        };
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },

    methods: {
        //
    }
};
</script>