<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <v-row>
                    <v-col
                        cols="12"
                        lg="10"
                        class="d-flex mx-auto"
                    >
                        <!-- terms(KOR) -->
                        <div
                            v-if="this.$route.params.lang == 'kor'"
                            class="terms"
                        >
                            <div class="terms__title-1 le-heading4 text-blue3">
                                오픈배지 지갑 이용 약관
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3 first">
                                <span class="terms__title-2__space">제1장</span> <span class="terms__title-2__text">총칙</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제1조</span> <span class="terms__title-3__text">(정의)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    본 약관에서 정하는 용어는 다음의 정의에 의한 것으로 합니다. 

                                    <ul class="terms__list-2 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span> 
                                                <span class="list__text">
                                                    <b>[대상자]</b><br />
                                                    오픈배지 또는 디지털 증서를 수령하는 권리를 지닌 자 및 수령인
                                                </span>
                                            </div>
                                            <br />
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span> 
                                                <span class="list__text">
                                                    <b>[수령인]</b><br />
                                                    오픈배지 또는 디지털 증서를 수령한 자
                                                </span>
                                            </div>
                                            <br />
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span> 
                                                <span class="list__text">
                                                    <b>[발행인]</b><br />
                                                    오픈배지를 발행하는 단체
                                                </span>
                                            </div>
                                            <br />
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span> 
                                                <span class="list__text">
                                                    <b>[오픈배지]</b><br />
                                                    1EdTech Consortium,Inc.(전 IMS Global Learning Consortium,Inc.)이 정한 세계표준규격 Open Badges v2.0 에 준거한 디지털 표시이며, 그 발행체가 제공하는 자격인정, 강좌, 학업단위, 업적 등의 이력, 학습 성적, 자격, 단위의 취득정보 등을 나타낸다.
                                                </span>
                                            </div>
                                            <br />
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span>
                                                <span class="list__text">
                                                    <b>[오픈배지 지갑]</b><br />
                                                    수령인이 수령한 오픈배지나 디지털 증서를 인터넷상에서 표시하게 하는 기능이다.
                                                </span>
                                            </div>
                                            <br />
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker"></span>
                                                <span class="list__text">
                                                    <b>[계정]</b><br />
                                                    대상자가 제 11 조에서 정하는 제공 서비스를 받기 위해 필요로 하는 등록 정보 전체를 말하며, 메일 주소, ID 나 비밀번호 등이 포함된다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제2조</span> <span class="terms__title-3__text">(약관의 적용)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                        
                                                <span class="list__text">
                                                    이 약관은 주식회사 LecoS(이하 '당사'라 한다)가 오픈배지나 디지털 증서 등의 디지털 증명서(이하 '오픈배지 등'이라 한다)의 대상자에 대하여 제공하는 오픈배지 및 증서 이용 서비스(이하 '본 서비스'라 한다)에 대한 일체에 적용됩니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span>
                                        
                                                <span class="list__text">
                                                    대상자는 본 서비스 상의 본 약관 게재 화면의 본 약관에 '동의한다' 버튼을 클릭하는 것 또는 당사가 지정하는 기타의 방법으로 본 약관의 내용에 대한 동의를 표명함으로써 수령인이 되며, 본 약관의 내용을 승낙하고 있는 것으로 간주됩니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제3조</span> <span class="terms__title-3__text">(수령인에게 통지)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                        
                                                <span class="list__text">
                                                    당사는, 인터넷 또는 당사가 적당하다고 판단하는 방법으로 수령인에 대한 불시 본 약관에 관한 필요한 사항을 통지, 또는 조회할 수 있는 상태에 있습니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 

                                                <span class="list__text">
                                                    전항의 통지는 당사가 해당 통지를 전항의 규정으로 한 시점보다 효력을 발하는 것으로 합니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> 
                                        
                                                <span class="list__text">
                                                    당사가 수령인에 대해서 한 통지는 이 약관의 일부를 구성합니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제4조</span> <span class="terms__title-3__text">(약관의 변경)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 

                                                <span class="list__text">
                                                    당사는 수령인의 승낙을 얻지 않고 본 약관을 변경하는 일이 있습니다. 이 경우 본 서비스 이용 조건은 변경 후의 약관에 따릅니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 

                                                <span class="list__text">
                                                    약관의 변경 내용은 수령인에게 통지하며, 서비스 상에 표시된 시점부터 효력을 발합니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제5조</span> <span class="terms__title-3__text">(약관의 범위)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                        
                                                <span class="list__text">
                                                    당사가 따로 개별 서비스 규정 또는 추가 규정한 경우 해당 규정은 본 약관의 일부를 구성합니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 

                                                <span class="list__text">
                                                    본 약관과 개별 서비스 규정 및 추가 규정이 다른 경우에는 개별 서비스 규정 및 추가 규정이 우선합니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>                    

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제2장</span> <span class="terms__title-2__text">오픈배지의 수령</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제6조</span> <span class="terms__title-3__text">(수령)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                                <span class="list__text">
                                                    수령인은 본 서비스에 수령인 정보를 등록함으로써 오픈 배지 등을 수령할 수 있습니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 
                                                <span class="list__text">
                                                    수령인 정보를 등록할 시, 대상자 본인이 보유한 이메일 주소를 등록합니다.  회사나 학교, 가족 등 여러 사람에 의해 이메일 주소를 공용되고 있는 경우에는 해당 공유자의 총의로 해당 수령인에 해당 이메일 주소로 수령인 정보의 등록을 인정하는 경우에만 해당 이메일 주소로 수령인 정보의 등록을 인정하는 것으로 합니다. 단, 이 경우도 본 서비스 이용에 대해서는 대상자 본인에게 한정하는 것으로 합니다. 
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> 
                                                <span class="list__text">
                                                    당사는 당사가 필요하다고 판단했을 경우 수령인의 본인 확인을 행하기 위해 수령인에 대해 필요한 서류 등의 제시를 요구할 수 있습니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제7조</span> <span class="terms__title-3__text">(ID 및 패스워드)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                                <span class="list__text">
                                                    당사는 수령인에게 본 서비스 이용에 필요한 ID를 발행합니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 
                                                <span class="list__text">
                                                    당사는 수령인에 의하여 등록된 본 서비스의 패스워드를 제삼자에게 개시하지 않습니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제8조</span> <span class="terms__title-3__text">(양도금지 등)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    대상자는, 대상자로서 지니는 권리를 제삼자에게 양도, 매매, 명의 변경, 질권의 설정 그 외의 담보로 제공하는 등의 행위는 할 수 없습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제9조</span> <span class="terms__title-3__text">(변경 신고)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                                <span class="list__text">
                                                    수령인은 본 서비스상에 등록된 수령인 정보에 변경이 생겼을 경우, 신속하게 당사 소정의 방법으로 변경 신고를 하는 것으로 합니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 
                                                <span class="list__text">
                                                    전항의 신고가 없음으로 인하여 수령인이 불이익을 당하였더라도 당사는 일절 그 책임을 지지 않습니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제10조</span> <span class="terms__title-3__text">(설비 등)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    대상자는 본 서비스를 이용하기 위해서 필요한 통신기기, 소프트웨어, 기타 이에 부수되어 필요한 모든 설비를 자기의 비용과 책임 하에 준비하고, 본 서비스를 이용 가능한 상태로 두는 것으로 합니다. 또, 자기의 비용과 책임으로 인터넷을 통해 본 서비스에 접속하는 것으로 합니다.
                                </div>
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제3장</span> <span class="terms__title-2__text">서비스 이용 및 이용상황 데이터</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제11조</span> <span class="terms__title-3__text">(제공 서비스)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는, 수령인의 오픈 배지 등을, 인터넷상에 표시하는 오픈 배지 지갑 서비스 및  다음의 부대 서비스 (이하'부대 서비스'라 한다)를 제공합니다.

                                    <ul class="terms__list-1 list list-space">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(1)</span> <span class="list__text">수령인 프로필 등록, 공개 서비스</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(2)</span> <span class="list__text">오픈배지 공개 서비스</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(3)</span> <span class="list__text">오픈배지 검증 서비스</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(4)</span> <span class="list__text">오픈배지 검색 서비스</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(5)</span> <span class="list__text">디지털 증서의 지정된 곳으로의 제출 및 검증 서비스</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(6)</span> <span class="list__text">기타 위에 부대되는 서비스</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제12조</span> <span class="terms__title-3__text">(서비스 이용개시)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> 
                                                <span class="list__text">
                                                    당사는 수령인에 대한 ID 의 발행을 통해 본 서비스 이용을 허락하며, 해당 수령인은 본 서비스를 본 약관에서 정하는 조건에 따라 이용할 수 있습니다.
                                                </span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> 
                                                <span class="list__text">
                                                    수령인은 자신이 등록한 오픈배지 지갑의 이용 개시일에 본 서비스를 이용할 수 있습니다.
                                                </span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제13조</span> <span class="terms__title-3__text">(이용 상황 데이터의 이용)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 본 서비스의 이용 상황 데이터의 취득과 해석을 위해 'Google Analytics' 및 'Clarity'를 이용합니다. 개인을 특정하는 정보를 포함하지 않고 이용 상황 데이터를 취득하는 데이터를 수집하는 경우가 있습니다. 이 기능은 이용자가 이용하는 브라우저 설정에서 Cookie 를 무효로 하는 것으로 수집을 거부할 수 있습니다. 당사는 'Google Analytics' 및 'Clarity'의 이용에 따른 손해에 대한 책임을 지지 않습니다. 

                                    <ul class="terms__list-1 list list-space mgb0 text-break-all">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(1)</span> <span class="list__text">GoogleAnalytics 이용 약관<br />(https://marketingplatform.google.com/about/analytics/terms/jp/)</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(2)</span> <span class="list__text">Google 개인정보 보호 및 약관<br />(https://policies.google.com/technologies/ads?hl=ja)</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(3)</span> <span class="list__text">Clarity 이용 약관<br />(https://clarity.microsoft.com/terms)</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(4)</span> <span class="list__text">Microsoft 개인정보처리방침<br />(https://privacy.microsoft.com/en-us/privacystatement)</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제14조</span> <span class="terms__title-3__text">(서비스의 이용중지)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    수령인은 본 서비스의 이용 중지를 희망하는 경우, 자신의 계정을 삭제함으로써 서비스의 이용중지를 할 수 있습니다. 또한, 삭제된 계정은 삭제를 취소하거나 등록을 복원하는 것을 할 수 없습니다. 
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제15조</span> <span class="terms__title-3__text">(오픈배지 삭제)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    수령인은 본 서비스 상에 있는 오픈배지의 일부 또는 전부를 삭제할 수 있습니다. 이때 오픈배지 삭제에 대한 정보는 발행인에게 통보되지 않습니다. 또한 수령인에 의해 일단 등록이 삭제된 오픈배지는 삭제 취소 또는 등록을 복원할 수 없습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제16조</span> <span class="terms__title-3__text">(아이디 및 패스워드 말소)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 수령인이 본 규약의 규정을 위반한 경우, 해당 수령인에 대한 통지를 가지고
                                    본 서비스 이용을 정지하고 발행된 ID 및 패스워드를 말소할 수 있습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제17조</span> <span class="terms__title-3__text">(오픈배지 지갑의 이용정지)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 수령인의 오픈 배지 등, 또 오픈 배지 지갑의 내용에 중대한 하자가 있는 경우 해당 수령인에 대한 통지로 해당 오픈 배지 지갑의 이용을 정지할 수 있습니다. 
                                </div>
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제4장</span> <span class="terms__title-2__text">수령인의 의무</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제18조</span> <span class="terms__title-3__text">(ID와 패스워드의 관리 책임)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">수령인은 본 서비스를 이용하기 위한 자신의 ID 및 패스워드의 사용 및 관리에 대해 일체의 책임을 집니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 수령인의 ID 및 패스워드가 제삼자에게 사용됨으로써 해당 수령인이 입게 되는 손해에 대하여 해당 수령인의 고의 과실 유무에 관계없이 일절 책임지지 않습니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> <span class="list__text">수령인은 자신이 설정한 패스워드를 잃어버렸을 경우, 또는 ID 및 패스워드를 도난당하거나 제삼자에게 사용되었음을 알게 된 경우에는 즉시 당사에 신청하여야 하며 당사의 지시에 따르도록 합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">4.</span> <span class="list__text">해당 ID 및 패스워드에 의해 이루어진 본 서비스의 이용은 해당 수령인에 의해 이루어진 것으로 간주하며, 해당 수령인은 기타 채무의 일체를 부담해야 합니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제19조</span> <span class="terms__title-3__text">(자기 책임의 원칙)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">수령인은 자신의 ID 및 패스워드에 의해 본 서비스를 이용하여 이루어진 일체의 행위 및 그 결과에 대해 해당 행위를 자신이 했는지 여부를 불문하고 책임을 집니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">수령인이 부대서비스를 이용한 것에 대한 책임은 수령인 자신에게 있습니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> <span class="list__text">수령인은 부대서비스 정보에 대해 당사에 보존 의무가 없음을 인식하고 필요한 정보에 대해서는 수령인 자신의 책임 하에 적절히 백업을 취합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">4.</span> <span class="list__text">수령인은 본 서비스 이용에 따라 제삼자로부터 문의, 클레임 등이 통지되었을 경우, 자신의 책임과 비용을 가지고 처리 해결합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">5.</span> <span class="list__text">수령인은 제삼자의 행위에 대한 요청, 의문 또는 클레임이 있는 경우에는 해당 제삼자에게 직접 그 취지를 통지하며, 그 결과에 대해서는 자기의 책임과 비용으로 처리 해결합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">6.</span> <span class="list__text">수령인은 본 서비스를 이용함으로써 당사 또는 제삼자에게 손해를 끼친 경우, 자기의 책임과 비용으로 손해를 배상합니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제20조</span> <span class="terms__title-3__text">(기타 그 외의 금지사항)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    수령인은 본 서비스상에서 이하의 행위를 실시하지 않는 것으로 합니다.

                                    <ul class="terms__list-1 list list-space mgb0">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(1)</span> <span class="list__text">ID 및 패스워드를 부정하게 사용하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(2)</span> <span class="list__text">타인의 이메일주소를 등록하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(3)</span> <span class="list__text">오픈 배지 지갑 작성 후 ID 또는 패스워드를 공유 및 대여하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(4)</span> <span class="list__text">본 서비스를 통하거나 본 서비스와 관련하여 컴퓨터 바이러스 등의 유해한 프로그램을 사용 또는 제공하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(5)</span> <span class="list__text">콘텐츠의 전부 또는 일부 수정 및 콘텐츠를 기반으로 한 파생적 제작물을 작성하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(6)</span> <span class="list__text">당사의 상표권 등의 지적 재산권을 침해하는 행위, 또는 침해할 우려가 있는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(7)</span> <span class="list__text">본 서비스를 통해서 입수한 데이터, 정보, 문장, 소프트웨어 등에 관해, 저작권법에서 인정된 사적 이용의 범위를 넘어 복제, 판매, 출판 등을 실시하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(8)</span> <span class="list__text">제삼자의 재산, 사생활 또는 초상권을 침해하는 행위 또는 침해할 우려가 있는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(9)</span> <span class="list__text">제삼자를 차별 또는 비방 중상하거나 제삼자의 명예 또는 신용을 훼손하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(10)</span> <span class="list__text">범죄적 행위 또는 범죄적 행위로 연결되는 행위 또는 그 우려가 있는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(11)</span> <span class="list__text">성풍속, 종교, 정치에 관한 활동</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(12)</span> <span class="list__text">상기 각호의 외, 법령, 본 약관 혹은 미풍양속에 위반(매춘, 폭력, 잔혹 등)하는 행위, 본 서비스의 운영을 방해하는 행위, 당사의 신용을 훼손하거나 재산을 침해하는 행위, 또는 당사에 불이익을 주는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(13)</span> <span class="list__text">상기 각 호의 어느 하나에 해당하는 행위를 조장하는 행위</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">(14)</span> <span class="list__text">기타 당사가 부적절하다고 인정하는 행위</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제21조</span> <span class="terms__title-3__text">(이용자격의 중단·취소)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">수령인이 전조의 항목에 해당하는 경우, 당사는 사전에 통지하지 않고 즉시 해당 수령인의 이용 자격을 중단 또는 장래에 취소할 수 있습니다. 덧붙여 이용 자격이 취소되었을 경우, 해당 수령인은 본 계약에 근거하는 채무의 전액을 당사에 대해서 부담하는 것으로 합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 전항의 조치를 취함으로써 해당 수령인이 본 서비스를 이용할 수 없으며, 이로 인해 손해가 발생하였다고 하더라도 어떠한 책임도 지지 않습니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제22조</span> <span class="terms__title-3__text">(손해배상)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    수령인은 본 약관 및 법령의 규정을 위반함에 따라 당사를 포함한 제삼자에게 손해를 끼쳤을 경우 해당 손해를 배상할 책임을 지는 것으로 하며 당사를 포함한 제삼자를 면책해야 합니다.
                                </div>
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제5장</span> <span class="terms__title-2__text">개인정보 취급</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제23조</span> <span class="terms__title-3__text">(개인정보)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">당사는 다음의 정보를 개인 정보로서 취급합니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">수령인의 이름, 이메일 등 당사의 서비스를 이용함에 있어 필요한 등록 정보</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(2)</span> <span class="list__text">이용서비스의 내용에 관한 정보</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(3)</span> <span class="list__text">본 서비스를 이용하기 위하여 당사가 수령인에게 부여한 ID 및 패스워드</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(4)</span> <span class="list__text">본 서비스를 이용하는 과정에서 기록된 로그인, 로그아웃 등의 정보</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(5)</span> <span class="list__text">그 외, 전자 메일, 전화, FAX, 편지 등에 의해, 당사와의 사이에 행해진 문의나 신청 등의 정보, 및 그에 대한 당사로부터의 회답이나 대응 등의 정보</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 본 조 제 1항 이외의 개인정보의 제공은 받지 않습니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제24조</span> <span class="terms__title-3__text">(개인정보의 사용)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">당사는, 전조에 규정하는 개인정보를 이하의 목적을 위해서 사용할 수 있는 것으로 합니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">본 서비스를 제공하기 위하여.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(2)</span> <span class="list__text">본 서비스 제공에 부대되는 업무를 수행하기 위해.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(3)</span> <span class="list__text">당사의 서비스 이용 동향 조사 및 분석을 위하여.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(4)</span> <span class="list__text">당사의 새로운 서비스의 연구 또는 개발을 하기 위해서.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 전항의 이용범위에서 당사의 업무위탁처인 주식회사 네트러닝 홀딩스에 개인정보의 취급을 위탁합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> <span class="list__text">당사는 1항 및 2항의 규정의 적용을 방해하지 않고, 이하의 항목에 해당하는 경우를 제외하고, 전조에서 규정하는 개인정보를 제삼자에게 개시하지 않는 것으로 합니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">수령인이 개인정보 공개에 대해 별도로 동의하는 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(2)</span> <span class="list__text">1항의 이용목적 달성을 위하여 제삼자와 공동 또는 위탁에 의하여 정보를 취급하는 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(3)</span> <span class="list__text">사업 양도, 분사 등으로 영업 자산의 일부로서 개인정보를 제삼자에게 인계하는 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(4)</span> <span class="list__text">개인정보를 특정 제삼자와 상호 이용하는 경우로, 사전에 그 이용 목적 및 특정 제삼자에 대하여 사전에 통지 또는 공표된 경우.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">4.</span> <span class="list__text">개인정보 취급에 관해서는 본 이용약관의 규정 외에 당사의 개인정보보호방침 및 개인정보의 취급에 따릅니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제25조</span> <span class="terms__title-3__text">(개인정보의 공개)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">당사는 수령인이 해당 수령인의 오픈배지 정보와 개인 정보에 대해 수령한 오픈 배지의 공개 선택이 가능한 경우는 수령인과 공개를 선택하는 것에서 이하의 정보를 공개합니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">오픈배지에 포함된 모든 정보(발행일, 발행인, 실효 등 상태 정보 및 발행인이 오픈배지에 등록한 메일 주소, 이름, 설명, 취득 조건, 스킬, 유효기간, 증거 항목에 기재된 정보 등)</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(2)</span> <span class="list__text">오픈 배지 지갑에 수령인 본인이 기록한 이름, 메시지 정보 등</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 수령인이 해당 수령인의 디지털 증서 정보와 개인정보에 대해 수령한 디지털 증서 열람용 URL 또는 PDF 파일을 특정 제삼자에게 다음의 정보를 제출할 수 있습니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">디지털 증서에 포함된 모든 정보(발행일, 발행인, 실효 등의 상태 정보, 수령인 이름 및 발행인이 증서 이미지에 표시한 정보, 발행인가 증서 노트란에 입력한 정보)</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제6장</span> <span class="terms__title-2__text">서비스의 조건, 내용 변경, 중단 및 정지</span>
                            </div>
                    
                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제26조</span> <span class="terms__title-3__text">(서비스 조건)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 본 서비스의 운영에 관해 본 서비스의 이용을 감시하고, 필요하다고 인정하는 경우, 자기의 재량으로 본 서비스로의 접근을 제한할 수 있습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제27조</span> <span class="terms__title-3__text">(서비스 내용 등의 변경)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 수령인에게 사전 통지 없이 본 서비스의 내용, 명칭을 변경하는 일이 있습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제28조</span> <span class="terms__title-3__text">(서비스의 일시적 중단)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">당사는, 이하의 몇 개의 사유가 생겼을 경우에는, 수령인에게 사전에 통지하는 일 없이 일시적으로 본 서비스를 중단하는 일이 있습니다.</span>
                                            </div>
                                            <ul class="terms__list-1 list list-space">
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(1)</span> <span class="list__text">인터넷상에 문제가 발생하여 인터넷을 이용할 수 없게 된 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(2)</span> <span class="list__text">당사가 본 서비스에 있어서의 인터넷 접속을 위탁하고 있는, 인터넷 접속 서비스 사업자가 보유하는 통신 설비 등에 불편이 발생하여 인터넷의 이용을 할 수 없게 되었을 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(3)</span> <span class="list__text">본 서비스용 설비 등의 보수를 정기적 또는 긴급하게 실시하는 경우. 또한 보수를 정기적으로 실시하는 경우는 수령인에 대해 1개월 이상 전까지 통지합니다.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(4)</span> <span class="list__text">화재, 정전 등으로 인해 서비스를 제공할 수 없게 된 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(5)</span> <span class="list__text">지진, 분화, 홍수, 해일 등의 천재지변으로 인하여 서비스를 제공할 수 없게 된 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(6)</span> <span class="list__text">역병, 전염병의 만연으로 인하여 본 서비스를 제공할 수 없게 된 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(7)</span> <span class="list__text">전쟁, 동란, 폭동, 소란, 노동쟁의 등으로 인하여 서비스 제공을 할 수 없게 된 경우.</span>
                                                    </div>
                                                </li>
                                                <li class="list__item">
                                                    <div class="list__item-box">
                                                        <span class="list__marker">(8)</span> <span class="list__text">기타, 운용상 또는 기술상 당사가 본 서비스의 일시적인 중단이 필요하다고 판단했을 경우.</span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 전항 각 호의 어느 하나 또는 기타의 사유로 인하여 본 서비스 제공의 지연 또는 중단 등이 발생하였더라도 이에 기인하는 수령인 또는 제삼자가 입은 손해에 대하여 일절 책임을 지지 않습니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제29조</span> <span class="terms__title-3__text">(서비스 제공의 중지)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    당사는 본 서비스의 전부 또는 일부의 제공을 중지하는 일이 있습니다. 이 경우, 당사는 3개월 이상 전까지 수령인에 대해 통지하는 것으로 합니다.
                                    단, 사전 통지에 대해 긴급 어쩔 수 없는 경우는 이에 한하지 않습니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제30조</span> <span class="terms__title-3__text">(면책)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    <ul class="terms__list-1 list">
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">1.</span> <span class="list__text">본 서비스의 내용은 당사가 그 시점에서 제공 가능한 것으로 합니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">2.</span> <span class="list__text">당사는 본 서비스이용으로 발생한 수령인의 손해(제삼자와의 사이에서 발생한 트러블로 인한 손해를 포함합니다.) 또는 제삼자의 손해에 대하여 어떠한 책임도 지지 않으며, 손해배상의무를 일절 지지 않습니다.</span>
                                            </div>
                                        </li>
                                        <li class="list__item">
                                            <div class="list__item-box">
                                                <span class="list__marker">3.</span> <span class="list__text">당사는 본 서비스에 대한 접근제한, 본 서비스의 중지·중단 등의 발생으로 인하여 본 서비스를 이용하지 못함으로 인해 발생한 수령인 또는 제삼자가 입은 어떠한 손해에 대하여 이유를 불문하고 어떠한 책임도 지지 않으며, 손해배상의무를 일절 지지 않습니다.</span>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="terms__title-2 le-heading6 text-blue3">
                                <span class="terms__title-2__space">제7장</span> <span class="terms__title-2__text">그 외 기타</span>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제31조</span> <span class="terms__title-3__text">(협의)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    본 서비스와 관련하여 수령인과 당사 간에 분쟁이 발생하였을 경우에는 해당 당사자가 함께 성의 있게 협의합니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제32조</span> <span class="terms__title-3__text">(전속적 합의 관할 법원)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    수령인과 당사 사이에 소송의 필요가 생겼을 경우, 도쿄 지방재판소를 수령인과 당사의 제1심의 전속적 합의 관할재판소로 합니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제33조</span> <span class="terms__title-3__text">(준거법)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    본 약관에 관한 준거법은 일본법으로 합니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__space">제34조</span> <span class="terms__title-3__text">(언어)</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    본 약관은 일본어로 작성되어, 다른 언어로 번역된 것 사이에 차이가 있는 경우는, 모든 점에 있어서 일본어가 우선되는 것으로 합니다.
                                </div>
                            </div>

                            <div class="terms__division">
                                <div class="terms__title-3 le-heading6">
                                    <span class="terms__title-3__text">부칙</span>
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2021년 10월 1일부터 시행합니다.
                                </div>
                        
                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2022년 7월 21일부터 개정 시행합니다.
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2022년 9월 29일부터 개정 시행합니다.
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2022년 11월 20일부터 개정 시행합니다.
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2023년 7월 13일부터 개정 시행합니다.
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2024년 3월 14일부터 개정 시행합니다.
                                </div>

                                <div class="terms__con le-text-body2 text-gray2">
                                    이 약관은 2024년 7월 1일부터 개정 시행합니다.
                                </div>
                            </div>
                        </div>
                        <!-- //terms(KOR) -->
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
export default {
    name: 'AboutPage',
    components: {
        //
    },

    metaInfo() {
        //
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
