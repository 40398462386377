<template>
    <div 
        class="footer d-flex justify-center"
        :class="{
            'pt-6 pb-6': $vuetify.breakpoint.smAndUp,
            'pt-3 pb-3': $vuetify.breakpoint.xsOnly
        }"
    >
        <div class="c-contanier">
            <div class="d-sm-flex justify-sm-space-between">
                <div>
                    <div 
                        class="text-gray1 le-text-body3 d-flex align-center"
                        :class="{
                            'mb-6': $vuetify.breakpoint.mdAndUp,
                            'mb-3': $vuetify.breakpoint.smOnly,
                            'mb-2': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <div class="d-flex gap-3">
                            <v-btn
                                width="32"
                                height="32"
                                :ripple="false"
                                plain
                                color="white1"
                                class="le-circle-button"
                                href="https://www.instagram.com/openbadgelecos/"
                                target="_blank"
                            >
                                <div class="bg-blue3 rounded-pill">
                                    <v-icon size="32">
                                        fontagon-icons ft-icon ft-ic_instagram
                                    </v-icon>
                                </div>
                            </v-btn>

                            <v-btn
                                width="32"
                                height="32"
                                :ripple="false"
                                plain
                                color="white1"
                                class="le-circle-button"
                                href="https://www.youtube.com/@lecosinc.5171"
                                target="_blank"
                            >
                                <div class="bg-blue3 rounded-pill">
                                    <v-icon size="32">
                                        fontagon-icons ft-icon ft-ic_youtube
                                    </v-icon>
                                </div>
                            </v-btn>

                            <v-btn
                                width="32"
                                height="32"
                                :ripple="false"
                                plain
                                color="white1"
                                class="le-circle-button"
                                href="https://blog.naver.com/lecoskorea"
                                target="_blank"
                            >
                                <div class="bg-blue3 rounded-pill">
                                    <v-icon size="32">
                                        fontagon-icons ft-icon ft-ic_blog
                                    </v-icon>
                                </div>
                            </v-btn>

                            <v-btn
                                width="32"
                                height="32"
                                :ripple="false"
                                plain
                                color="white1"
                                class="le-circle-button"
                                href="https://x.com/LecoSkorea"
                                target="_blank"
                            >
                                <div class="bg-blue3 rounded-pill">
                                    <v-icon size="32">
                                        fontagon-icons ft-icon ft-ic_twitter
                                    </v-icon>
                                </div>
                            </v-btn>
                        </div>
                    </div>

                    <div 
                        class="text-gray1 le-text-body3 d-flex flex-wrap gap-x-5"
                        :class="{
                            'gap-y-2 mb-2': $vuetify.breakpoint.smAndUp,
                            'gap-y-1 mb-1': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <span>㈜레코스 LecoS,Inc.</span>
                    </div>

                    <div 
                        class="text-gray1 le-text-body3 d-flex flex-wrap gap-x-5"
                        :class="{
                            'gap-y-2 mb-2': $vuetify.breakpoint.smAndUp,
                            'gap-y-1 mb-1': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <span>사업자등록번호 : 396-87-02365</span>
                        <span>대표이사 : 노원석</span>
                        <span>주소 : 서울특별시 마포구 마포대로 86 창강빌딩 4층 421호</span>
                    </div>

                    <div 
                        class="text-gray1 le-text-body3 d-flex flex-wrap gap-x-5"
                        :class="{
                            'gap-y-2 mb-2': $vuetify.breakpoint.smAndUp,
                            'gap-y-1 mb-1': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <span>대표번호 : 02-716-5571 <span class="le-text-caption1">(평일 9:00~18:00, 주말/공휴일 제외)</span></span>
                        <span>대표메일 : info@lecos.co.kr</span>
                    </div>

                    <div class="text-gray1 le-text-caption1">
                        ⓒ 2024 LecoS, Inc. All rights reserved.
                    </div>
                </div>

                <div
                    class="d-md-flex flex-grow-1"
                    :class="{
                        'justify-space-between': $vuetify.breakpoint.mdAndUp,
                        'ml-15': $vuetify.breakpoint.smAndUp
                    }"
                    :style="{ 'max-width': $vuetify.breakpoint.smAndUp ? '385px' : '' }"
                >
                    <div 
                        class="d-flex flex-sm-column flex-wrap gap-x-6"
                        :class="{
                            'gap-y-2': $vuetify.breakpoint.smAndUp,
                            'mt-2 gap-y-1': $vuetify.breakpoint.xsOnly,
                        }"
                    >
                        <RouterLink
                            :to="{ name: 'TermsOfServicePage' }"
                            class="text-blue3 d-flex align-center l-text-button l-text-button--medium"
                        >
                            <span class="text">이용약관</span>
                        </RouterLink>

                        <RouterLink
                            :to="{ name: 'PrivacyPage' }"
                            class="text-blue3 d-flex align-center l-text-button l-text-button--medium"
                        >
                            <span class="text">개인정보취급방침</span>
                        </RouterLink>

                        <a
                            href="https://lecos.notion.site/fa480e1608b947ecbdfeb74428e6184f"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="text-blue3 d-flex align-center l-text-button l-text-button--medium"
                        >
                            <span class="text">이용가이드</span>
                        </a>

                        <a
                            href="https://site.imsglobal.org/certifications/lecos-inc/lecosopenbadge#cert_pane_nid_473678"
                            target="_blank"
                            class="text-blue3 d-flex align-center l-text-button l-text-button--medium"
                        >
                            <span class="text">1EdTech 인증서</span>
                        </a>
                    </div>

                    <div
                        class="d-flex" 
                        :class="{
                            'gap-x-5 ml-15': $vuetify.breakpoint.mdAndUp,
                            'gap-x-3': $vuetify.breakpoint.smAndDown,
                            'mt-7': $vuetify.breakpoint.smOnly,
                            'mt-2': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div>
                            <v-img
                                :src="require('@/assets/img/footer-logo-1EdTech.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 50 : ($vuetify.breakpoint.smOnly ? 50 : 37)"
                                alt=""
                            />
                        </div>

                        <div>
                            <v-img
                                :src="require('@/assets/img/footer-logo-TrustEd.png')"
                                :width="$vuetify.breakpoint.mdAndUp ? 54 : ($vuetify.breakpoint.smOnly ? 54 : 40)"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            :style="{ opacity: isVisible ? 1 : 0 }"
            class="page-top"
        >
            <v-btn
                fab
                depressed
                :ripple="false"
                :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                :height="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                color="blue3 rounded-pill text-white1 d-flex align-center justify-center"
                @click="scrollToTop"
            >
                <v-icon
                    size="24"
                    class="ma-0"
                >
                    fontagon-icons ft-icon ft-ic_arrow_up_fill
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer',

    data: function() {
        return {
            isVisible: false
        };
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            this.isVisible = window.scrollY > 0;
        },
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }
};
</script>
