import Vue from 'vue';
import VueRouter from 'vue-router';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePage from '../views/HomePage';
import OpenBadgesPage from '../views/OpenBadgesPage';
import IssuancePage from '../views/IssuancePage';
import ManagementPage from '../views/ManagementPage';
import SampleBadgesPage from '../views/SampleBadgesPage';
import BadgesGalleryPage from '../views/BadgesGalleryPage';
import EducationPage from '../views/EducationPage';
import EnterprisePage from '../views/EnterprisePage';
import AssociationPage from '../views/AssociationPage';
import AboutPage from '../views/AboutPage';
import PressPage from '../views/PressPage';
import NoticePage from '../views/NoticePage';
import NoticeViewPage from '../views/NoticeViewPage';
import ContactPage from '../views/ContactPage';
import UserForOpenbadgePage from '../views/UserForOpenbadgePage';
import PrivacyPage from '../views/PrivacyPage';
import TermsOfServicePage from '../views/TermsOfServicePage';
import NotFoundPage from '../views/NotFoundPage';
import Inquiry from '../components/Inquiry';
import SenbadgesPage from '../views/SenbadgesPage';
import KohveabadgesPage from '../views/KohveabadgesPage';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'HomePage',
        components: {
            header: Header,
            default: HomePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang',
        name: 'HomePageSelectedLanguage',
        components: {
            header: Header,
            default: HomePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/openbadge',
        name: 'OpenbadgesPage',
        components: {
            header: Header,
            default: OpenBadgesPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/openbadge/badge-list',
        name: 'BadgesGalleryPage',
        components: {
            header: Header,
            default: BadgesGalleryPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/service',
        name: 'ServicePage',
        components: {
            header: Header,
            default: IssuancePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/service/issuance',
        name: 'IssuancePage',
        components: {
            header: Header,
            default: IssuancePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/service/management',
        name: 'ManagementPage',
        components: {
            header: Header,
            default: ManagementPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/service/issuing-sample',
        name: 'SampleBadgesPage',
        components: {
            header: Header,
            default: SampleBadgesPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    // {
    //     path: '/:lang/service/badge-list',
    //     name: 'BadgesGalleryPage',
    //     components: {
    //         header: Header,
    //         default: BadgesGalleryPage,
    //         inquiry: Inquiry,
    //         footer: Footer
    //     }
    // },
    {
        path: '/:lang/solutions',
        name: 'SolutionPage',
        components: {
            header: Header,
            default: EducationPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/solutions/education',
        name: 'EducationPage',
        components: {
            header: Header,
            default: EducationPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/solutions/enterprise',
        name: 'EnterprisePage',
        components: {
            header: Header,
            default: EnterprisePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/solutions/association',
        name: 'AssociationPage',
        components: {
            header: Header,
            default: AssociationPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/company',
        name: 'CompanyPage',
        components: {
            header: Header,
            default: AboutPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/company/about',
        name: 'AboutPage',
        components: {
            header: Header,
            default: AboutPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/company/press',
        name: 'PressPage',
        components: {
            header: Header,
            default: PressPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/company/event-list',
        name: 'NoticePage',
        components: {
            header: Header,
            default: NoticePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/company/event-detail',
        name: 'NoticeViewPage',
        components: {
            header: Header,
            default: NoticeViewPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/contact-us',
        name: 'ContactPage',
        components: {
            header: Header,
            default: ContactPage,
            footer: Footer
        }
    },
    {
        path: '/:lang/user-for-openbadge',
        name: 'UserForOpenbadgePage',
        components: {
            header: Header,
            default: UserForOpenbadgePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/terms_of_service', // 오픈배지 사이트와 연동중
        name: 'TermsOfServicePage', // 이용약관
        components: {
            header: Header,
            default: TermsOfServicePage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/privacy',
        name: 'PrivacyPage', // 개인정보취급방침
        components: {
            header: Header,
            default: PrivacyPage,
            inquiry: Inquiry,
            footer: Footer
        }
    },
    {
        path: '/:lang/senbadges',
        name: 'SenbadgesPage',
        // component: UserForOpenBadgePage,
        components: {
            default: SenbadgesPage,
            // default: () => import(/* webpackChunkName: "UserForOpenBadgePage" */ '../views/UserForOpenBadgePage.vue'),
        },
        // props: {
        //     default: true
        // }
    },
    {
        path: '/:lang/kohveabadges',
        name: 'KohveabadgesPage',
        // component: UserForOpenBadgePage,
        components: {
            default: KohveabadgesPage,
            // default: () => import(/* webpackChunkName: "UserForOpenBadgePage" */ '../views/UserForOpenBadgePage.vue'),
        },
        // props: {
        //     default: true
        // }
    },
    {
        path: '*',
        name: 'NotFoundPage',
        components: {
            default: NotFoundPage,
            footer: Footer
        },
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        // 특정 라우터 경로에 대한 조건 추가
        if (to.name === 'BadgesGalleryPage' && from.name === 'BadgesGalleryPage') {
            // 같은 페이지에서 검색어만 변경될 경우 스크롤을 유지
            console.log('savedPosition', savedPosition);
            return savedPosition || { x: 0, y: window.scrollY };
        }
        return { x: 0, y: 0 };
    },
    // scrollBehavior() { 
    //     return { x: 0, y: 0 }; 
    // },
    routes,
});

router.beforeEach((to, from, next) => {
    const defaultLang = 'kor';
    const registedLang = ['kor', 'eng'];

    if ( to.name === 'HomePage') {
        next(`/${defaultLang}`);
    } else if ( to.name === 'NotFoundPage' ) {
        next();
    } else {
        if ( registedLang.find(l => l === to.params.lang) ) {
            next();
        } else {
            next(`${to.params.lang}/404`);
        }
    }
});

export default router;
