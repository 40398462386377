var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":"도입부터 발행까지","sub-title":"즉시 도입 가능한 오픈배지 플랫폼","bg-color":"blue9"}}),_c('section',{staticClass:"c-section bg-blue9 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('div',{staticClass:"d-flex flex-column step-wrap",class:{ 'gap-12': _vm.$vuetify.breakpoint.mdAndUp, 'gap-10': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 01 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 계약 진행 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 발행인 등록을 위한 내용 협의 후 계약 진행 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 02 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 기본 설정 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 발행인 등록 후 오픈배지 시스템 관리자 설정 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 03 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 발행 준비 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 배지 디자인과 배지에 들어가는 데이터 준비 ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 발행 전용 사이트에서 배지 정보 및 수령인 등록 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 04 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/issuance-step-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 배지 발행 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 수여 대상자에게 수여 알림 메일 자동 전송 ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 수령인은 메일을 통해 계정을 생성하고 배지를 수령 ")])])])])],1)])])]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" 도입 항목 및 비용은 문의해주세요. ")]),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-white1",attrs:{"to":{ name: 'ContactPage' },"color":"orange1","depressed":"","large":_vm.$vuetify.breakpoint.mdAndUp}},[_vm._v(" 비용 문의 ")])],1),_c('div',{staticClass:"d-flex justify-center flex-wrap mx-auto",class:{
                    'gap-12 mt-20': _vm.$vuetify.breakpoint.mdAndUp,
                    'gap-10 mt-10': _vm.$vuetify.breakpoint.smAndDown
                },staticStyle:{"max-width":"995px"}},[_c('v-card',{class:{ 'pa-12': _vm.$vuetify.breakpoint.mdAndUp, 'pa-9': _vm.$vuetify.breakpoint.smOnly, 'pa-7': _vm.$vuetify.breakpoint.xsOnly },style:({
                        'width': _vm.$vuetify.breakpoint.mdAndUp ? '470px' : _vm.$vuetify.breakpoint.smOnly ? '380px' : '100%',
                        'max-width': _vm.$vuetify.breakpoint.xsOnly ? '360px' : ''
                    }),attrs:{"color":"blue6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{ 'mr-10': _vm.$vuetify.breakpoint.mdAndUp, 'mr-8': _vm.$vuetify.breakpoint.smAndDown }},[_c('p',{staticClass:"le-heading5"},[_vm._v(" 기본 사항 ")]),_c('v-img',{attrs:{"src":require('@/assets/img/issuance-cost-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"height":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"alt":""}})],1),_c('div',{staticClass:"le-text-body1 mt-1"},[_vm._v(" 무제한 배지 등록"),_c('br'),_vm._v(" 무제한 수여자 등록"),_c('br'),_vm._v(" 배지 대량 발행 가능"),_c('br'),_vm._v(" PDF 인증서 기능"),_c('br'),_vm._v(" 블록체인 기록"),_c('br'),_c('span',{staticClass:"le-text-body3 text-gray3"},[_vm._v("*Block Cert 사용")])])])]),_c('v-card',{class:{ 'pa-12': _vm.$vuetify.breakpoint.mdAndUp, 'pa-9': _vm.$vuetify.breakpoint.smOnly, 'pa-7': _vm.$vuetify.breakpoint.xsOnly },style:({
                        'width': _vm.$vuetify.breakpoint.mdAndUp ? '470px' : _vm.$vuetify.breakpoint.smOnly ? '380px' : '100%',
                        'max-width': _vm.$vuetify.breakpoint.xsOnly ? '360px' : ''
                    }),attrs:{"color":"orange2"}},[_c('div',{staticClass:"d-flex"},[_c('div',{class:{ 'mr-10': _vm.$vuetify.breakpoint.mdAndUp, 'mr-8': _vm.$vuetify.breakpoint.smAndDown }},[_c('p',{staticClass:"le-heading5 white-space-nowrap"},[_vm._v(" 선택 사항 ")]),_c('v-img',{attrs:{"src":require('@/assets/img/issuance-cost-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"height":_vm.$vuetify.breakpoint.mdAndUp ? 136 : (_vm.$vuetify.breakpoint.smAndDown ? 76 : 76),"alt":""}})],1),_c('div',{staticClass:"le-text-body1 mt-1"},[_vm._v(" 배지 디자인 의뢰"),_c('br'),_vm._v(" API 설정 비용"),_c('br'),_c('span',{staticClass:"le-text-body3 text-gray3"},[_vm._v("*고객사 연동은 발행자 직접 진행")]),_c('br'),_vm._v(" 구체적인 배지 컨설팅"),_c('br')])])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }