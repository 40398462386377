<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="오픈배지"
            sub-title="새로운 디지털 인증"
            bg-color="blue9"
        />

        <!-- ANCHOR: 인트로 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <v-row>
                    <v-col
                        cols="12"
                        lg="8"
                        md="9"
                        sm="12"
                        class="mx-auto d-flex flex-column"
                        :class="{
                            'gap-y-15': $vuetify.breakpoint.smAndUp,
                            'gap-y-10': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <!-- 인증을 업그레이드 -->
                        <div class="d-flex openbadge-intro-box">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        인증을 업그레이드
                                    </div>
                                    
                                    <div class="le-heading5">
                                        차별화된 디지털 배지
                                    </div>

                                    <p class="le-text-body2 mt-5">
                                        배지 이미지 안에 개인의 학습이력, 기술, 지식, 명예, 경험, 역량 등의 데이터를 담는 <strong>디지털 인증서</strong>입니다.
                                    </p>
                                </v-card>
                            </div>
                        </div>

                        <!-- 국제 표준 인증 -->
                        <div class="d-flex openbadge-intro-box odd">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 pr-10 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        국제 표준 인증
                                    </div>
                                    
                                    <div class="le-heading5">
                                        아시아 최초 2.0과 3.0인증
                                    </div>

                                    <p class="le-text-body2 mt-5">
                                        교육기술의 국제 표준을 정하는 <strong>1EdTech</strong>의 발행, 관리, 표시 3가지 인증을 모두 받았습니다.
                                    </p>

                                    <div
                                        class="mt-5"
                                        :class="{ 'text-center': $vuetify.breakpoint.xsOnly }"
                                    >
                                        <v-btn
                                            color="orange1"
                                            depressed
                                            :small="$vuetify.breakpoint.smAndDown"
                                            class="text-white1"
                                            href="https://site.imsglobal.org/certifications/lecos-inc/lecosopenbadge#cert_pane_nid_473678"
                                            target="_blank"
                                        >
                                            인증서 바로가기
                                        </v-btn>
                                    </div>
                                </v-card>
                            </div>
                        </div>

                        <!-- 검증 및 보안 -->
                        <div class="d-flex openbadge-intro-box">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-3.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        검증 및 보안
                                    </div>
                                    
                                    <div class="le-heading5">
                                        블록체인 기반 실시간 검증
                                    </div>

                                    <p class="le-text-body2 mt-5">
                                        레코스는 블록체인을 사용하여 증명성의 신뢰를 높여 <strong>위 · 변조</strong>를 방지하며, 클릭 한번으로 인증이 가능합니다.
                                    </p>

                                    <p class="le-text-body3 mt-3">
                                        * 1EdTech 권장 BlockCerts  사용
                                    </p>
                                </v-card>
                            </div>
                        </div>

                        <!-- 즉시 사용 -->
                        <div class="d-flex openbadge-intro-box odd">
                            <div class="position-absolute openbadge-intro-box__img">
                                <v-img
                                    :src="require('@/assets/img/openbadge-intro-4.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 320 : ($vuetify.breakpoint.smOnly ? 212 : 120)"
                                    contain
                                    alt=""
                                />
                            </div>

                            <div class="d-flex align-center w-100 openbadge-intro-box__content-wrap">
                                <v-card class="d-flex flex-column w-100 pr-10 openbadge-intro-box__item">
                                    <div class="le-heading6 text-blue3">
                                        즉시 사용
                                    </div>
                                    
                                    <div class="le-heading5">
                                        비용 절감 실현
                                    </div>

                                    <p class="le-text-body2 mt-5">
                                        인증서의 디지털화로 친환경적이며, 별도의 시스템 구축 없이 레코스 플랫폼에서 <strong>즉시 사용 가능</strong>합니다.
                                    </p>
                                </v-card>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>

        <!-- ANCHOR: 모든 정보 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        모든 정보를 한번에
                    </h2>

                    <p class="le-text-subtitle1">
                        배지에 담긴 <span class="text-blue3">데이터를 확인</span>할 수 있습니다.
                    </p>
                </div>

                <div class="d-flex justify-center">
                    <div>
                        <v-img
                            v-if="$vuetify.breakpoint.smAndUp"
                            :src="require('@/assets/img/openbadge-info-1.png')"
                            :width="$vuetify.breakpoint.mdAndUp ? 634 : ($vuetify.breakpoint.smOnly ? 552 : 328)"
                            contain
                            alt=""
                        />

                        <img
                            v-else
                            :src="require('@/assets/img/openbadge-info-1.png')"
                            alt=""
                        />
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 모든 정보 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        레코스 오픈배지 종류
                    </h2>

                    <p class="le-text-subtitle1">
                        고객이 <span class="text-blue3">원하는 배지를 등록하고 발행</span>할 수 있습니다.
                    </p>
                </div>

                <v-row class="d-flex justify-center">
                    <v-col
                        cols="12"
                        class="d-flex flex-wrap justify-center openbadge-type"
                        :class="{
                            'gap-x-13': $vuetify.breakpoint.mdAndUp,
                            'gap-x-8': $vuetify.breakpoint.smOnly,
                            'flex-column gap-y-9': $vuetify.breakpoint.xsOnly
                        }"
                    >
                        <div
                            class="d-flex justify-center"
                            :class="{
                                'gap-x-13': $vuetify.breakpoint.mdAndUp,
                                'gap-x-8': $vuetify.breakpoint.smOnly,
                            }"
                            :style="{ 'column-gap': $vuetify.breakpoint.xsOnly ? '8%' : '' }"
                        >
                            <!-- 수료 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-1.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    수료
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    교육, 학점
                                </p>
                            </div>

                            <!-- 학위 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-2.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    학위
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    졸업, 수료
                                </p>
                            </div>

                            <!-- 자격 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-3.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    자격
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    수상, 자격증
                                </p>
                            </div>
                        </div>

                        <div
                            class="d-flex justify-center"
                            :class="{
                                'gap-x-13': $vuetify.breakpoint.mdAndUp,
                                'gap-x-8': $vuetify.breakpoint.smOnly,
                            }"
                            :style="{ 'column-gap': $vuetify.breakpoint.xsOnly ? '8%' : '' }"
                        >
                            <!-- 참여 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-4.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    참여
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    행사, 이벤트
                                </p>
                            </div>

                            <!-- 역량 -->
                            <div class="openbadge-type__item">
                                <v-card
                                    class="rounded-circle d-flex justify-center align-center pa-0"
                                    :width="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 100 : ($vuetify.breakpoint.smOnly ? 80 : 80)"
                                >
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/openbadge-type-5.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 70 : ($vuetify.breakpoint.smOnly ? 50 : 50)"
                                            contain
                                            alt=""
                                        />
                                    </div>
                                </v-card>

                                <p class="le-heading5 text-center mt-10">
                                    역량
                                </p>

                                <p
                                    class="le-text-body1 text-center"
                                    :class="{ 
                                        'mt-5': $vuetify.breakpoint.mdAndUp,
                                        'mt-4': $vuetify.breakpoint.smAndDown,
                                    }"
                                >
                                    직무, 승진
                                </p>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getOpenBadgesMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'OpenBadgesPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getOpenBadgesMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
