import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

Vue.use(Vuetify);

const opts = {
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: {
                primary: '#1976D2',
                secondary: '#424242',
                accent: '#82B1FF',
                error: '#FF5252',
                info: '#2196F3',
                success: '#4CAF50',
                warning: '#FFC107',

                white: '#ffffff',
                white1: '#ffffff',
                gray1: '#161616',
                gray2: '#525252',
                gray3: '#8D8D8D',
                gray4: '#A8A8A8',
                gray5: '#C6C6C6',
                gray6: '#E0E0E0',
                gray7: '#F4F4F4',
                blue1: '#0E275D',
                blue2: '#163F99',
                blue3: '#2255DC',
                blue4: '#4E8EE0',
                blue5: '#8CACFF',
                blue6: '#DEE8FF',
                blue7: '#DEF5FF',
                blue8: '#EFF4FF',
                blue9: '#F8FAFF',
                blue10: '#6C99FF',
                blue11: '#57C7FF',
                blue12: '#EAF8FF',
                red1: '#DA1E28',
                yellow1: '#FFFAF2',
                orange1: '#FF6442',
                orange2: '#FFF1EE',
                orange3: '#FF8061',
                green1: '#F1FFFE',
                green2: '#0ED2B1',
            },
            dark: {
                primary: '#FF8C00',
                secondary: '#FF4500',
                accent: '#FFD700',
                error: '#B22222',
                info: '#1E90FF',
                success: '#32CD32',
                warning: '#FFD700',
            },
        },
        dark: false, // 기본 테마를 라이트로 설정
    },
};

export default new Vuetify(opts);
