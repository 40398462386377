import axios from 'axios';
import router from '@/router';
// import store from '@/store';

// export default axios.create({
//     baseURL: '//localhost:8005/api'
// });

/*
    axios 인스턴스를 생성합니다.
    생성할때 사용하는 옵션들 (baseURL, timeout, headers 등)은 다음 URL에서 확인할 수 있습니다.
    https://github.com/axios/axios 의 Request Config 챕터 확인
*/
const instance = axios.create({
    // baseURL: '//localhost:8005/api',
    baseURL: '',
    // baseURL: 
    //     process.env.NODE_ENV !== 'production' // development mode 
    //         ? ''  // vue.config.js의 proxy 사용
    //         : process.env.VUE_APP_REAL_SERVER,
    timeout: 10000
    // 해커 뉴스 API는 커스텀 헤더 넣으면 CORS걸려서 주석처리했습니다.
    // headers: {'X-Custom-Header': 'foobar'}
});

/*
    1. 요청 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 요청 바로 직전 - 인자값: axios config
    2) 요청 에러 - 인자값: error
*/
instance.interceptors.request.use(
    function (config) {
        // 요청 바로 직전
        // axios 설정값에 대해 작성합니다.
        return config;
    }, 
    function (error) {
        // 요청 에러 처리를 작성합니다.
        return Promise.reject(error);
    }
);

/*
    2. 응답 인터셉터를 작성합니다.
    2개의 콜백 함수를 받습니다.

    1) 응답 정성 - 인자값: http response
    2) 응답 에러 - 인자값: http error
*/
instance.interceptors.response.use(
    function (response) {
    /*
        http status가 200인 경우
        응답 바로 직전에 대해 작성합니다. 
        .then() 으로 이어집니다.
    */
        console.log('200!');

        if (response.data.resultCode === 404) {
            // goto NotFound page
            console.log('not found page');
            router.push({ name: 'NotFoundPage', params: { '0': '404' } });

        }
        
        // if (response.data.status === 401) {
        //     // goto login page
        //     const currentUserType = store.getters['auth/getCurrentUserType'];
        //     const userTypes = store.getters['auth/getUserTypes'];

        //     if (parseInt(currentUserType) === userTypes.OBUSER) {
        //         store.dispatch('auth/actRemoveCookie');
        //         router.push({ name: 'LoginPage' });
        //     }
        //     else {
        //         window.location.href = process.env.VUE_APP_MVLOGIN + '?ReturnUrl=' + location.pathname + location.hash;
        //     }
        // }
        // if (response.data.status === 403) {
        //     // goto unauthorized page
        //     router.push({ name: 'UnauthorizedPage' });
        // }
        // if (response.data.status === 404) {
        //     // goto NotFound page           
        //     router.push({ name: 'NotFoundPage' });
        // }
        
        return response;
    },

    function (error) {
    /*
        http status가 200이 아닌 경우
        응답 에러 처리를 작성합니다.
        .catch() 으로 이어집니다.    
        */
        // console.log('200이 아닌 경우!', error.response);
        // console.log('200이 아닌 경우!', error.response.status);
        // router.push({ name: 'NotFoundPage', query: { 'error': error } });
        if (error.response) {
            if (error.response.status === 404) {
                router.push({ name: 'NotFoundPage' });
            }
        } else {
            alert('[서버 장애 안내]\n신속한 복구를 위해 노력하겠습니다.\n서비스 이용에 불편을 드려 죄송합니다.\n문의처 : 02-716-5571~2');
        }
        return Promise.reject(error);
    }
);

// 생성한 인스턴스를 익스포트 합니다.
export default instance;