export default {
    // 전체 공지사항 목록 반환
    getNoticeItems(state) {
        return state.notice;
    },

    // 특정 id에 대한 공지사항 반환
    getNoticeItem: (state) => {
        return (id) => {
            return state.notice.find(item => item.id === id);
        };
    },

    // 전체 언론보도 목록 반환
    getPressItems(state) {
        return state.press;
    },
};