<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="공지사항"
            sub-title="레코스의 새로운 소식과 이벤트"
            bg-color=""
        />

        <!-- ANCHOR: 테이블 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row class="d-flex justify-center">
                    <v-col
                        cols="12"
                        lg="8"
                        md="10"
                        sm="12"
                    >
                        <v-simple-table class="le-simple-table">
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                        v-for="item in getNoticeItems"
                                        :key="item.id"
                                        @click="goToNotice(item.id)"
                                    >
                                        <td>
                                            <div>
                                                <div class="d-flex flex-column gap-3">
                                                    <div>
                                                        <v-chip
                                                            class="text-white1"
                                                            small
                                                            :color="item.type === '1' ? 'blue10' : (item.type === '2' ? 'orange1' : '')"
                                                        >
                                                            {{ item.type === '1' ? '공지사항' : (item.type === '2' ? '이벤트' : '') }}
                                                        </v-chip>
                                                    </div>
                                            
                                                    <div class="le-text-subtitle3 truncate-two-lines">
                                                        {{ item.title }}
                                                    </div>

                                                    <div class="le-text-body3">
                                                        {{ item.date }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapGetters } from 'vuex';
import { getNoticeMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'NoticePage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getNoticeMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        ...mapGetters('notice', ['getNoticeItems']),
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 화면 이동
         * ============================================================ */
        goToNotice(id) {
            this.$router.push({ name: 'NoticeViewPage', query: { id } });
        }
    },
    // !SECTION: Methods
};
</script>
