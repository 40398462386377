// import axios from 'axios';
import api from '@/api';
import {
//
} from './mutation-types';

export default {
    // 서울 특별시 교육청 샘플 배지 발행
    // eslint-disable-next-line no-unused-vars
    issueBadge ({ commit }, issue) {
        issue.company = 'kohvea';
        console.log('action! kohveabadges', issue);
        // https://lecos.co.kr/api/v1/OpenBadges
        return api.post('/api/v1/OpenBadges', issue, {
            headers: {
                'Accept-Language': issue.lang
            },
        });
    },
};
