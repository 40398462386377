<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="로그인"
            sub-title="오픈배지 서비스 메뉴"
            bg-color=""
        />

        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row>
                    <v-col
                        cols="12"
                        md="11"
                        lg="10"
                        class="mx-auto"
                        :class="{
                            'd-flex align-start justify-space-between': $vuetify.breakpoint.mdAndUp,
                        }"
                    >
                        <!-- 발행인 로그인 -->
                        <div
                            style="flex: 1 1 50%;"
                            :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '384px' : '' }"
                            class="d-flex flex-column align-center justify-center"
                        >
                            <p 
                                class="le-heading4" 
                                :class="{
                                    'mb-6': $vuetify.breakpoint.mdAndUp,
                                    'mb-5': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                발행인 로그인
                            </p>

                            <div>
                                <v-img
                                    :src="require('@/assets/img/user-for-openbadge-1.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 384 : ($vuetify.breakpoint.smOnly ? 360 : 300)"
                                    contain
                                    alt=""
                                />
                            </div>
                            
                            <p
                                class="le-text-body1 text-center"
                                :class="{
                                    'mt-10': $vuetify.breakpoint.mdAndUp,
                                    'mt-9': $vuetify.breakpoint.smOnly,
                                    'mt-6': $vuetify.breakpoint.xsOnly
                                }"
                            >
                                배지 등록 및 발행을 위한 사이트
                            </p>

                            <div
                                :class="{
                                    'mt-15': $vuetify.breakpoint.mdAndUp,
                                    'mt-12': $vuetify.breakpoint.smOnly,
                                    'mt-10': $vuetify.breakpoint.xsOnly
                                }"
                                class="d-flex gap-7"
                            >
                                <v-btn
                                    href="https://nlp.netlearning.co.jp/ns/login/LogIn.aspx?ReturnUrl=/ns/comsys/Default.aspx&lang=en"
                                    target="_blank"
                                    color="orange1"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    depressed
                                    class="text-white1"
                                >
                                    로그인
                                </v-btn>

                                <v-btn
                                    href="https://lecos.notion.site/b1cbcf01272049f1910984db79e92cbd"
                                    target="_blank"
                                    color="blue1"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    depressed
                                    class="text-white1"
                                >
                                    발행 가이드
                                </v-btn>
                            </div>
                        </div>

                        <!-- 수령인 로그인 -->
                        <div
                            style="flex: 1 1 50%;"
                            :style="{ 'max-width': $vuetify.breakpoint.mdAndUp ? '384px' : '' }"
                            class="d-flex flex-column align-center justify-center"
                            :class="{
                                'mt-22': $vuetify.breakpoint.smAndDown
                            }"
                        >
                            <p 
                                class="le-heading4" 
                                :class="{
                                    'mb-6': $vuetify.breakpoint.mdAndUp,
                                    'mb-5': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                수령인 로그인
                            </p>

                            <div>
                                <v-img
                                    :src="require('@/assets/img/user-for-openbadge-2.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 384 : ($vuetify.breakpoint.smOnly ? 360 : 300)"
                                    alt=""
                                />
                            </div>
                            
                            <p
                                class="le-text-body1 text-center"
                                :class="{
                                    'mt-10': $vuetify.breakpoint.mdAndUp,
                                    'mt-9': $vuetify.breakpoint.smOnly,
                                    'mt-6': $vuetify.breakpoint.xsOnly
                                }"
                            >
                                배지 수령 및 관리를 위한 사이트
                            </p>

                            <div
                                :class="{
                                    'mt-15': $vuetify.breakpoint.mdAndUp,
                                    'mt-12': $vuetify.breakpoint.smOnly,
                                    'mt-10': $vuetify.breakpoint.xsOnly
                                }"
                                class="d-flex gap-7"
                            >
                                <v-btn
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/login"
                                    target="_blank"
                                    color="orange1"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    depressed
                                    class="text-white1"
                                >
                                    로그인
                                </v-btn>

                                <v-btn
                                    href="https://lecos.notion.site/8755f872723b4027a1e678a1de3961a5"
                                    target="_blank"
                                    color="blue1"
                                    :small="$vuetify.breakpoint.smAndDown"
                                    depressed
                                    class="text-white1"
                                >
                                    사용 가이드
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getLoginMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'AboutPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getLoginMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
