<template>
    <!-- SECTION: Template -->
    <div>
        <!-- ANCHOR: MainVisual -->
        <div class="main-visual d-flex justify-center align-center">
            <div
                class="main-visual__body"
                :class="{ 'loading': isVisualLoading }"
            >
                <div class="main-visual__item">
                    <div class="visual-text">
                        <p class="le-heading5">
                            국제표준 디지털배지
                        </p>

                        <p class="le-heading1 mt-3">
                            오픈배지
                        </p>

                        <p class="le-text-subtitle1 mt-3">
                            Open Badges
                        </p>

                        <p class="le-text-body1 text-center mt-5">
                            차원이 다른 디지털 인증<br>
                            레코스 오픈배지가 제공합니다.
                        </p>

                        <div class="mt-10">
                            <v-btn
                                :to="{ name: 'ContactPage' }"
                                color="orange1"
                                depressed
                                :large="$vuetify.breakpoint.smAndUp"
                                class="text-white1"
                            >
                                도입 문의
                            </v-btn>
                        </div>
                    </div>

                    <div class="art-board">
                        <div class="asset asset1">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset1.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset2">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset2.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset2x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset3">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset3.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset3x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset4">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset4.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset4x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset5">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset5.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset6">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset6.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset7">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset7.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset8">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset8.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset8x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset9">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset9.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset9x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset10">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset10.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset10x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset11">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset11.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset11x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset12">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset12.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset12x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset13">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset13.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset14">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset14.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset15">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset15.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset15x1.png')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset16">
                            <v-img
                                :src="require('@/assets/img/main-asset/asset16.svg')"
                                alt=""
                            />
                        </div>
                        <div class="asset asset17">
                            <v-img
                                v-if="isMobile || isTablet"
                                :src="require('@/assets/img/main-asset/asset17.png')"
                                alt=""
                            />

                            <v-img
                                v-else
                                :src="require('@/assets/img/main-asset/asset17x1.png')"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="main-visual__arrow">
                <v-img
                    :src="require('@/assets/svg/others/ic_chevrons_down_outline.svg')"
                    alt=""
                    :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 32 : 32)"
                />
            </div>

            <div class="quick-menu-wrap c-contanier d-flex justify-center align-center position-relative h-100">
                <!-- 퀵메뉴 -->
                <div
                    v-if="!$vuetify.breakpoint.smAndDown"
                    class="d-flex flex-column quick-menu gap-3"
                >
                    <v-btn
                        :to="{ name: 'ContactPage' }"
                        color="blue4"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_questionMark_circle_fill
                        </v-icon>

                        문의 하기
                    </v-btn>

                    <v-btn
                        :to="{ name: 'SampleBadgesPage' }"
                        color="blue3"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_badge_fill
                        </v-icon>
                        샘플배지 발행
                    </v-btn>

                    <v-btn
                        href="https://www.openbadge-global.com/ns/portal/openbadge/login"
                        target="_blank"
                        color="blue2"
                        depressed
                        class="rounded-pill text-white1 text-left"
                    >
                        <v-icon size="24">
                            fontagon-icons ft-icon ft-ic_wallet_fill
                        </v-icon>
                        배지 지갑 로그인
                    </v-btn>
                </div>
            </div>
        </div>

        <!-- ANCHOR: 최다 고객사 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    국내 및 아시아<br />최다 고객사 및 발행 사례 보유
                </h2>

                <div class="d-flex client-stats">
                    <!-- 도입 고객사 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-company.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            250+
                        </div>

                        <div class="le-heading6 mb-3">
                            도입 고객사
                        </div>
                    </div>

                    <!-- 등록된 배지 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-certificate.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            2,000+
                        </div>

                        <div class="le-heading6 mb-3">
                            등록된 배지
                        </div>
                    </div>

                    <!-- 발행된 배지 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj "
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-badge.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            100,000+
                        </div>

                        <div class="le-heading6 mb-3">
                            발행된 배지
                        </div>
                    </div>

                    <!-- 지갑 사용자 -->
                    <div class="client-stats__column text-center">
                        <div
                            class="circle-obj"
                            :class="{'mb-10': !$vuetify.breakpoint.xsOnly, 'mb-5': $vuetify.breakpoint.xsOnly}"
                        >
                            <v-avatar
                                class="rounded-0"
                                :size="($vuetify.breakpoint.mdAndUp ? 100 : $vuetify.breakpoint.smOnly ? 76 : $vuetify.breakpoint.xsOnly ? 73 : 100)"
                            >
                                <v-img
                                    :src="require('@/assets/img/home-largest-wallet.png')"
                                    alt=""
                                />
                            </v-avatar>
                        </div>

                        <div class="le-heading4 text-blue3 mb-5">
                            25,000+
                        </div>

                        <div class="le-heading6 mb-3">
                            지갑 사용자
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 롤링 배너 -->
        <div 
            class="rbn bg-blue8" 
            :class="{
                'py-16': $vuetify.breakpoint.mdAndUp,
                'py-15': $vuetify.breakpoint.smOnly,
                'py-10': $vuetify.breakpoint.xsOnly,
            }"
        >
            <swiper :options="rbnSwiperOptions">
                <swiper-slide
                    v-for="(image, index) in rbnImages"
                    :key="index"
                >
                    <img
                        :src="image"
                        alt="Banner Image"
                    />
                </swiper-slide>
            </swiper>
        </div>

        <!-- ANCHOR: 높은 고객 만족도 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    높은 고객 만족도
                </h2>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 후기 1 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="blue7"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-blue.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-body1 mb-5">
                                                오픈배지 도입 후 <strong>자격증 취득이 5배나 상승</strong>하고 학생들에게도 좋은 동기 부여가 되었습니다.
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                C 고등학교 교사
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 후기 2 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="orange2"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-orange.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-body1 mb-5">
                                                종이 자격증을 오픈배지로 대체하고 <strong>비용과 시간이 두 배 이상 감소</strong>했습니다.
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smOnly ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smOnly ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                K사 자격담당자
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 후기 3 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between h-100"
                                        color="green1"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div>
                                            <div class="mb-5 w-100">
                                                <v-img
                                                    :src="require('@/assets/img/home-quotation-green.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 60 : ($vuetify.breakpoint.smOnly ? 60 : 48)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-body1 mb-5">
                                                개성 있는 디자인의 오픈배지는 저만의 <strong>특별한 포트폴리오를 완성</strong>시켜 주었어요.
                                            </p>
                                        </div>

                                        <div class="d-flex flex-column justify-center align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-high-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                                    alt=""
                                                />
                                            </div>

                                            <p class="le-text-subtitle3">
                                                H 대학 학생
                                            </p>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 완벽한 오픈배지 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        완벽한 오픈배지 서비스
                    </h2>

                    <p class="le-text-subtitle1">
                        레코스에서 발행, 수령, 검증을 한 번에
                    </p>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 발행 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                발행
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #배지 등록
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #배지 수여
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'IssuancePage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 수령 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                수령
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #배지 수령
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #배지 공유
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'ManagementPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 검증 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                검증
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #배지 실시간 검증
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-perfect-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    href="https://www.lecos.co.kr/openbadge/verify.html"
                                                    target="_blank"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 프리미엄 오픈배지 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        프리미엄 오픈배지 솔루션
                    </h2>

                    <p class="le-text-subtitle1">
                        전문가의 최적화된 솔루션 제공
                    </p>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 교육 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="gray7"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                교육
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #교육인증
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #경쟁력
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #동기부여
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-1.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'EducationPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 기업 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="orange2"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                기업
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #인재관리
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #마케팅
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #역량개발
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-2.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'EnterprisePage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 기관 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card
                                        class="d-flex flex-column align-center justify-space-between w-100 h-100"
                                        color="blue6"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="d-flex flex-column align-center">
                                            <p class="le-heading4 mb-3">
                                                기관
                                            </p>

                                            <div class="d-flex flex-wrap justify-center gap-2 mb-5">
                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #신뢰성
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #참여율
                                                </v-chip>

                                                <v-chip
                                                    outlined
                                                    small
                                                >
                                                    #홍보효과
                                                </v-chip>
                                            </div>
                                        </div>

                                        <div class="d-flex flex-column align-center">
                                            <div
                                                :class="{
                                                    'mb-10': $vuetify.breakpoint.mdAndUp,
                                                    'mb-5': $vuetify.breakpoint.smAndDown
                                                }"
                                            >
                                                <v-img
                                                    :src="require('@/assets/img/home-premium-3.png')"
                                                    :width="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    :height="$vuetify.breakpoint.mdAndUp ? 112 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                    alt=""
                                                />
                                            </div>

                                            <div>
                                                <v-btn
                                                    :to="{ name: 'AssociationPage' }"
                                                    color="orange1"
                                                    :small="$vuetify.breakpoint.xsOnly"
                                                    depressed
                                                    class="text-white1"
                                                >
                                                    자세히 보기
                                                </v-btn>
                                            </div>
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 오픈배지의 모든 것 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 mb-5">
                        오픈배지의 모든 것,<br>레코스에 있습니다.
                    </h2>
                </div>

                <div class="d-flex">
                    <v-row class="d-flex justify-center">
                        <v-col
                            cols="12"
                            md="10"
                        >
                            <v-row class="d-flex justify-center">
                                <!-- 편리함 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-1.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            편리함
                                        </p>

                                        <div class="le-text-body1 text-center">
                                            이용 가이드 제공<br>
                                            지속적인 정보 제공
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 신속함 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-2.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            신속함
                                        </p>

                                        <div class="le-text-body1 text-center">
                                            상시 기술 지원<br>
                                            빠른 고객 대응
                                        </div>
                                    </v-card>
                                </v-col>

                                <!-- 전문성 -->
                                <v-col
                                    cols="12"
                                    md="4"
                                    sm="4"
                                    :style="$vuetify.breakpoint.xsOnly ? 'max-width: 370px;' : ''"
                                    class="d-flex justify-center"
                                >
                                    <v-card 
                                        class="d-flex flex-column align-center w-100"
                                        :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 282px;' : ''"
                                    >
                                        <div class="mb-5">
                                            <v-img
                                                :src="require('@/assets/img/home-all-3.png')"
                                                :width="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 72 : 112)"
                                                alt=""
                                            />
                                        </div>

                                        <p class="le-heading4 mb-5">
                                            전문성
                                        </p>

                                        <div class="le-text-body1 text-center">
                                            고객 맞춤 서비스<br>
                                            풍부한 도입사례
                                        </div>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </div>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getHomeMetaInfo } from '@/utils/metaInfo.js';
// https://github.surmon.me/vue-awesome-swiper
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
    name: 'HomePage',
    components: {
        Swiper,
        SwiperSlide
    },

    // SECTION: Meta Information
    metaInfo() {
        return getHomeMetaInfo(this);
    },
    // !SECTION: Meta Information

    // SECTION: Data Properties
    data: function () {
        return {
            isMobile: isMobile,
            isTablet: isTablet,
            isVisualLoading: true,
            rbnSwiperOptions: {
                loop : true, // 슬라이드 반복 여부
                loopAdditionalSlides : 1,
                autoplay: {
                    delay: 0, // 자동 재생 지연 시간 (0으로 설정하여 계속 스크롤)
                    disableOnInteraction: false // 사용자 상호작용에도 자동 재생 계속
                },
                // slidesPerView: '7', // 화면에 보이는 슬라이드 수 자동 조정
                spaceBetween: 40, // 슬라이드 사이의 간격
                speed: 9000, // 스크롤 속도 (밀리초 단위)
                freemode: false,

                breakpoints: {
                    0: { // 모바일 뷰포트
                        // spaceBetween: 40,
                        slidesPerView: 3
                    },
                    600: { // 태블릿 뷰포트
                        slidesPerView: 5
                    },
                    960: { // 데스크탑 뷰포트
                        slidesPerView: 7
                    },
                    1400: { // 데스크탑 뷰포트
                        slidesPerView: 11
                    }
                }
            },
            rbnImages: [
                require('@/assets/img/home-rbn-1.png'),
                require('@/assets/img/home-rbn-2.png'),
                require('@/assets/img/home-rbn-3.png'),

                require('@/assets/img/home-rbn-4.png'),
                require('@/assets/img/home-rbn-5.png'),
                require('@/assets/img/home-rbn-6.png'),

                require('@/assets/img/home-rbn-7.png'),
                require('@/assets/img/home-rbn-8.png'),
                require('@/assets/img/home-rbn-9.png'),

                require('@/assets/img/home-rbn-10.png'),
                require('@/assets/img/home-rbn-11.png'),
                require('@/assets/img/home-rbn-12.png'),
            ],
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        //
    },

    watch: {
        //
    },

    created() {
        //
    },

    mounted() {
        this.isVisualLoading = false;
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        //
    },
    // !SECTION: Methods
};
</script>
