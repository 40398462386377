<template>
    <div class="bn-inquiry-wrap">
        <div
            class="bn-inquiry le-heading5-5 d-flex flex-column align-center justify-center text-center"
            :class="{'tablet': isTablet }"
        >
            <p :class="{ 'mb-2': $vuetify.breakpoint.mdAndUp, 'mb-3': $vuetify.breakpoint.smAndDown }">
                지금, 레코스에서<br v-if="$vuetify.breakpoint.smAndDown"> 오픈배지를 시작하세요.
            </p>

            <p
                class="le-text-body3"
                :class="{ 'mb-6': $vuetify.breakpoint.mdAndUp, 'mb-5': $vuetify.breakpoint.smAndDown }"
            >
                문의 메일을 주시면 빠르게<br v-if="$vuetify.breakpoint.smAndDown"> 상담을 도와드립니다.
            </p>

            <v-btn
                :to="{ name: 'ContactPage' }"
                color="blue4"
                depressed
                :small="!$vuetify.breakpoint.mdAndUp"
                class="text-white1"
            >
                도입 문의
            </v-btn>
        </div>
    </div>
</template>

<script>
import { isMobile, isTablet } from 'mobile-device-detect';

export default {
    name: 'Inquiry',

    data: function() {
        return {
            isMobile: isMobile,
            isTablet: isTablet,
        };
    },

    mounted() {
        //
    },

    methods: {
        //
    }
};
</script>