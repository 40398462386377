<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="기관"
            sub-title="신뢰를 더하는 오픈배지"
            bg-color="green1"
        />

        <!-- ANCHOR: 로드 -->
        <section class="c-section bg-green1 d-flex justify-center">
            <div class="c-contanier">
                <div class="road-wrap road-bg road-association">
                    <div class="road-bg__left road-bg__left--association"></div>
                    <div class="road-bg__center road-bg__center--association"></div>
                    <div class="road-bg__right road-bg__right--association"></div>
    
                    <div class="road-content">
                        <!-- road row 1 -->
                        <div class="road-row road-row-1">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :class="{ 'mt-5 mr-17': $vuetify.breakpoint.mdAndUp }"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '18px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/association-road-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 224 : ($vuetify.breakpoint.smOnly ? 146 : 125)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            국제 표준
                                        </div>
                                    
                                        <div class="le-heading5">
                                            신뢰성 강화
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            <strong>국제 표준 규격을 사용하여 발행</strong>되는 오픈배지는 디지털 인증에 신뢰를 더합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 2 -->
                        <div class="road-row road-row-2">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-16px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/association-road-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 249 : ($vuetify.breakpoint.smOnly ? 164 : 150)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            소속감 강화
                                        </div>
                                    
                                        <div class="le-heading5">
                                            효율적 회원 관리
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            오픈배지를 사용하여 <strong>회원을 체계적</strong>으로 관리하고 소속 회원 혜택을 강화할 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 3 -->
                        <div class="road-row road-row-3">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '12px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/association-road-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 217 : ($vuetify.breakpoint.smOnly ? 141 : 121)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            참여 확산
                                        </div>
                                    
                                        <div class="le-heading5">
                                            다양한 활용
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            회원 인증, 교육, 이벤트 참여 등 여러 오픈배지를 활용한 다양한 인증은 <strong>지속적인 참여를 장려</strong>할 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 4 -->
                        <div class="road-row road-row-4">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :class="{ 'ml-8': $vuetify.breakpoint.mdAndUp, 'ml-5': $vuetify.breakpoint.smOnly }"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-17px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/association-road-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 294 : ($vuetify.breakpoint.smOnly ? 189 : 163)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            마케팅 활용
                                        </div>
                                    
                                        <div class="le-heading5">
                                            프로그램 홍보
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            소속 회원이 배지를 공유할 때마다 프로그램이 홍보되며 <strong>새로운 회원을 유치</strong>하는데 도움을 줄 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 선택한 -->
        <section class="c-section d-flex flex-column justify-center pb-0">
            <div
                class="c-contanier"
                :class="{' mx-auto w-100': $vuetify.breakpoint.lgAndUp}"
            >
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 pb-5">
                        레코스를 선택한 열정적인 기관
                    </h2>
                </div>

                <div
                    class="d-flex flex-column"
                    :class="{ 'gap-10': $vuetify.breakpoint.smAndUp }"
                >
                    <v-row
                        class="d-flex align-center justify-center"
                    >
                        <v-col
                            cols="12"
                            lg="10"
                            md="11"
                            sm="12"
                            class="pa-0"
                            :class="{
                                'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
                                'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <!-- 서울특별시교육청 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/association-institution-1.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center text-break-all">
                                        서울특별시교육청
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        교육 프로그램 및 박람회<br>
                                        교육 수료, 활동 인증, 체험 인증
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 국어국립원 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/association-institution-2.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center text-break-all">
                                        국어국립원
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        국외 한국어교원 (K-티처)<br>온라인 프로그램 이수
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 시립성북청소년센터 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/association-institution-3.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center text-break-all">
                                        시립성북청소년센터
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        청소년 프로그램 및 행사<br>
                                        교육 수료, 행사 참여
                                    </p>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <!-- 블로그 바로가기 -->
            <BannerBlog />
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getAssociationMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';
import BannerBlog from '@/components/BannerBlog.vue';

export default {
    name: 'EducationPage',
    components: {
        PageTitle,
        BannerBlog
    },

    metaInfo() {
        return getAssociationMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
