<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="기업"
            sub-title="기업의 가치를 높이는 오픈배지"
            bg-color="yellow1"
        />

        <!-- ANCHOR: 로드 -->
        <section class="c-section bg-yellow1 d-flex justify-center">
            <div class="c-contanier">
                <div class="road-wrap road-bg road-enterprise">
                    <div class="road-bg__left road-bg__left--enterprise"></div>
                    <div class="road-bg__center road-bg__center--enterprise"></div>
                    <div class="road-bg__right road-bg__right--enterprise"></div>
    
                    <div class="road-content">
                        <!-- road row 1 -->
                        <div class="road-row road-row-1">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :class="{ 'mt-9': $vuetify.breakpoint.mdAndUp }"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '4px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/enterprise-road-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 283 : ($vuetify.breakpoint.smOnly ? 203 : 158)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            효율성
                                        </div>
                                    
                                        <div class="le-heading5">
                                            인재 관리
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            오픈배지 데이터를 통해 직원 역량을 파악하여 <strong>적재적소에 인재 배치</strong>가 가능합니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 2 -->
                        <div class="road-row road-row-2">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-26px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/enterprise-road-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 269 : ($vuetify.breakpoint.smOnly ? 206 : 161)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            역량 관리
                                        </div>
                                    
                                        <div class="le-heading5">
                                            직원 기술 및 스킬
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            <strong>직원들에게 맞춤형 교육을 제공</strong>하고 학습 이력을 체계적으로 관리할 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 3 -->
                        <div class="road-row road-row-3">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '4px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/enterprise-road-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 274 : ($vuetify.breakpoint.smOnly ? 178 : 150)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--left">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            학습 보상
                                        </div>
                                    
                                        <div class="le-heading5">
                                            확실한 동기부여
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            시각적으로 명확한 보상을 제공하여 지속적인 <strong>학습 동기 부여와 역량 향상</strong>에 도움을 줍니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- road row 4 -->
                        <div class="road-row road-row-4">
                            <div class="speech-bubble-wrap">
                                <div
                                    class="road-obj"
                                    :style="{ 'right': $vuetify.breakpoint.xsOnly ? '-7px' : '' }"
                                >
                                    <v-img
                                        :src="require('@/assets/img/enterprise-road-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 205 : ($vuetify.breakpoint.smOnly ? 135 : 112)"
                                        contain
                                        alt=""
                                    />
                                </div>

                                <div class="speech-bubble speech-bubble--right">
                                    <div class="speech-bubble__body">
                                        <div class="le-heading6 text-blue3">
                                            마케팅 활용
                                        </div>
                                    
                                        <div class="le-heading5">
                                            브랜드 인지도 강화
                                        </div>

                                        <p class="le-text-body2 mt-5">
                                            오픈배지 이미지 안에 기업 브랜딩을 담아 <strong>홍보와 인지도 상승에 활용</strong>할 수 있습니다.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 선택한 -->
        <section class="c-section d-flex flex-column justify-center pb-0">
            <div
                class="c-contanier"
                :class="{' mx-auto w-100': $vuetify.breakpoint.lgAndUp}"
            >
                <div class="c-title text-center">
                    <h2 class="text-center le-heading3 pb-5">
                        레코스를 선택한 혁신적인 기업
                    </h2>
                </div>

                <div
                    class="d-flex flex-column"
                    :class="{ 'gap-10': $vuetify.breakpoint.smAndUp }"
                >
                    <v-row
                        class="d-flex align-center justify-center"
                    >
                        <v-col
                            cols="12"
                            lg="10"
                            md="11"
                            sm="12"
                            class="pa-0"
                            :class="{
                                'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
                                'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <!-- ㈜케이티 AICE -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-1.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        ㈜케이티 AICE
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        초등학생부터 성인까지<br>
                                        인공지능 능력시험 합격자
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- ㈜케이티 에이블스쿨 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-2.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        ㈜케이티 에이블스쿨
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        AI 개발자, DX 컨설턴트<br>
                                        교육 수료, 우수 성적, 참여
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- (사)한국능률협회 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-3.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        (사)한국능률협회
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        성인 교육 및 경영 컨설팅<br>
                                        10개 카테고리 교육 수료
                                    </p>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>

                    <v-row
                        class="d-flex align-center justify-center"
                    >
                        <v-col
                            cols="12"
                            lg="10"
                            md="11"
                            sm="12"
                            class="pa-0"
                            :class="{
                                'd-flex justify-space-between': $vuetify.breakpoint.smAndUp,
                                'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <!-- ㈜알파코 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-4.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        ㈜알파코
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        AI전문인력 양성 교육<br>
                                        과정 수료, 레벨 인증
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- 네스트랩 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-5.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 text-center mt-5">
                                        네스트랩
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        디지털 산업 맞춤형 교육<br>이론 및 실무 교육 수료
                                    </p>
                                </v-card>
                            </v-col>

                            <!-- ㈜글로브포인트 -->
                            <v-col
                                cols="12"
                                md="4"
                                sm="4"
                                style="max-width: 320px;"
                            >
                                <v-card class="d-flex flex-column align-center h-100 pl-1 pr-1">
                                    <div>
                                        <v-img
                                            :src="require('@/assets/img/enterprise-institution-6.png')"
                                            :width="$vuetify.breakpoint.mdAndUp ? 204 : ($vuetify.breakpoint.smAndDown ? 116 : 116)"
                                            :height="$vuetify.breakpoint.mdAndUp ? 138 : ($vuetify.breakpoint.smAndDown ? 76 : 76)"
                                            alt=""
                                        />
                                    </div>

                                    <p class="le-heading5-5 mt-5 text-center">
                                        ㈜글로브포인트
                                    </p>

                                    <p class="le-text-body2 mt-5 text-center">
                                        VR 온라인 교육<br>학습 수료, 자격증
                                    </p>
                                </v-card>
                            </v-col>
                        </v-col>
                    </v-row>
                </div>
            </div>

            <!-- 블로그 바로가기 -->
            <BannerBlog />
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getEnterpriseMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';
import BannerBlog from '@/components/BannerBlog.vue';

export default {
    name: 'EducationPage',
    components: {
        PageTitle,
        BannerBlog
    },

    metaInfo() {
        return getEnterpriseMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
