<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: 문의하기 -->
        <section class="d-flex justify-center nl-has-bg pb-15">
            <div class="c-contanier contact-wrap">
                <p class="le-text-subtitle2 text-center">
                    아래 내용을 입력하시고 배지를 발행해 보세요.<br>
                </p>

                <!-- ANCHOR: Form-문의하기 -->
                <div class="mt-10">
                    <v-form ref="form">
                        <!-- 이름 -->
                        <v-row
                            no-gutters
                            class="d-flex flex-column justify-center align-center"
                        >
                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="text-right mb-3"
                            >
                                <p class="text-red1">
                                    *표시는 필수 입력 항목입니다.
                                </p>
                            </v-col>

                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="pb-0 pt-0 mb-2"
                            >
                                <p class="contact_title mb-3">
                                    <strong class="le-text-subtitle3">이름</strong>
                                    <span class="le-text-body2 text-red1 ml-1">*</span>
                                </p>

                                <v-text-field
                                    v-model="name"
                                    :rules="[v => !!v || '이름을 입력해주세요.']"
                                    outlined
                                    color="blue1"
                                    :dense="$vuetify.breakpoint.smAndDown"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <!-- 이메일 -->
                        <v-row
                            no-gutters
                            class="d-flex flex-column justify-center align-center"
                        >
                            <v-col
                                cols="10"
                                md="4"
                                sm="8"
                                class="pb-0 pt-0 mb-2"
                            >
                                <p class="contact_title mb-3">
                                    <strong class="le-text-subtitle3">이메일</strong>
                                    <span class="le-text-body2 text-red1 ml-1">*</span>
                                </p>

                                <v-text-field
                                    v-model="email"
                                    :rules="[
                                        v => !!v || '이메일을 입력해주세요.',
                                        v => /.+@.+\..+/.test(v) || '유효한 이메일 주소를 입력해주세요.'
                                    ]"
                                    outlined
                                    color="blue1"
                                    placeholder="info@lecos.co.kr"
                                    :dense="$vuetify.breakpoint.smAndDown"
                                ></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row class="mt-10">
                            <v-col class="d-flex align-center justify-center pt-0 pb-0">
                                <v-btn
                                    color="orange1"
                                    :large="$vuetify.breakpoint.smAndUp"
                                    depressed
                                    class="text-white1"
                                    @click="showIssuingCheckDialogVisible"
                                >
                                    발행하기
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-form>
                </div>
            </div>
        </section>

        <!-- ANCHOR: 다이얼로그-입력확인 -->
        <template>
            <v-dialog
                v-model="isIssuingCheckDialogVisible"
                width="500"
            >
                <v-card class="pa-0 rounded-lg">
                    <div class="le-heading6 pa-5">
                        입력 확인
                    </div>

                    <div
                        class="le-text-body2 pl-5 pr-6"
                        style="min-height: 88px;"
                    >
                        <p class="mb-3">
                            입력하신 내용은 다음과 같습니다.
                        </p>

                        <strong>{{ name }}</strong><br>
                        <strong>{{ email }}</strong><br>
                        
                        <p class="mt-3">
                            다음과 같이 발행 하시겠습니까?
                        </p>
                    </div>

                    <div class="pa-5 d-flex justify-end gap-3">
                        <v-btn
                            v-ripple="{ class: 'text-blue3' }"
                            color="blue1"
                            outlined
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleIssuingCheckDialogClose"
                        >
                            아니오
                        </v-btn>

                        <v-btn
                            color="orange1"
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleSubmit"
                        >
                            네
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </template>

        <!-- ANCHOR: 다이얼로그-발행완료 -->
        <template>
            <v-dialog
                v-model="isIssuingSuccessDialogVisible"
                width="500"
            >
                <v-card class="pa-0 rounded-lg">
                    <div class="le-heading6 pa-5">
                        발행 완료
                    </div>

                    <div
                        class="le-text-body2 pl-5 pr-6"
                        style="min-height: 88px;"
                    >
                        오픈배지 발행이 완료되었습니다.<br />
                        입력하신 메일 주소로 수여 알림이 발송됩니다.<br />
                        메일 본문에 있는 수령 절차 시작 버튼을 클릭해서 수령해주세요.<br />
                        <span class="text-red1">*메일 발송까지는 최대 한시간이 소요됩니다.</span>
                    </div>

                    <div class="pa-5 d-flex justify-end gap-3">
                        <v-btn
                            color="orange1"
                            :small="$vuetify.breakpoint.smAndDown"
                            depressed
                            class="text-white1"
                            @click="handleIssuingSuccessDialogClose"
                        >
                            확인
                        </v-btn>
                    </div>
                </v-card>
            </v-dialog>
        </template>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapActions } from 'vuex';
import { getSampleBadgesMetaInfo } from '@/utils/metaInfo.js';

export default {
    name: 'SampleBadgesPage',
    components: {
        //
    },

    metaInfo() {
        return getSampleBadgesMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            isIssuingCheckDialogVisible: false,
            isIssuingSuccessDialogVisible: false,
            lang: 'ko-KR',
            name: '',
            email: '',
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        ...mapActions(
            'senbadges', [ 'issueBadge' ]
        ),

        /* ============================================================
         * ANCHOR: 발행하기 버튼 클릭
         * ============================================================ */
        showIssuingCheckDialogVisible() { 
            // 유효성 검사
            if (!this.$refs.form.validate()) {
                console.log('validate!!');
                return;
            }
            this.isIssuingCheckDialogVisible = true;
        },

        handleIssuingCheckDialogClose() {
            this.isIssuingCheckDialogVisible = false;
        },

        /* ============================================================
         * ANCHOR: 네(발행하기) 버튼 클릭
         * ============================================================ */
        async handleSubmit() {
            // this.handleIssuingCheckDialogClose();
            // this.isIssuingSuccessDialogVisible = true;

            try {
                // 유효성 검사
                // if (!this.$refs.form.validate()) {
                //     console.log('validate!!');
                //     return;
                // }

                const { lang, name, email } = this;

                this.issueBadge({ name, email, lang })
                    .then(res => {
                        console.log('res', res);

                        if (res.status === 200) {
                            // this.$router.push({ name: 'ContactUsFinishPage' });
                            this.handleIssuingCheckDialogClose();
                            this.isIssuingSuccessDialogVisible = true;
                            this.$nextTick(() => {
                                this.name = undefined;
                                this.email = undefined;
                            });
                        }
                    }).catch((error) => {
                        if (error.response) {
                            // 요청이 이루어졌으며 서버가 2xx의 범위를 벗어나는 상태 코드로 응답했습니다.
                            console.log(error.response.data);
                            console.log(error.response.status);
                            console.log(error.response.headers);
                                    
                            // this.errMsg = error.response.request;
                            // this.activeModalErr = true;
                            // console.log('errMsg', this.errMsg);
                        }
                        else if (error.request) {
                            // 요청이 이루어 졌으나 응답을 받지 못했습니다.
                            // `error.request`는 브라우저의 XMLHttpRequest 인스턴스 또는
                            // Node.js의 http.ClientRequest 인스턴스입니다.
                            console.log(error.request);

                            // this.errMsg = error.request;
                            // this.activeModalErr = true;
                        }
                        else {
                            // 오류를 발생시킨 요청을 설정하는 중에 문제가 발생했습니다.
                            console.log('Error', error.message);

                            // this.errMsg = error.message;
                            // this.activeModalErr = true;
                        }
                        console.log(error.config);
                    }).finally(() => { 
                        console.log('end');
                        // this.$refs['btnIssuing'].disabled = false;
                    });
            } catch (error) {
                this.handleError(error);
            } finally {
                console.log('Request completed');
                // 필요에 따라 버튼 비활성화 복원 등
                // this.$refs['btnInquery'].disabled = false;
            }
        },
        handleError(error) {
            // 에러 핸들링 함수
            if (error.response) {
                // 요청이 이루어졌으나 에러 응답을 받은 경우
                console.error('Error Response Data:', error.response.data);
                console.error('Error Response Status:', error.response.status);
                console.error('Error Response Headers:', error.response.headers);
                alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해 주세요.');
            } else if (error.request) {
                // 요청이 이루어졌으나 응답이 없는 경우
                console.error('Error Request:', error.request);
                alert('서버와의 연결에 실패했습니다. 인터넷 연결을 확인해 주세요.');
            } else {
                // 요청 설정 중 오류가 발생한 경우
                console.error('Error Message:', error.message);
                alert('오류가 발생했습니다. 다시 시도해 주세요.');
            }
            console.error('Error Config:', error.config);
        },
        /* ============================================================
         * ANCHOR: 확인 버튼 클릭 (발행 완료)
         * ============================================================ */
        handleIssuingSuccessDialogClose() {
            this.isIssuingSuccessDialogVisible = false;
            this.resetForm();
        },
        resetForm() {
            this.name = '';
            this.email = '';
            this.$refs.form.resetValidation();
        },
    },
    // !SECTION: Methods
};
</script>
