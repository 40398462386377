var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer d-flex justify-center",class:{
        'pt-6 pb-6': _vm.$vuetify.breakpoint.smAndUp,
        'pt-3 pb-3': _vm.$vuetify.breakpoint.xsOnly
    }},[_c('div',{staticClass:"c-contanier"},[_c('div',{staticClass:"d-sm-flex justify-sm-space-between"},[_c('div',[_c('div',{staticClass:"text-gray1 le-text-body3 d-flex align-center",class:{
                        'mb-6': _vm.$vuetify.breakpoint.mdAndUp,
                        'mb-3': _vm.$vuetify.breakpoint.smOnly,
                        'mb-2': _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('div',{staticClass:"d-flex gap-3"},[_c('v-btn',{staticClass:"le-circle-button",attrs:{"width":"32","height":"32","ripple":false,"plain":"","color":"white1","href":"https://www.instagram.com/openbadgelecos/","target":"_blank"}},[_c('div',{staticClass:"bg-blue3 rounded-pill"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v(" fontagon-icons ft-icon ft-ic_instagram ")])],1)]),_c('v-btn',{staticClass:"le-circle-button",attrs:{"width":"32","height":"32","ripple":false,"plain":"","color":"white1","href":"https://www.youtube.com/@lecosinc.5171","target":"_blank"}},[_c('div',{staticClass:"bg-blue3 rounded-pill"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v(" fontagon-icons ft-icon ft-ic_youtube ")])],1)]),_c('v-btn',{staticClass:"le-circle-button",attrs:{"width":"32","height":"32","ripple":false,"plain":"","color":"white1","href":"https://blog.naver.com/lecoskorea","target":"_blank"}},[_c('div',{staticClass:"bg-blue3 rounded-pill"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v(" fontagon-icons ft-icon ft-ic_blog ")])],1)]),_c('v-btn',{staticClass:"le-circle-button",attrs:{"width":"32","height":"32","ripple":false,"plain":"","color":"white1","href":"https://x.com/LecoSkorea","target":"_blank"}},[_c('div',{staticClass:"bg-blue3 rounded-pill"},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v(" fontagon-icons ft-icon ft-ic_twitter ")])],1)])],1)]),_c('div',{staticClass:"text-gray1 le-text-body3 d-flex flex-wrap gap-x-5",class:{
                        'gap-y-2 mb-2': _vm.$vuetify.breakpoint.smAndUp,
                        'gap-y-1 mb-1': _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('span',[_vm._v("㈜레코스 LecoS,Inc.")])]),_c('div',{staticClass:"text-gray1 le-text-body3 d-flex flex-wrap gap-x-5",class:{
                        'gap-y-2 mb-2': _vm.$vuetify.breakpoint.smAndUp,
                        'gap-y-1 mb-1': _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('span',[_vm._v("사업자등록번호 : 396-87-02365")]),_c('span',[_vm._v("대표이사 : 노원석")]),_c('span',[_vm._v("주소 : 서울특별시 마포구 마포대로 86 창강빌딩 4층 421호")])]),_c('div',{staticClass:"text-gray1 le-text-body3 d-flex flex-wrap gap-x-5",class:{
                        'gap-y-2 mb-2': _vm.$vuetify.breakpoint.smAndUp,
                        'gap-y-1 mb-1': _vm.$vuetify.breakpoint.xsOnly,
                    }},[_vm._m(0),_c('span',[_vm._v("대표메일 : info@lecos.co.kr")])]),_c('div',{staticClass:"text-gray1 le-text-caption1"},[_vm._v(" ⓒ 2024 LecoS, Inc. All rights reserved. ")])]),_c('div',{staticClass:"d-md-flex flex-grow-1",class:{
                    'justify-space-between': _vm.$vuetify.breakpoint.mdAndUp,
                    'ml-15': _vm.$vuetify.breakpoint.smAndUp
                },style:({ 'max-width': _vm.$vuetify.breakpoint.smAndUp ? '385px' : '' })},[_c('div',{staticClass:"d-flex flex-sm-column flex-wrap gap-x-6",class:{
                        'gap-y-2': _vm.$vuetify.breakpoint.smAndUp,
                        'mt-2 gap-y-1': _vm.$vuetify.breakpoint.xsOnly,
                    }},[_c('RouterLink',{staticClass:"text-blue3 d-flex align-center l-text-button l-text-button--medium",attrs:{"to":{ name: 'TermsOfServicePage' }}},[_c('span',{staticClass:"text"},[_vm._v("이용약관")])]),_c('RouterLink',{staticClass:"text-blue3 d-flex align-center l-text-button l-text-button--medium",attrs:{"to":{ name: 'PrivacyPage' }}},[_c('span',{staticClass:"text"},[_vm._v("개인정보취급방침")])]),_vm._m(1),_vm._m(2)],1),_c('div',{staticClass:"d-flex",class:{
                        'gap-x-5 ml-15': _vm.$vuetify.breakpoint.mdAndUp,
                        'gap-x-3': _vm.$vuetify.breakpoint.smAndDown,
                        'mt-7': _vm.$vuetify.breakpoint.smOnly,
                        'mt-2': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/footer-logo-1EdTech.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 50 : (_vm.$vuetify.breakpoint.smOnly ? 50 : 37),"alt":""}})],1),_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/footer-logo-TrustEd.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 54 : (_vm.$vuetify.breakpoint.smOnly ? 54 : 40),"alt":""}})],1)])])])]),_c('div',{staticClass:"page-top",style:({ opacity: _vm.isVisible ? 1 : 0 })},[_c('v-btn',{attrs:{"fab":"","depressed":"","ripple":false,"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"height":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"color":"blue3 rounded-pill text-white1 d-flex align-center justify-center"},on:{"click":_vm.scrollToTop}},[_c('v-icon',{staticClass:"ma-0",attrs:{"size":"24"}},[_vm._v(" fontagon-icons ft-icon ft-ic_arrow_up_fill ")])],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_vm._v("대표번호 : 02-716-5571 "),_c('span',{staticClass:"le-text-caption1"},[_vm._v("(평일 9:00~18:00, 주말/공휴일 제외)")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-blue3 d-flex align-center l-text-button l-text-button--medium",attrs:{"href":"https://lecos.notion.site/fa480e1608b947ecbdfeb74428e6184f","target":"_blank","rel":"noopener noreferrer"}},[_c('span',{staticClass:"text"},[_vm._v("이용가이드")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"text-blue3 d-flex align-center l-text-button l-text-button--medium",attrs:{"href":"https://site.imsglobal.org/certifications/lecos-inc/lecosopenbadge#cert_pane_nid_473678","target":"_blank"}},[_c('span',{staticClass:"text"},[_vm._v("1EdTech 인증서")])])
}]

export { render, staticRenderFns }