<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="공지사항"
            sub-title="레코스의 새로운 소식과 이벤트"
            bg-color=""
        />

        <!-- ANCHOR: 상세 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row class="d-flex justify-center">
                    <v-col
                        cols="12"
                        lg="8"
                        md="10"
                        sm="12"
                        class="notice-view"
                    >
                        <!-- 게시물 정보 -->
                        <div>
                            <p class="le-heading6">
                                {{ currentNoticeItem.title }}
                            </p>

                            <p class="le-text-body2">
                                {{ currentNoticeItem.date }}
                            </p>
                        </div>

                        <!-- 게시물 콘텐츠 -->
                        <div class="notice-view__content my-15">
                            <!-- id: 14 -->
                            <div v-if="currentNoticeItem.id == '14'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative;">
                                            <div style="display: inline-block; position: relative;">
                                                <img
                                                    :src="require('@/assets/img/notice-thumb/event-view-014.png')"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 13 -->
                            <div v-if="currentNoticeItem.id == '13'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative;">
                                            <div style="display: inline-block; position: relative;">
                                                <img
                                                    :src="require('@/assets/img/notice-thumb/event-view-013.png')"
                                                />

                                                <a
                                                    href="https://lecos.notion.site/8755f872723b4027a1e678a1de3961a5"
                                                    target="_blank"
                                                    style="text-indent: -10000px; width: 41%; height: 3.5%; border-radius: 10px; opacity: 0.5; margin-left: -21.8%; display: flex; position: absolute; left: 51.3%; bottom: 6%"
                                                >수령인 가이드 바로가기</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 12 -->
                            <div v-if="currentNoticeItem.id == '12'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative;">
                                            <div style="display: inline-block; position: relative;">
                                                <img
                                                    :src="require('@/assets/img/notice-thumb/event-view-012.png')"
                                                />

                                                <a
                                                    href="/download/ob-user-guide.pdf?v=231116"
                                                    target="_blank"
                                                    style="text-indent: -10000px; width: 59.8%; height: 5.2%; border-radius: 10px; opacity: 0.5; margin-left: -29.8%; display: flex; position: absolute; left: 51%; bottom: 10.3%"
                                                >이용가이드 바로가기</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 11 -->
                            <div v-if="currentNoticeItem.id == '11'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative;">
                                            <div style="display: inline-block; position: relative;">
                                                <img
                                                    :src="require('@/assets/img/notice-thumb/event-view-011.png')"
                                                />

                                                <a
                                                    href="https://www.youtube.com/watch?v=Ejmx3B6FA-w"
                                                    target="_blank"
                                                    style="text-indent: -10000px; width: 59.5%; height: 8.2%; border-radius: 10px; opacity: 0.5; margin-left: -29.8%; display: flex; position: absolute; left: 50%; bottom: 32.5%"
                                                >발행자 가이드 동영상 바로가기</a>

                                                <a
                                                    href="https://www.youtube.com/watch?v=EGd7tiUiyFY"
                                                    target="_blank"
                                                    style="text-indent: -10000px; width: 59.5%; height: 8.2%; border-radius: 10px; opacity: 0.5; margin-left: -29.8%; display: flex; position: absolute; left: 50%; bottom: 20.5%"
                                                >사용자 가이드 동영상 바로가기</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 10 -->
                            <div v-if="currentNoticeItem.id == '10'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative;">
                                            <img
                                                :src="require('@/assets/img/notice-thumb/event-view-010.png')"
                                            />

                                            <a
                                                href="/download/ob-user-guide.pdf"
                                                target="_blank"
                                                style="text-indent: -10000px; width: 60%; height: 3.9%; border-radius: 10px; opacity: 0.5; margin-left: -30%; display: flex; position: absolute; left: 50%; bottom: 8%"
                                            >이용 가이드 바로가기</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 9 -->
                            <div v-if="currentNoticeItem.id == '9'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative; width: fit-content; margin: 0 auto;">
                                            <img
                                                :src="require('@/assets/img/notice-thumb/event-view-009.png')"
                                            />

                                            <a
                                                href="/download/ob-user-guide.pdf"
                                                target="_blank"
                                                style="text-indent: -10000px; width: 60%; height: 9.5%; border-radius: 10px; opacity: 0.5; margin-left: -30%; display: flex; position: absolute; left: 50%; bottom: 16%"
                                            >                                       
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 8 -->
                            <div v-if="currentNoticeItem.id == '8'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative; width: fit-content; margin: 0 auto;">
                                            <a
                                                href="https://blog.naver.com/lecoskorea"
                                                target="_blank"
                                            ><img :src="require('@/assets/img/notice-thumb/event-view-008.png')" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 7 -->
                            <div v-if="currentNoticeItem.id == '7'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative; width: fit-content; margin: 0 auto;">
                                            <img :src="require('@/assets/img/notice-thumb/event-view-007.png')" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 6 -->
                            <div v-if="currentNoticeItem.id == '6'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <div style="position: relative; width: fit-content; margin: 0 auto;">
                                            <img :src="require('@/assets/img/notice-thumb/event-view-006.png')" />
                                            <a
                                                href="https://youtu.be/gVbTdXarGlA"
                                                target="_blank"
                                                style="position: absolute; left: 20%; top: 48%; width: 60%; height: 14.5%; opacity: 0.5; border-radius: 10px; text-indent: -10000px;"
                                            >오픈배지 원년, 어디까지 왔나? - (주)레코스 노원석 대표</a>
                                            <a
                                                href="https://youtu.be/CZ81jfl52Q0"
                                                target="_blank"
                                                style="position: absolute; left: 20%; top: 66.5%; width: 60%; height: 14.5%; opacity: 0.5; border-radius: 10px; text-indent: -10000px;"
                                            >인재육성과 활용의 게임체인저, 오픈배지 - 성균관대학교 학생성공센터 박진희 박사 / (주) 휴넷 에듀테크연구소 홍정민 소장</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 5 -->
                            <div v-if="currentNoticeItem.id == '5'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <a
                                            href="https://lecos.co.kr/educationkorea2023"
                                            target="blank"
                                            title="새창"
                                        >
                                            <img :src="require('@/assets/img/notice-thumb/event-view-005.jpg')" />
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 4 -->
                            <div v-if="currentNoticeItem.id == '4'">
                                <div style="text-align: center;">
                                    <div class="b-box">
                                        <img :src="require('@/assets/img/notice-thumb/event-view-004.jpg')" />
                                    </div>
                                </div>
                            </div>

                            <!-- id: 3 -->
                            <div v-if="currentNoticeItem.id == '3'">
                                <div
                                    style="text-align: center;"
                                    class="openbadge-search-fun"
                                >
                                    <div class="b-box">
                                        <img :src="require('@/assets/img/notice-thumb/event-view-003.jpg')" />

                                        <a
                                            href="https://www.lecos.co.kr/kor/terms_of_service"
                                            target="_blank"
                                            class="link-go link-terms"
                                        >이용규약 바로가기</a>
                                        <a
                                            href="https://www.openbadge-global.com/ns/portal/openbadge/login"
                                            target="_blank"
                                            class="link-go link-wallet"
                                        >오픈배지 지갑 바로가기</a>
                                    </div>
                                </div>
                            </div>

                            <!-- id: 2 -->
                            <div v-if="currentNoticeItem.id == '2'">
                                <div style="text-align: center;">
                                    <a
                                        href="https://www.nlhdkorea.com/"
                                        target="_blnak"
                                    ><img :src="require('@/assets/img/notice-thumb/event-view-002.jpg')" /></a>
                                </div>
                            </div>

                            <!-- id: 1 -->
                            <div
                                v-if="currentNoticeItem.id == '1'"
                                style="line-height: 3.8rem; font-size: 2.0rem; color: #000;"
                            >
                                안녕하세요.<br />

                                당사의 서비스를 이용해주시는 여러분께 진심으로 감사드리며,<br />

                                주식회사 레코스는 오픈배지 서비스 향상을 위해 <span style="font-size: 2.0rem; font-weight: bold;">7월 7일부터

                                    오픈배지 지갑 로그인 URL의 도메인 변경을 실시합니다.</span><br /><br />

                                *URL 변경<br />

                                변경일 : 2022년 7월 7일(목)<br />

                                변경전 : http://nlp.netlearning.co.jp/ns/portal/openbadge/#/login<br />

                                변경후 : http://www.openbadge-global.com/ns/portal/openbadge/login<br />

                                <span style="color: orange">※변경전 URL로 접속하셔도 자동적으로 새로운 URL로 전송됩니다.</span><br /><br />

                                즐겨찾기, 북마크 등에 등록하신 분께서는 새로운 주소로 재등록해주시길 바랍니다.<br /><br />


                                이용에 참고 부탁드리며, 문의사항은 언제든지 연락주세요.<br />

                                감사합니다.
                            </div>
                        </div>

                        <!-- 목록 버튼 -->
                        <div class="d-flex justify-center">
                            <v-btn
                                :to="{ name: 'NoticePage' }"
                                color="orange1"
                                depressed
                                :small="$vuetify.breakpoint.smAndDown"
                                :large="$vuetify.breakpoint.mdAndUp"
                                class="text-white1"
                            >
                                목록으로
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapGetters } from 'vuex';
import { getNoticeMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'NoticePage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getNoticeMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        ...mapGetters('notice', ['getNoticeItem']),

        currentNoticeItem() {
            const id = this.$route.query.id;
            return id ? this.getNoticeItem(id) : null;
        }
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        //
    },
    // !SECTION: Methods
};
</script>
