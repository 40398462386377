<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="Learning eco-System"
            sub-title="오픈배지의 혁신,<br> 레코스가 앞장 섭니다."
            bg-color="blue9"
        />

        <!-- ANCHOR: 회사소개 -->
        <section class="c-section bg-blue9 d-flex justify-center">
            <div class="c-contanier">
                <v-row
                    class="justify-end align-center"
                >
                    <v-col
                        cols="12"
                        lg="6"
                        md="5"
                        class="d-flex"
                        :class="{
                            'justify-end': $vuetify.breakpoint.lgAndUp,
                            'justify-center': $vuetify.breakpoint.mdAndDown,
                        }"
                    >
                        <v-img
                            :src="require('@/assets/img/about-company-1.png')"
                            class="v-img-w-full"
                            max-width="519"
                            alt=""
                        ></v-img>
                    </v-col>

                    <v-col
                        v-if="$vuetify.breakpoint.mdAndUp"
                        cols="12"
                        md="1"
                    >
                    </v-col>

                    <v-col
                        cols="12"
                        lg="5"
                        md="6"
                        :class="{
                            'mt-15': $vuetify.breakpoint.smAndDown,
                        }"
                    >
                        <p class="le-text-body1">
                            (주)레코스는 2021년 8월, 국내 최초로 설립된 국제표준 디지털배지(오픈배지) 서비스 전문 기업으로 한국 및 아시아의 오픈배지 서비스를 주도하고 있습니다.
                        </p>
                        <br>
                        <p class="le-text-body1">
                            아시아 최초 1EdTech의 국제표준 오픈배지 2.0과 3.0 인증을 취득하였고 국내 및 아시아 최다 발행인과 수령인을 보유하고 있습니다.
                        </p>
                        <br>
                        <p class="le-text-body1">
                            학습의 에코 시스템을 제공하는 기관과 학습자의 만남의 장.
                        </p>
                        <br>
                        <p class="le-text-body1">
                            자신의 스킬을 발휘하고 싶은 분과 풍부한 지식을 가진 인재를 요구하는 분들의 만남의 장.
                        </p>
                        <br>
                        <p class="le-heading6">
                            오픈배지가 필요한 모든 순간에 레코스가 함께합니다.
                        </p>
                    </v-col>
                </v-row>
            </div>
        </section>

        <!-- ANCHOR: 찾아오시는 길 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <h2 class="text-center le-heading3 c-title">
                    레코스 찾아오시는 길
                </h2>

                <v-row class="justify-center">
                    <v-col
                        cols="12"
                        lg="8"
                        md="10"
                    >
                        <div
                            id="map"
                            style="width: 100%; height: 500px;"
                        ></div>

                        <div
                            class="d-flex flex-column"
                            :class="{
                                'gap-y-10': $vuetify.breakpoint.mdAndUp,
                                'gap-y-9': $vuetify.breakpoint.smOnly,
                                'mt-13': $vuetify.breakpoint.smAndUp,
                                'mt-10 gap-y-6': $vuetify.breakpoint.xsOnly,
                            }"
                        >
                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-1.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        서울시 마포구 마포대로 86, 4층 421호 (도화동, 창강빌딩)
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-2.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        02-716-5571
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-3.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        버스 이용시
                                    </p>

                                    <p class="mt-3 le-text-body2">
                                        160, 260, 261, 463, 600, 173, 740, 604, 7013, 1711, 7613, 7611 - 공덕역 하차
                                    </p>
                                </div>
                            </div>

                            <div class="d-flex">
                                <div class="mr-5">
                                    <v-img
                                        :src="require('@/assets/img/about-map-4.png')"
                                        :width="$vuetify.breakpoint.mdAndUp ? 48 : ($vuetify.breakpoint.smOnly ? 48 : 40)"
                                        alt=""
                                    ></v-img>
                                </div>

                                <div class="pt-2">
                                    <p class="le-heading6">
                                        지하철 이용시
                                    </p>

                                    <ul class="mt-3 le-text-body2 list-type-1">
                                        <li>5호선, 6호선 - 공덕역 8번 출구</li>
                                        <li>공항철도, 경의선 - 공덕역 9번 출구</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getAboutMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'AboutPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getAboutMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            map: null,
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    created() {
        //
    },

    mounted() {
        // 클라이언트 사이드에서만 실행
        if (process.browser) {
            this.initKakaoMap();
        }
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        // api 불러오기
        initKakaoMap() {
            // 카카오 지도 API 스크립트 로드
            const script = document.createElement('script');
            script.src = '//dapi.kakao.com/v2/maps/sdk.js?appkey=15c0b3f3a98e2438cf24949430861698&autoload=false';
            script.onload = () => {
                window.kakao.maps.load(this.initMap);
            };
            document.head.appendChild(script);
        },

        initMap() {
            const container = document.getElementById('map');
            const options = {
                center: new window.kakao.maps.LatLng(37.54268932651642, 126.95058561871812),
                level: 3
            };
            this.map = new window.kakao.maps.Map(container, options);
            this.addMarker();
        },

        addMarker() {
            const markerPosition = new window.kakao.maps.LatLng(37.54268932651642, 126.95058561871812);
            const marker = new window.kakao.maps.Marker({
                position: markerPosition,
            });
            marker.setMap(this.map);
        }
    },
    // !SECTION: Methods
};
</script>
