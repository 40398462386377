export default {
    // 공지사항
    notice: [
        {
            id: '14',
            type: '1',
            title: '레코스 창립 3주년 기념 홈페이지 새단장 안내',
            date: '2024-09-02',
            content: '',
        },
        {
            id: '13',
            type: '1',
            title: '오픈배지 지갑 디자인 리뉴얼 및 신규 기능 추가',
            date: '2024-06-13',
            content: '',
        },
        {
            id: '12',
            type: '1',
            title: ' 오픈배지 지갑 내 이미지 및 QR코드 다운로드 버튼 위치 변경 안내',
            date: '2023-11-16',
            content: '',
        },
        {
            id: '11',
            type: '1',
            title: '레코스 오픈배지 발행자 및 사용자 동영상 가이드 안내',
            date: '2023-09-18',
            content: '',
        },
        {
            id: '10',
            type: '1',
            title: '레코스 오픈배지 인증서 보안강화를 위한 인쇄방법 변경안내',
            date: ' 2023-08-24',
            content: '',
        },
        {
            id: '9',
            type: '1',
            title: '오픈 배지 수여 알림 메일 내 수령 절차 기한 변경 안내',
            date: ' 2023-08-03',
            content: '',
        },
        {
            id: '8',
            type: '1',
            title: '레코스 오픈배지 공식 블로그 안내',
            date: '2023-06-13',
            content: '',
        },
        {
            id: '7',
            type: '1',
            title: '레코스 오픈배지 수령 방법 및 일부 기능 개선 안내',
            date: '2023-04-06',
            content: '',
        },
        {
            id: '6',
            type: '2',
            title: '제20회 교육박람회 오픈배지 세미나 녹화본',
            date: '2023-01-16',
            content: '',
        },
        {
            id: '5',
            type: '2',
            title: '오픈배지 세미나안내',
            date: '2022-12-13',
            content: '',
        },
        {
            id: '4',
            type: '1',
            title: '레코스 1주년 감사인사',
            date: '2022-08-16',
            content: '',
        },
        {
            id: '3',
            type: '1',
            title: '오픈배지 검색기능을 오픈했습니다.',
            date: '2022-07-22',
            content: '',
        },
        {
            id: '2',
            type: '1',
            title: '네트러닝 한국지사 홈페이지 오픈',
            date: '2022-07-06',
            content: '',
        },
        {
            id: '1',
            type: '1',
            title: '오픈배지 지갑 로그인 URL변경 안내',
            date: '2022-06-28',
            content: '',
        },
    ],

    // 언론보도
    press: [
        {
            id: '92',
            mediaCompany: 'KOREA 뉴스',
            title: '시립성북청소년센터, 제2회 아시아퍼시픽 오픈배지 어워드 ‘금상’ 수상',
            link: 'https://news.korea.com/newsView/2024111167318aa6421b6',
            date: '2024-11-11',
            display: true,
        },
        {
            id: '91',
            mediaCompany: 'UNN',
            title: '“디지털 전환 시대, 고등교육이 나아가야 할 방향은”…에듀테크학회, 2024 추계 학술대회 개최',
            link: 'https://news.unn.net/news/articleView.html?idxno=571126',
            date: '2024-11-15',
            display: true,
        },
        {
            id: '90',
            mediaCompany: 'Daum 뉴시스',
            title: '"AI시대에 필요한 인재 양성"…휴넷, \'미래역량스쿨\' 출시',
            link: 'https://v.daum.net/v/20241114114247069',
            date: '2024-11-14',
            display: true,
        },
        {
            id: '89',
            mediaCompany: 'UNN',
            title: '단국대, K-MOOC 참여대학 최초로 MOOC에 오픈배지 적용”',
            link: 'https://news.unn.net/news/articleView.html?idxno=571003&fbclid=IwZXh0bgNhZW0CMTEAAR1_uVRULceekK5OFtogIg9ODsFGB0Zg9b1P_TMS3B1GXjvWHgw_QVkcK7s_aem_9PD_8dinLIdumkQy8EssdQ',
            date: '2024-11-13',
            display: true,
        },
        {
            id: '88',
            mediaCompany: 'UNN',
            title: '한국 에듀테크 기업, 글로벌 어워드서 대거 수상…“국내 넘어 세계 시장 진출 박차”',
            link: 'https://news.unn.net/news/articleView.html?idxno=570665',
            date: '2024-11-06',
            display: true,
        },
        {
            id: '87',
            mediaCompany: '전자신문',
            title: '[에듀플러스]경희대, 창업교육 우수대학 선정',
            link: 'https://www.etnews.com/20241030000421',
            date: '2024-10-30',
            display: true,
        },
        {
            id: '86',
            mediaCompany: 'Youtube',
            title: '어린이과학동아 Smart+ (30초.VER)',
            link: 'https://www.youtube.com/watch?v=7iCLVgL3yVI',
            date: '2024-10-02',
            display: true,
        },
        {
            id: '85',
            mediaCompany: '성대신문',
            title: '디지털 대전환 시대, 디지털 배지의 성공적인 정착을 위해서',
            link: 'https://www.skkuw.com/news/articleView.html?idxno=31447',
            date: '2024-10-14',
            display: true,
        },
        {
            id: '84',
            mediaCompany: '동아사이언스',
            title: '과학잡지·AI 결합 \'어린이과학동아 Smart+\' 출시',
            link: 'https://www.dongascience.com/news.php?idx=67771',
            date: '2024-10-07',
            display: true,
        },
        {
            id: '83',
            mediaCompany: 'Daum 머니투데이',
            title: '휴넷, \'L&D 마스터 스쿨\' 출시...기업교육 전문가 양성',
            link: 'https://v.daum.net/v/20240912164102374',
            date: '2024-09-12',
            display: true,
        },
        {
            id: '82',
            mediaCompany: 'UNN',
            title: '오픈배지, 디지털 대전환 ‘열쇠’ 되려면…“사용자 인식 높아지고 정부·기업 협력 필요”',
            link: 'https://news.unn.net/news/articleView.html?idxno=568144',
            date: '2024-09-09',
            display: true,
        },
        {
            id: '81',
            mediaCompany: 'UNN',
            title: '‘오픈배지 도입 3년차’ 국내 192개 대학서 사용, 사용자 수 2만 명 넘어…확산 속도 가속화',
            link: 'https://news.unn.net/news/articleView.html?idxno=567202',
            date: '2024-08-19',
            display: true,
        },
        {
            id: '80',
            mediaCompany: 'UNN',
            title: '㈜레코스, ㈜케이에스씨앤씨와 오픈배지 관련 협약 체결',
            link: 'https://news.unn.net/news/articleView.html?idxno=566907',
            date: '2024-08-07',
            display: true,
        },
        {
            id: '79',
            mediaCompany: '전자신문',
            title: ' [에듀플러스]\'교육 대전환\' 디지털 배지 도입 3년…성과와 보완점은',
            link: 'https://www.etnews.com/20240806000207',
            date: '2024-08-06',
            display: true,
        },
        {
            id: '78',
            mediaCompany: 'Daum 전자신문',
            title: ' 제약바이오협회, AI 신약개발 플랫폼 교육생 54명 선발 ',
            link: 'https://v.daum.net/v/20240801165904916',
            date: '2024-08-01',
            display: true,
        },
        {
            id: '77',
            mediaCompany: 'Daum 뉴시스',
            title: '서울시교육청, \'디지털 교사\' 육성한다…400개 연수과정 지원',
            link: 'https://v.daum.net/v/20240721090022605',
            date: '2024-07-21',
            display: true,
        },
        {
            id: '76',
            mediaCompany: '이뉴스투데이',
            title: 'AI 신약개발 융합인재 양성 ‘제2기 LAIDD 멘토링 프로젝트’ 가동',
            link: 'https://www.enewstoday.co.kr/news/articleView.html?idxno=2147878',
            date: '2024-07-03',
            display: true,
        },
        {
            id: '75',
            mediaCompany: 'UNN',
            title: '[2024 테마캠퍼스/한양여자대학교] 디지털 전환을 선도하는 지산학 융합형 교육혁신대학',
            link: 'https://news.unn.net/news/articleView.html?idxno=564634',
            date: '2024-06-19',
            display: true,
        },
        {
            id: '74',
            mediaCompany: 'UNN',
            title: '레코스·아이포트폴리오, 디지털 영어 독서 이력 인증 활성화 위한 업무협약 체결',
            link: 'https://news.unn.net/news/articleView.html?idxno=563415',
            date: '2024-05-20',
            display: true,
        },
        {
            id: '73',
            mediaCompany: '전자신문',
            title: '[에듀플러스][미리가본 제10회 SWTO]〈10·끝〉개최·후원 기관, 한소협 등 공동 개최…과기정통부·레코스 후원 등 참여기관 20개',
            link: 'https://www.etnews.com/20240511000012',
            date: '2024-05-12',
            display: true,
        },
        {
            id: '72',
            mediaCompany: '아이티비즈',
            title: '메디오피아테크 ‘훌라로’, LMS시스템 오픈배지 시스템 도입',
            link: 'https://www.it-b.co.kr/news/articleView.html?idxno=75158',
            date: '2024-04-30',
            display: true,
        },
        {
            id: '71',
            mediaCompany: 'UNN',
            title: '에듀테크학회, 한국통상정보학회와 공동학술대회 개최…해외 진출·AI의 교육적 활용 방안 모색',
            link: 'https://news.unn.net/news/articleView.html?idxno=562231',
            date: '2024-04-21',
            display: true,
        },
        {
            id: '70',
            mediaCompany: '전자신문',
            title: '[에듀플러스]장관상 수여하는 SW사고력올림피아드 6월 1일 개최, 접수 중…수상학생, 서울대 등 배출',
            link: 'https://www.etnews.com/20240412000273',
            date: '2024-04-14',
            display: true,
        },
        {
            id: '69',
            mediaCompany: 'UNN',
            title: '미래 학습 생태계 바꿀 게임체인저 ‘디지털배지’…표준화·신뢰도·보안은 ‘여전한 숙제’',
            link: 'https://news.unn.net/news/articleView.html?idxno=561221',
            date: '2024-03-28',
            display: true,
        },
        {
            id: '68',
            mediaCompany: 'UNN',
            title: '레코스, 러닝스파크와 ‘글로벌 진출’ 위해 업무제휴 체결',
            link: 'https://news.unn.net/news/articleView.html?idxno=560969',
            date: '2024-03-20',
            display: true,
        },
        {
            id: '67',
            mediaCompany: 'UNN',
            title: '배화여대, 디지털배지 발급으로 교육혁신 선도',
            link: 'https://news.unn.net/news/articleView.html?idxno=560390',
            date: '2024-03-06',
            display: true,
        },
        {
            id: '66',
            mediaCompany: '한국경제TV',
            title: '대림대학교, 한국공학교육인증원 공동 반도체관련 전공 졸업생 대상 디지털 배지 발급',
            link: 'https://www.wowtv.co.kr/NewsCenter/News/Read?articleId=A202402270139',
            date: '2024-02-27',
            display: true,
        },
        {
            id: '65',
            mediaCompany: 'UNN',
            title: '레코스·메디오피아테크, 인재 육성·글로벌 교육 시장 대응 위해 MOU 체결',
            link: 'https://news.unn.net/news/articleView.html?idxno=559824',
            date: '2024-02-21',
            display: true,
        },
        {
            id: '64',
            mediaCompany: 'DAUM 노컷뉴스',
            title: '동강대, 메타버스 졸업식…\'디지털 졸업장\' 수여',
            link: 'https://v.daum.net/v/20240218133605288',
            date: '2024-02-18',
            display: true,
        },
        {
            id: '63',
            mediaCompany: '전자신문',
            title: '[에듀플러스]오픈배지 도입, 일반 대학에 이어 사이버대로 확산 “학생들의 학습 역량 인증…낮은 기업 활용도는 숙제”',
            link: 'https://www.etnews.com/20240212000003',
            date: '2024-02-13',
            display: true,
        },
        {
            id: '62',
            mediaCompany: 'UNN',
            title: '디지털 학위증 수여한 삼육보건대',
            link: 'https://news.unn.net/news/articleView.html?idxno=559269',
            date: '2024-02-06',
            display: true,
        },
        {
            id: '61',
            mediaCompany: 'Daum 한겨레',
            title: '‘AI 선도대학 원년’ 선포…“모두를 위한 AI 교육”',
            link: 'https://v.daum.net/v/20240129174015798',
            date: '2024-01-29',
            display: true,
        },
        {
            id: '60',
            mediaCompany: 'UNN',
            title: '“IC-PBL 기반 지산학 협력 ‘고도화’…동반성장 추진 모델 구현”',
            link: 'https://news.unn.net/news/articleView.html?idxno=558436&fbclid=IwAR3gl_rp4NQ8bi4HpxhWs7TktuCVPY7jPpUeVCKDH58HcVAqW1Yvm-daRCE',
            date: '2024-01-19',
            display: true,
        },
        {
            id: '59',
            mediaCompany: 'Daum 전자신문',
            title: '\'오픈배지\' 적용했더니 자격증 취득률 \'5배\' 증가',
            link: 'https://v.daum.net/v/20231225154604566?fbclid=IwAR1b1-0ODqI28ti1L4OSXehQxMY4G1pBPvYHkhsUe_gLKommYgEi6JwyF0g',
            date: '2023-12-25',
            display: true,
        },
        {
            id: '58',
            mediaCompany: 'UNN',
            title: '“전문대 연합 구축한 ‘메타버시티 2.0’…교육부 ‘디지털혁신’ 사례로 ‘엄지척’”(종합)',
            link: 'https://news.unn.net/news/articleView.html?idxno=557215',
            date: '2023-12-22',
            display: true,
        },
        {
            id: '57',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] (終)좌담회 “‘오픈 배지’는 학습을 추동하는 좋은 툴…‘학습 이력·역량 검증’ 새로운 표준 제시 기대”',
            link: 'https://news.unn.net/news/articleView.html?idxno=556897',
            date: '2023-12-18',
            display: true,
        },
        {
            id: '56',
            mediaCompany: '동아일보',
            title: '“졸업생 해외 취업에 날개… 대학들 ‘공학교육인증’ 활성화해야”',
            link: 'https://www.donga.com/news/Society/article/all/20231207/122516403/1',
            date: '2023-12-07',
            display: true,
        },
        {
            id: '55',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ⑥디지털 배지 도입은 능력중심 사회로 가는 촉매제',
            link: 'https://news.unn.net/news/articleView.html?idxno=555734',
            date: '2023-11-25',
            display: true,
        },
        {
            id: '54',
            mediaCompany: 'Daum 전자신문',
            title: '한양대, 한양 마이스터 디그리 반도체 심화과정 개설',
            link: 'https://v.daum.net/v/20231124150303236',
            date: '2023-11-24',
            display: true,
        },
        {
            id: '53',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ⑤디지털 배지와 고등직업교육분야 활용',
            link: 'https://news.unn.net/news/articleView.html?idxno=555037',
            date: '2023-11-13',
            display: true,
        },
        {
            id: '52',
            mediaCompany: 'UNN',
            title: '학생 역량 인증하는 ‘디지털 배지’ 도입 시범학교 10개교, 학생들에게 첫 발급',
            link: 'https://news.unn.net/news/articleView.html?idxno=554811',
            date: '2023-11-05',
            display: true,
        },
        {
            id: '51',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ④오픈 배지, 기업 내 역량 검증의 새로운 표준',
            link: 'https://news.unn.net/news/articleView.html?idxno=554317',
            date: '2023-11-05',
            display: true,
        },
        {
            id: '50',
            mediaCompany: '전자신문',
            title: '한국 에듀테크 기업, 글로벌 에듀테크 어워드 휩쓸어',
            link: 'https://www.etnews.com/20231030000179',
            date: '2023-10-30',
            display: true,
        },
        {
            id: '49',
            mediaCompany: 'UNN',
            title: '싸이웍스, 日 ‘2023 AES GLOBAL AWARD’ 최고 대상 수상',
            link: 'https://news.unn.net/news/articleView.html?idxno=554505',
            date: '2023-10-30',
            display: true,
        },
        {
            id: '48',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ③오픈배지의 글로벌 생태계 구축',
            link: 'https://news.unn.net/news/articleView.html?idxno=553943',
            date: '2023-10-21',
            display: true,
        },
        {
            id: '47',
            mediaCompany: 'Daum 매일경제',
            title: '“기업교육 퍼스트 무버될 터”…휴넷, 글로벌 L&D 포럼 성료',
            link: 'https://v.daum.net/v/20231017171200534',
            date: '2023-10-17',
            display: true,
        },
        {
            id: '46',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ②데이터 혁명이 바꾸는 대학 교육의 미래',
            link: 'https://news.unn.net/news/articleView.html?idxno=553713',
            date: '2023-10-15',
            display: true,
        },
        {
            id: '45',
            mediaCompany: '한인포스트',
            title: '한국 에듀텍, 블록체인 기반 국제표준 오픈배지 발급 협력키로',
            link: 'https://www.lecos.co.kr/external/press/haninpost_231011.html',
            date: '2023-10-11',
            display: true,
        },
        {
            id: '44',
            mediaCompany: 'KOMPAS.com',
            title: 'LecoS 오픈배지 인도네시아 본격 진출',
            link: 'https://buku.kompas.com/read/4605/open-badge-inovasi-digital-berbasis-blockchain-hadir-di-indonesia?preview=1',
            date: '2023-10-03',
            display: true,
        },
        {
            id: '43',
            mediaCompany: 'UNN',
            title: '[연재기획 ‘디지털 대전환 기획 시리즈’] ①디지털 배지, 대학 혁신 그리고 학생 성공',
            link: 'https://news.unn.net/news/articleView.html?idxno=552960',
            date: '2023-09-25',
            display: true,
        },
        {
            id: '42',
            mediaCompany: '전자신문',
            title: '직업계고 10곳 오픈배지 발급…고교에서도 디지털 배지',
            link: 'https://www.etnews.com/20230920000114',
            date: '2023-09-20',
            display: true,
        },
        {
            id: '41',
            mediaCompany: '연합뉴스',
            title: '원광대 영어교육과, 블록체인 기반 디지털 배지 도입',
            link: 'https://www.yna.co.kr/view/AKR20230911035100055?input=1195m',
            date: '2023-09-11',
            display: true,
        },
        {
            id: '40',
            mediaCompany: '전자신문',
            title: '레코스, 아시아 최초로 \'오픈배지 3.0\' 인증 취득',
            link: 'https://www.etnews.com/20230905000208',
            date: '2023-09-05',
            display: true,
        },
        {
            id: '39',
            mediaCompany: 'UNN',
            title: '배화여대, 디지털 배지로 학생 핵심 역량 인증',
            link: 'https://news.unn.net/news/articleView.html?idxno=551924',
            date: '2023-09-04',
            display: true,
        },
        {
            id: '38',
            mediaCompany: '인천일보',
            title: '파주 세경고, 교육부 지정 디지털 배지 시범운영학교 선정',
            link: 'https://www.incheonilbo.com/news/articleView.html?idxno=1208254',
            date: '2023-08-23',
            display: true,
        },
        {
            id: '37',
            mediaCompany: '한인포스트',
            title: '한국 에듀텍, 블록체인 기반 국제표준 오픈배지 발급 협력키로',
            link: 'https://www.lecos.co.kr/external/press/haninpost.html',
            date: '2023-08-01',
            display: true,
        },
        {
            id: '36',
            mediaCompany: '머니투데이',
            title: '알파코, 디지털인재양성 프로그램에 오픈 배지 도입',
            link: 'https://news.mt.co.kr/mtview.php?no=2023070922004964721',
            date: '2023-07-10',
            display: true,
        },
        {
            id: '35',
            mediaCompany: '이뉴스투데이',
            title: '윤선생, ‘원어민 온라인 영어캠프’ 참가자 모집',
            link: 'http://www.enewstoday.co.kr/news/articleView.html?idxno=2013289',
            date: '2023-06-26',
            display: true,
        },
        {
            id: '34',
            mediaCompany: '한경닷컴',
            title: '초·중학생 대상 \'AICE 퓨처\' 시험 뜬다',
            link: 'https://www.hankyung.com/it/article/2023062525851',
            date: '2023-06-25',
            display: true,
        },
        {
            id: '33',
            mediaCompany: 'The ASIA N',
            title: '서울시교육청, 교원 AI·디지털 연수 맞춤형 지원 ‘디지털 배지’ 도입',
            link: 'http://kor.theasian.asia/archives/339662',
            date: '2023-06-21',
            display: true,
        },
        {
            id: '32',
            mediaCompany: 'News1 KOREA',
            title: 'AI 교원 연수 들으면 \'디지털 배지\' 발급…서울교육청 시범도입',
            link: 'https://www.news1.kr/articles/5083640',
            date: '2023-06-21',
            display: true,
        },
        {
            id: '31',
            mediaCompany: 'UNN',
            title: '포항공대·한국고등직업교육학회, ‘글로벌 메타버스’ 산학협력에 맞손',
            link: 'https://news.unn.net/news/articleView.html?idxno=548547',
            date: '2023-06-20',
            display: true,
        },
        {
            id: '30',
            mediaCompany: '대전경제',
            title: '‘IC-PBL 공유‧협력 컨소시엄’ 창립총회 공동개최',
            link: 'http://www.djenews.co.kr/news/articleView.html?idxno=121366',
            date: '2023-06-18',
            display: true,
        },
        {
            id: '29',
            mediaCompany: '디지털데일리',
            title: 'KT, AI 꿈나무 양성나서…상명대와 협력',
            link: 'https://www.ddaily.co.kr/page/view/2023060809304968211',
            date: '2023-06-08',
            display: true,
        },
        {
            id: '28',
            mediaCompany: '전자신문',
            title: '[에듀테크, 백년대계 시작하자]<하>평생교육시대, 민관 파트너십 필요',
            link: 'https://www.etnews.com/20230516000145',
            date: '2023-05-17',
            display: true,
        },
        {
            id: '27',
            mediaCompany: 'UNN',
            title: '오픈 배지 도입 대학 115개→150개…디지털 바람 타고 확산되는 ‘디지털 배지’, 향후 전망은?',
            link: 'https://news.unn.net/news/articleView.html?idxno=545050',
            date: '2023-04-15',
            display: true,
        },
        {
            id: '26',
            mediaCompany: 'UNN',
            title: '‘디지털 배지’ 활성화되려면 “신뢰성 기반 생태계 구성 및 관리·감독 전담 기관 필요”',
            link: 'https://news.unn.net/news/articleView.html?idxno=544844',
            date: '2023-04-10',
            display: true,
        },
        {
            id: '25',
            mediaCompany: '전자신문',
            title: '고교에서도 디지털 인증, \'오픈배지\' 첫 발행',
            link: 'https://www.etnews.com/20230410000209',
            date: '2023-04-10',
            display: true,
        },
        {
            id: '24',
            mediaCompany: '전자신문',
            title: '한국교육학술정보원, 디지털배지 활성화 방안 포럼 개최',
            link: 'https://www.etnews.com/20230407000017',
            date: '2023-04-07',
            display: true,
        },
        {
            id: '23',
            mediaCompany: '전자신문',
            title: '한양대, 학부 학점과정에 국내 대학 최초 \'오픈배지\' 도입',
            link: 'https://www.etnews.com/20230406000164?mc=em_004_00001',
            date: '2023-04-06',
            display: true,
        },
        {
            id: '22',
            mediaCompany: 'UNN',
            title: '한국고등직업교육학회, 메타와 손 잡고 ‘디지털 배지’ 교육 활성화 나서',
            link: 'https://news.unn.net/news/articleView.html?idxno=544434',
            date: '2023-04-04',
            display: true,
        },
        {
            id: '21',
            mediaCompany: '서울신문',
            title: '싸이웍스, 레코스와 ‘오픈배지 사업 협력 업무협약’ 체결',
            link: 'https://www.seoul.co.kr/news/newsView.php?id=20230314500141&wlog_tag3=daum',
            date: '2023-03-14',
            display: true,
        },
        {
            id: '20',
            mediaCompany: '동아일보',
            title: '동강대, 디지털 졸업장 ‘오픈배지’로 학위 수여',
            link: 'https://www.donga.com/news/Society/article/all/20230219/117976323/1',
            date: '2023-02-20',
            display: true,
        },
        {
            id: '19',
            mediaCompany: '전자신문',
            title: '한양여대·동강대 국내 첫 \'오픈배지 졸업장\' 발행',
            link: 'https://www.etnews.com/20230210000128',
            date: '2023-02-12',
            display: true,
        },
        {
            id: '18',
            mediaCompany: 'UNN',
            title: '[제3기 미래대학 콜로키엄] AI·디지털 테크놀로지의 힘, 대학을 어떻게 바꿔나갈까',
            link: 'https://news.unn.net/news/articleView.html?idxno=541537',
            date: '2023-02-05',
            display: true,
        },
        {
            id: '17',
            mediaCompany: 'UNN',
            title: '32개 전문대, ‘메가 트렌드’ ESG 아래 결집…“지속가능 넘어 생존가능 대비해야”',
            link: 'https://news.unn.net/news/articleView.html?idxno=540874',
            date: '2023-01-20',
            display: true,
        },
        {
            id: '16',
            mediaCompany: 'UNN',
            title: '메타버스에 올라탄 64개 전문대, 지난 2년 발자취…양적 성장 넘어 ‘질적 도약’ 이룬다',
            link: 'https://news.unn.net/news/articleView.html?idxno=539227',
            date: '2022-12-22',
            display: true,
        },
        {
            id: '15',
            mediaCompany: 'UNN',
            title: '대학 원격교육의 혁신모델 전파, ‘공유교육(Sharing Education)’에 달렸다',
            link: 'https://news.unn.net/news/articleView.html?idxno=536725',
            date: '2022-11-10',
            display: true,
        },
        {
            id: '14',
            mediaCompany: 'UNN',
            title: '[본지-한국고등직업교육학회 공동기획③] 학생 자치 활동을 메타버스 속에서 누린다',
            link: 'https://news.unn.net/news/articleView.html?idxno=535708&fbclid=IwAR1R-wBfBmR-py0_xIgST0Q8VdYKaJUGqSYWxYeFBFbT_xW78_zRAy6QJcU',
            date: '2022-10-23',
            display: true,
        },
        {
            id: '13',
            mediaCompany: 'UNN',
            title: '[본지-한국고등직업교육학회 공동기획②] 정규 교과목 강의를 메타버스로…수강신청, 학점교류 등 제도 정비 나서야',
            link: 'https://news.unn.net/news/articleView.html?idxno=535667&fbclid=IwAR3gNpEHvRukxZkbstlNaUtplunm-Tt62mzGA4eCsGOWVgZdodskrts_L3Y',
            date: '2022-10-23',
            display: true,
        },
        {
            id: '12',
            mediaCompany: 'UNN',
            title: '[본지-한국고등직업교육학회 공동기획①] ‘공유‧협력’ 위해 탄생한 우주…전문대학 63개 뭉친 메타버시티(Metaversity) 주목',
            link: 'https://news.unn.net/news/articleView.html?idxno=535653&fbclid=IwAR12Cav_h2yvUstGw0a5LqoSBzWvGAC0dONycAZx2-iOQn9aXm6F5sJ9IM8',
            date: '2022-10-23',
            display: true,
        },
        {
            id: '11',
            mediaCompany: '뉴스1코리아',
            title: '휴넷 HRD 칼리지, HRD 심화과정 \'HRD Master 과정\' 모집',
            link: 'https://www.news1.kr/articles/4830896',
            date: '2022-10-13',
            display: true,
        },
        {
            id: '10',
            mediaCompany: '신아일보',
            title: '윤선생, 일본 네트러닝홀딩스와 글로벌 교육시장 동반진출 맞손',
            link: 'http://www.shinailbo.co.kr/news/articleView.html?idxno=1602924',
            date: '2022-09-26',
            display: true,
        },
        {
            id: '9',
            mediaCompany: '성공회대학교',
            title: '일본기업 ㈜네트러닝홀딩스와 MOU 체결',
            link: 'https://www.skhu.ac.kr/bbs/skhu/39/51289/artclView.do?layout=unknown',
            date: '2022-09-22',
            display: true,
        },
        {
            id: '8',
            mediaCompany: 'UNN',
            title: '한국고등직업교육학회, 네트러닝홀딩스와 디지털배지 기술 도입 MOU 체결',
            link: 'https://news.unn.net/news/articleView.html?idxno=534197',
            date: '2022-09-20',
            display: true,
        },
        {
            id: '7',
            mediaCompany: '전자신문',
            title: '[스페셜리포트]디지털 배지, 인재양성 수단으로 자리매김',
            link: 'https://www.etnews.com/20220825000144',
            date: '2022-08-25',
            display: true,
        },
        {
            id: '6',
            mediaCompany: '전자신문',
            title: '[스페셜리포트]디지털 배지, 해외는 한발 앞서 도입',
            link: 'https://www.etnews.com/20220825000179',
            date: '2022-08-25',
            display: true,
        },
        {
            id: '5',
            mediaCompany: '전자신문',
            title: '[ET뷰]디지털 배지, 기업·대학 중심 확산일로',
            link: 'https://www.etnews.com/20220825000220',
            date: '2022-08-25',
            display: true,
        },
        {
            id: '4',
            mediaCompany: '아웃소싱타임스',
            title: '일본 네트러닝홀딩스 · 지식콘텐츠기업협회, \'오픈배지\'와 지식콘텐츠 사업 공동 추진을 위한 업무 협약 체결',
            link: 'http://www.outsourcing.co.kr/news/articleView.html?idxno=93888',
            date: '2022-05-02',
            display: true,
        },
        {
            id: '3',
            mediaCompany: '전자신문',
            title: '日, \'오픈배지\' 확산…디지털 자격 증명 시대',
            link: 'https://www.etnews.com/20220502000306',
            date: '2022-05-02',
            display: true,
        },
        {
            id: '2',
            mediaCompany: '전자신문',
            title: '한중일 에듀테크 기업 뭉쳐...아시아 에듀테크 써밋 9월 공식 발족 추진',
            link: 'https://www.etnews.com/20220502000256',
            date: '2022-05-02',
            display: true,
        },
        {
            id: '1',
            mediaCompany: '전자신문',
            title: '학력 대신에 학습 경력, \'디지털 자격 증명\' 부상',
            link: 'https://www.etnews.com/20220429000237',
            date: '2022-05-01',
            display: true,
        }
    ]
};
