var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":"수령부터 관리까지","sub-title":"쉽고 빠른 배지 수령 및 공유","bg-color":"blue12"}}),_c('section',{staticClass:"c-section bg-blue12 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('div',{staticClass:"d-flex flex-column step-wrap",class:{ 'gap-12': _vm.$vuetify.breakpoint.mdAndUp, 'gap-10': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 01 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 수여 알림 메일 확인 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 발행인으로부터 전송된 수여 알림 메일에서 배지 수령 시작 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 02 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 배지 지갑 등록 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 처음 수령시에 이름과 비밀번호를 입력하여 나만의 배지 지갑 작성 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 03 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 발행 완료 메일 확인 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 수령 절차 완료 후, 발행 완료 메일이 전송됨 ")])])])])],1),_c('div',{staticClass:"d-flex",class:{ 'flex-column': _vm.$vuetify.breakpoint.xsOnly }},[_c('div',{staticClass:"step-title le-heading6 bg-blue11 text-white1 d-flex align-center justify-center"},[_vm._v(" Step 04 ")]),_c('v-card',{staticClass:"d-flex flex-grow-1",class:{
                            'pt-15 pb-15 pl-10 pr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'pt-10 pb-10 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                            'pt-10 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                                'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                                'mr-5': _vm.$vuetify.breakpoint.smOnly,
                                'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                            }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-share-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 80 : 80),"height":_vm.$vuetify.breakpoint.mdAndUp ? 88 : (_vm.$vuetify.breakpoint.smAndDown ? 68 : 68),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{ 'mb-5': _vm.$vuetify.breakpoint.mdAndUp, 'mb-4': _vm.$vuetify.breakpoint.smOnly, 'mb-3': _vm.$vuetify.breakpoint.xsOnly }},[_vm._v(" 수령 배지 확인 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 배지 지갑에 로그인하여 배지 상세내용 확인 및 공유 가능 ")])])])])],1)])])]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" 레코스 오픈배지 지갑 기능 ")]),_c('div',{staticClass:"d-flex flex-column mx-auto gap-10",staticStyle:{"max-width":"790px"}},[_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" 모으기 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 전 세계에서 발행한 오픈배지를 하나의 지갑에 모으고 관리 ")])])])]),_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" 공개하기 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 내가 공개하고 싶은 오픈배지만 공개 가능 ")]),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 공개 지갑으로 나만의 포트폴리오 작성 ")])])])]),_c('v-card',{staticClass:"d-flex",class:{
                        'pa-10': _vm.$vuetify.breakpoint.mdAndUp,
                        'pt-7 pb-7 pl-5 pr-5': _vm.$vuetify.breakpoint.smOnly,
                        'pt-8 pb-8 pl-3 pr-3': _vm.$vuetify.breakpoint.xsOnly
                    }},[_c('div',{staticClass:"d-flex align-center justify-center",class:{
                            'mr-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'mr-5': _vm.$vuetify.breakpoint.smOnly,
                            'mr-3': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('v-img',{attrs:{"src":require('@/assets/img/management-function-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 140 : (_vm.$vuetify.breakpoint.smAndDown ? 96 : 96),"height":_vm.$vuetify.breakpoint.mdAndUp ? 104 : (_vm.$vuetify.breakpoint.smAndDown ? 72 : 72),"alt":""}})],1),_c('div',[_c('p',{staticClass:"le-heading5 text-blue3",class:{
                                'mb-5': _vm.$vuetify.breakpoint.mdAndUp,
                                'mb-3': _vm.$vuetify.breakpoint.smAndDown
                            }},[_vm._v(" 공유하기 ")]),_c('div',{staticClass:"d-flex flex-column gap-3"},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" 언제 어디서나 SNS에 업로드하고 URL로 공유 가능 ")])])])])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }