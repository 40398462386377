<template>
    <div class="banner-blog bg-orange2">
        <div
            class="c-contanier"
            :class="{' mx-auto w-100': $vuetify.breakpoint.lgAndUp}"
        >
            <v-row
                class="d-flex align-center justify-center"
            >
                <v-col
                    cols="12"
                    lg="10"
                    md="11"
                    sm="12"
                >
                    <div
                        class=""
                        :class="{ 'd-flex align-center justify-space-between': $vuetify.breakpoint.smAndUp, 'd-flex flex-column align-center justify-center': $vuetify.breakpoint.xsOnly }"
                    >
                        <div :class="{ 'd-flex align-center': $vuetify.breakpoint.smAndUp, 'd-flex flex-column align-center': $vuetify.breakpoint.xsOnly }">
                            <div class="banner-blog__obj">
                                <v-img
                                    :src="require('@/assets/img/edu-banner-blog.png')"
                                    :width="$vuetify.breakpoint.mdAndUp ? 196 : ($vuetify.breakpoint.smAndDown ? 117 : 93)"
                                    :height="$vuetify.breakpoint.mdAndUp ? 133 : ($vuetify.breakpoint.smAndDown ? 79 : 63)"
                                    alt=""
                                />
                            </div>
                            
                            <p
                                class="le-text-subtitle2"
                                :class="{
                                    'ml-2': $vuetify.breakpoint.mdAndUp,
                                    'ml-1': $vuetify.breakpoint.smOnly,
                                    'mt-3': $vuetify.breakpoint.xsOnly,
                                    'text-center': $vuetify.breakpoint.xsOnly
                                }"
                            >
                                <strong>레코스 오픈배지</strong>의 더 많은 사례를 확인해 보세요.
                            </p>
                        </div>

                        <div :class="{'ml-5': $vuetify.breakpoint.smAndUp, 'mt-5': $vuetify.breakpoint.xsOnly }">
                            <v-btn
                                color="orange1"
                                depressed
                                :small="$vuetify.breakpoint.smAndDown"
                                :large="$vuetify.breakpoint.mdAndUp"
                                class="text-white1"
                                href="https://blog.naver.com/lecoskorea"
                                target="_blank"
                            >
                                공식 블로그 바로가기
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BannerBlog',
    props: {
        //
    }
};
</script>