import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Meta from 'vue-meta';
import SimpleVueValidation from 'simple-vue-validator';
import VModal from 'vue-js-modal';
import VueGtm from 'vue-gtm';
import vuetify from './plugins/vuetify';
// import VueGtag from 'vue-gtag';

// Vue.use(VueGtag, {
//     config: {
//         id: 'G-714229T6E4',  // Google Analytics의 Tracking ID
//         params: {
//             send_page_view: false, // default : true
//         },
//     },
// }, router);

Vue.use(VueGtm, {
    id: 'GTM-NRZC9JZ3',
    enabled: true,
    debug: true,
    loadScript: true,
    defer: false,
});

Vue.config.productionTip = false;

Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid', // 변화시키는 구분 값
    refreshOnceOnNavigation: true,
});

Vue.use(SimpleVueValidation);
Vue.use(VModal, { dynamic: true });

// Vue.use(VueGtag, {
//     config: {
//         id: 'UA-215924196-1',  // Google Analytics의 Tracking ID
//         params: {
//             send_page_view: false, // default : true
//         },
//     },
//     router
// });

new Vue({
    created() {
        AOS.init();
    },
    router,
    store,
    i18n,
    vuetify,
    // RenderAfterDocument Event:'render-event'name must correspond to vue-config.js
    mounted() {
        document.dispatchEvent(new Event('render-event'));
    },
    render: (h) => h(App),
}).$mount('#app');
