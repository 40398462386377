<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="언론보도"
            sub-title="언론에 보도된 레코스"
            bg-color=""
        />

        <!-- ANCHOR: 테이블 -->
        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <v-row class="d-flex justify-center">
                    <v-col
                        cols="12"
                        lg="8"
                        md="10"
                        sm="12"
                    >
                        <v-simple-table class="le-simple-table">
                            <template v-slot:default>
                                <tbody>
                                    <tr
                                        v-for="item in getPressItems"
                                        :key="item.id"
                                        @click="goToPressLink(item.link)"
                                    >
                                        <td>
                                            <div>
                                                <div class="d-flex flex-column gap-3">
                                                    <div class="d-flex align-center">
                                                        <v-icon
                                                            size="32"
                                                            color="blue10"
                                                            class="mr-2"
                                                        >
                                                            fontagon-icons ft-icon ft-ic_newsPaper_fill
                                                        </v-icon>

                                                        <span class="le-text-body3">{{ item.mediaCompany }}</span>
                                                    </div>
                                            
                                                    <div class="le-text-subtitle3 truncate-two-lines">
                                                        {{ item.title }}
                                                    </div>

                                                    <div class="le-text-body3">
                                                        {{ item.date }}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                </v-row>
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { mapGetters } from 'vuex';
import { getPressMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';

export default {
    name: 'PressPage',
    components: {
        PageTitle
    },

    metaInfo() {
        return getPressMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            //
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    computed: {
        ...mapGetters('notice', ['getPressItems']),
    },

    created() {
        //
    },

    mounted() {
        //
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        goToPressLink(link) {
            window.open(link, '_blank');
        },
    },
    // !SECTION: Methods
};
</script>
