import ogImageCommon from '../assets/img/og-sns-common.png';
// import ogImageHome from '../assets/img/og-sns-home.png';
// import ogImageOpenbadge from '../assets/img/og-sns-openbadge.png';
// import ogImageIssuance from '../assets/img/og-sns-issuance.png';
// import ogImageManagement from '../assets/img/og-sns-management.png';
// import ogImageSample from '../assets/img/og-sns-sample.png';
// import ogImageGallery from '../assets/img/og-sns-gallery.png';
// import ogImageEducation from '../assets/img/og-sns-education.png';
// import ogImageEnterprise from '../assets/img/og-sns-enterprise.png';
// import ogImageAssociation from '../assets/img/og-sns-association.png';
// import ogImageCompany from '../assets/img/og-sns-company.png';
// import ogImagePress from '../assets/img/og-sns-press.png';
// import ogImageNotice from '../assets/img/og-sns-notice.png';
// import ogImageContact from '../assets/img/og-sns-contact.png';
// import ogImageLogin from '../assets/img/og-sns-login.png';


/* SECTION: Home */
// ANCHOR: Home
export const getHomeMetaInfo = (vm) => ({
    title: '㈜레코스(LecoS)', // vm.$t('meta.home.title')
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스(LecoS)는 아시아 최초 국제표준규격 인증을 받은 오픈배지(Open Badge) 플랫폼입니다. 오픈배지가 필요한 모든 곳, 레코스와 함께하세요.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS)',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '차원이 다른 디지털 인증, 레코스 오픈배지가 제공합니다.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Home */

/* SECTION: Open Badges */
// ANCHOR: Open Badges
export const getOpenBadgesMetaInfo = (vm) => ({
    title: '오픈배지 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '오픈배지는 특별합니다. 당신이 가진 기술,지식,명예,경험,역량들을 블록체인 기술을 사용한 데이터로 완벽하게 디지털 배지에 담을 수 있습니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 오픈배지',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지의 모든 것, 레코스에 다 있습니다.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Open Badges */

/* SECTION: Service */
// ANCHOR: Issuance
export const getIssuanceMetaInfo = (vm) => ({
    title: '도입-발행 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '계약 진행부터 배지 발행까지 레코스 오픈배지라면 복잡한 과정 없이 쉽게 도입 및 발행이 가능합니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 도입-발행',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '복잡한 절차 없이 즉시 오픈배지 도입 및 발행이 가능합니다.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Management
export const getManagementMetaInfo = (vm) => ({
    title: '수령-보관 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스 오픈배지 지갑으로 전 세계에서 발행된 오픈배지를 하나의 지갑에서 모으고 관리하고 공유할 수 있습니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 수령-보관',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지 지갑에서 쉽고 빠르게 배지를 수령하고 관리하세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Sample Badges
export const getSampleBadgesMetaInfo = (vm) => ({
    title: '샘플배지 발행하기 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스 샘플배지 지갑을 발행해서 배지 지갑을 체험하고 레코스에서 발행된 배지들을 검색해보세요.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 샘플배지 발행하기',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '레코스의 샘플배지를 발행하고 배지 지갑을 체험해보세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Badges Gallery
export const getBadgesGalleryMetaInfo = (vm) => ({
    title: '배지 갤러리 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스를 통해 발행된 다양한 기관들의 배지들을 확인해보세요.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 배지 갤러리',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '레코스에서 발행된 다양한 배지들을 확인해보세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

/* !SECTION: Service */

/* SECTION: Solutions */
// ANCHOR: Education
export const getEducationMetaInfo = (vm) => ({
    title: '교육 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '오픈배지 안에 교육관련 내용을 디지털화하여 배지안에 담아 발행하여 교육의 품질을 향상시킬 수 있습니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 교육',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지로 교육을 빛내보세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Enterprise
export const getEnterpriseMetaInfo = (vm) => ({
    title: '기업 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '오픈배지는 신뢰도 높은 스킬 증명으로 인재 배치에 활용 가능하며 사원의 학습 동기부여 뿐 아니라 기업 브랜드 홍보효과도 가져옵니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 기업',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지로 기업의 가치를 높여보세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Association
export const getAssociationMetaInfo = (vm) => ({
    title: '기관 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '오픈배지는 복잡한 절차 없이 기관의 브랜드를 보호하며 회원 혜택을 강화하고 소속감 고취와 프로그램 홍보까지도 가능하게 합니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 기관',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지로 기관의 신뢰를 높여보세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Solutions */

/* SECTION: Company */
// ANCHOR: About
export const getAboutMetaInfo = (vm) => ({
    title: '회사소개 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스는 네트러닝 그룹의 오픈배지 전문 회사 입니다. 주소 : 서울시 마포구 마포대로 86 창강빌딩 4층 421호, 전화번호 : 02-716-5571, 대표이사 : 노원석',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 회사소개',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '학습과 스킬의 에코시스템을 만드는 레코스입니다.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Press
export const getPressMetaInfo = (vm) => ({
    title: '언론보도 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '오픈배지(디지털배지)전문 기업인 레코스에 대한 뉴스기사입니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 언론보도',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '언론에 보도된 레코스의 소식을 확인하세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Notice
export const getNoticeMetaInfo = (vm) => ({
    title: '공지사항 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스 오픈배지(디지털배지)의 업데이트된 다양한 소식들을 확인하세요.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 공지사항',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '레코스의 새로운 소식과 이벤트를 확인하세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});

// ANCHOR: Contact
export const getContactMetaInfo = (vm) => ({
    title: '문의하기 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '디지털배지, 오픈배지에 대한 문의사항은 언제든지 연락 주십시오.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 문의하기',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지에 대해 언제든지 문의해 주세요.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Company */

/* SECTION: Login */
// ANCHOR: Login
export const getLoginMetaInfo = (vm) => ({
    title: '로그인 | ㈜레코스(LecoS)',
    titleTemplate: '%s | Learn for the Future',
    meta: [
        {
            name: 'description',
            content: '레코스 오픈배지 발행 단체와 수령인을 위한 전용 페이지입니다.',
            vmid: 'description',
        },
        { name: 'keywords', content: vm.$t('meta.keywords') },
        {
            property: 'og:title',
            content: '㈜레코스(LecoS) | 로그인',
            vmid: 'og:title',
        },
        { property: 'og:type', content: 'website', vmid: 'og:type' },
        { property: 'og:image', content: ogImageCommon, vmid: 'og:image' },
        {
            property: 'og:description',
            content: '오픈배지 사용자를 위한 페이지입니다.',
            vmid: 'og:description',
        },
    ],
    script: [
        {
            src: 'https://lecos.co.kr/clarity-lecos-korea/tracking.js',
            async: true,
            defer: true,
        },
    ],
});
/* !SECTION: Login */