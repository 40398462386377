<template>
    <!-- SECTION: Template -->
    <div class="sub-page">
        <!-- ANCHOR: PageTitle -->
        <PageTitle
            main-title="배지 갤러리"
            sub-title="레코스에서 발행된 다양한 오픈배지"
            bg-color=""
        />

        <section class="c-section d-flex justify-center">
            <div class="c-contanier">
                <!-- ANCHOR: Search  -->
                <div class="d-flex justify-end">
                    <v-text-field
                        v-model="search"
                        outlined
                        :dense="$vuetify.breakpoint.mdAndUp"
                        :class="{ 'xsmall': $vuetify.breakpoint.smAndDown }"
                        style="max-width: 240px;"
                        hide-details
                        placeholder="검색"
                        color="blue1"
                        @input="filterBadges"
                    >
                        <template v-slot:append>
                            <button
                                v-if="search"
                                @click="clearSearch"
                            >
                                <v-icon>fontagon-icons ft-icon ft-ic_delete</v-icon>
                            </button>

                            <v-icon
                                class="search-icon"
                            >
                                fontagon-icons ft-icon ft-ic_search
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
                <!-- // search  -->

                <!-- ANCHOR: Tab -->
                <div
                    class="tab-container"
                    :class="{ 'mt-10': $vuetify.breakpoint.mdAndUp, 'mt-8': $vuetify.breakpoint.smAndDown, 'mb-25': $vuetify.breakpoint.mdAndUp, 'mb-15': $vuetify.breakpoint.smAndDown }"
                >
                    <swiper
                        v-if="false"
                        :options="swiperBoardTab"
                        class="tab tab-space-1"
                    >
                        <swiper-slide class="tab__item">
                            <router-link
                                :to="{ name: 'BadgesGalleryPage', query: { category: 'all' } }"
                                class="tab__link"
                                :class="[ selectdCategory == 'all' ? 'active' : '' ]"
                                @click.native="updateCategoryAndFilter"
                            >
                                전체
                            </router-link>
                        </swiper-slide>

                        <swiper-slide class="tab__item">
                            <router-link
                                :to="{ name: 'BadgesGalleryPage', query: { category: 'edu' } }"
                                class="tab__link"
                                :class="[ selectdCategory == 'edu' ? 'active' : '' ]"
                                @click.native="updateCategoryAndFilter"
                            >
                                교육기관
                            </router-link>
                        </swiper-slide>

                        <swiper-slide class="tab__item">
                            <router-link
                                :to="{ name: 'BadgesGalleryPage', query: { category: 'corp' } }"
                                class="tab__link"
                                :class="[ selectdCategory == 'corp' ? 'active' : '' ]"
                                @click.native="updateCategoryAndFilter"
                            >
                                기업
                            </router-link>
                        </swiper-slide>

                        <swiper-slide class="tab__item">
                            <router-link
                                :to="{ name: 'BadgesGalleryPage', query: { category: 'group' } }"
                                class="tab__link"
                                :class="[ selectdCategory == 'group' ? 'active' : '' ]"
                                @click.native="updateCategoryAndFilter"
                            >
                                단체
                            </router-link>
                        </swiper-slide>

                        <swiper-slide class="tab__item">
                            <router-link
                                :to="{ name: 'BadgesGalleryPage', query: { category: 'etc' } }"
                                class="tab__link"
                                :class="[ selectdCategory == 'etc' ? 'active' : '' ]"
                                @click.native="updateCategoryAndFilter"
                            >
                                그외
                            </router-link>
                        </swiper-slide>
                    </swiper>

                    <swiper
                        :options="swiperBoardTab"
                        class="tab tab-space-1"
                    >
                        <swiper-slide
                            v-for="(tab, index) in tabs"
                            :key="index"
                            class="tab__item"
                        >
                            <router-link
                                :to="tab.route"
                                class="tab__link"
                                :class="{ active: selectdCategory === tab.category }"
                                @click.native="updateCategoryAndFilter"
                            >
                                {{ tab.label }}
                            </router-link>
                        </swiper-slide>
                    </swiper>
                </div>
                <!-- // tab -->

                <!-- ANCHOR: 검색 결과가 없을 때 표시할 메시지 -->
                <div
                    v-if="!hasResults"
                    class="text-center board-list-space le-text-body2"
                >
                    검색 결과가 없습니다.
                </div>
                <!-- // 검색 결과가 없을 때 표시할 메시지 -->

                <!-- ANCHOR: BoardList -->
                <ul class="board-list board-list-space">
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/M2habGRsbnhVOFAwdVp0MUhSVDNjUT09/badges/cGRZQkQrQVREQW1tV1Q1SXRxWGFtQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-156.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    창립25주년기념 공동학술포럼
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="http://kohvea.kr/"
                                        target="_blank"
                                    >
                                        사단법인 한국고등직업교육학회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZnlvZFBYbVN0bk1uM3FsaklLYmhwUT09/badges/eU15M0VrMEhEeUJISmUwd3dYQ1BHQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-155.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024년 3분기 청소년방과후아카데미 우수 청소년
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://www.sbyouth.or.kr/"
                                        target="_blank"
                                    >
                                        서울시립성북청소년센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZnlvZFBYbVN0bk1uM3FsaklLYmhwUT09/badges/NTJtQVZvTUM3bXZBd1VEbUxRZmQxQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-154.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024 스비 맵 프로젝트
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://www.sbyouth.or.kr/"
                                        target="_blank"
                                    >
                                        서울시립성북청소년센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/cnlaWG1tSnRMeTA2M1dtcWNoZmhvUT09/badges/QmVhbXFsVWsvZUlnTWFRRG9pOTZjUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-153.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 DKU K-MOOC COURSE
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.dankook.ac.kr/web/kor"
                                        target="_blank"
                                    >단국대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/cnlaWG1tSnRMeTA2M1dtcWNoZmhvUT09/badges/WEFLR2J6bUxJbWYxTXlLbE1YbTQ1Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-152.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 DKU SDGs COMPLETION
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.dankook.ac.kr/web/kor"
                                        target="_blank"
                                    >단국대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/cnlaWG1tSnRMeTA2M1dtcWNoZmhvUT09/badges/RXRrdUNGU1JxYnRXeXY5a2lHZTdLZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-151.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 DKU 마일리지 흑색인증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.dankook.ac.kr/web/kor"
                                        target="_blank"
                                    >단국대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZWxSOWxtZm1ic0JBYkpYL2w4MmQvdz09/badges/VzVvb1hqNmEzanA4NEZKYVJtME0xQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-150.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    제10회 SW 사고력 올림피아드 은상: 초등5~6부문
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://edu.ggumeasy.com/"
                                        target="_blank"
                                    >
                                        SW사고력 올림피아드 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZWxSOWxtZm1ic0JBYkpYL2w4MmQvdz09/badges/UXVWSGllUlhpVCtWVkRFUlc4cERqZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-149.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    제10회 SW 사고력 올림피아드 동상: 초등5~6부문
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://edu.ggumeasy.com/"
                                        target="_blank"
                                    >
                                        SW사고력 올림피아드 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZWxSOWxtZm1ic0JBYkpYL2w4MmQvdz09/badges/VUNLYVRocUNYZHRnSXVPSkRQcytHZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-148.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    제10회 SW 사고력 올림피아드 장려상:초등5~6부문
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://edu.ggumeasy.com/"
                                        target="_blank"
                                    >
                                        SW사고력 올림피아드 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/MGp3RzNvOEZrWVhDZ05kSEJvMW95QT09/badges/enNUaWUwaUE4b1ZlZGZrYkQrNStzUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-147.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    AICE Junior
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/N0R0dE1XMmRKc0xFQ2FkcldmcHo2dz09/badges/UGtUUmVPS2FNRXBpU0JnQ3NxSS95dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-146.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AI를 활용한 문제 해결 과정(실습)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/N0R0dE1XMmRKc0xFQ2FkcldmcHo2dz09/badges/YllFN2VGMzRiak5JVk9TQzFtbmlwdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-145.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    피드백 및 평가기법과 빅데이터(1)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/N0R0dE1XMmRKc0xFQ2FkcldmcHo2dz09/badges/TUZ2Wm9nSlMwbHlNTGVyVEoyS1BKdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-144.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학습동기 유발(1)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/N0R0dE1XMmRKc0xFQ2FkcldmcHo2dz09/badges/Tmg2YzJ6T0tkWmszcUlSMEdjL3lBQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-143.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    지도 및 멘토링 기법에 사용되는 AI(1)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/N0R0dE1XMmRKc0xFQ2FkcldmcHo2dz09/badges/c241b0lXZFBRRG02ZmtpNHh2ZWRNdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-142.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    프로젝트기반 학습 퍼실리테이션 교수 스킬 향상 과정(1)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/b1BTOVFHQ3RCRS8rbkdOTURIMUk1dz09/badges/OHUzZGVWeWY1UTZoSHc2ZGYzU2lydz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-141.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    GOLD BADGE ver.2
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://selc.or.kr/lms/main/MainView.do"
                                        target="_blank"
                                    >대학 e-러닝 기반 학점인정 컨소시엄 (College e-learning-based credit recognition consortium)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/b1BTOVFHQ3RCRS8rbkdOTURIMUk1dz09/badges/MEROMGtxSGNVVGM5MGYzOXNFRDE4Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-140.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SILVER BADGE ver.2
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://selc.or.kr/lms/main/MainView.do"
                                        target="_blank"
                                    >대학 e-러닝 기반 학점인정 컨소시엄 (College e-learning-based credit recognition consortium)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/b1BTOVFHQ3RCRS8rbkdOTURIMUk1dz09/badges/NmF1M2tzTjZJQWJzN0ZBdmJYMElwZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-139.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    BRONZE BADGE ver.2
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://selc.or.kr/lms/main/MainView.do"
                                        target="_blank"
                                    >대학 e-러닝 기반 학점인정 컨소시엄 (College e-learning-based credit recognition consortium)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/b1BTOVFHQ3RCRS8rbkdOTURIMUk1dz09/badges/aDNzQW5rbVBmSlN6RFJHK0JHNnE5Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-138.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    GREEN BADGE ver.2
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://selc.or.kr/lms/main/MainView.do"
                                        target="_blank"
                                    >대학 e-러닝 기반 학점인정 컨소시엄 (College e-learning-based credit recognition consortium)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SGhUcTZCRWpNUjRXZitYNGhXVzd2dz09/badges/NTg5dWNLUHpRNHFrS3R5SmtUZzRRQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-137.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    디지털교수학습(심화1)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sen.go.kr/sen/index.do"
                                        target="_blank"
                                    >서울특별시교육청</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SGhUcTZCRWpNUjRXZitYNGhXVzd2dz09/badges/TVZrYjJSdHVFOGEwQWkxOExsRzVTdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-136.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 AI·에듀테크 선도교사단
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sen.go.kr/sen/index.do"
                                        target="_blank"
                                    >서울특별시교육청</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SGhUcTZCRWpNUjRXZitYNGhXVzd2dz09/badges/Y2p4YW5nTFZPL2E2UlZiWHUwUWJLdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-135.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 에듀테크 코리아 페어 서울시교육청 에듀테크 충전소
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sen.go.kr/sen/index.do"
                                        target="_blank"
                                    >서울특별시교육청</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/MWlyb3FnQ2xscCtYYm40TlZYTzM2Zz09/badges/eXZKMXZGUmx5dGxOMU5zZU9qVjNEdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-134.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    국외 한국어교원(K-티처) 프로그램
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.korean.go.kr/"
                                        target="_blank"
                                    >국립국어원 (National Institute of Korean Language)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/L2Z5dU1FQ2tIMFZhR3o5U3BvbVVidz09/badges/dEZSaUdTT3BNeVQ4TTNaWU8yK09WUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-133.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Fall 2024 STAR TREK
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ust.ac.kr/kor/"
                                        target="_blank"
                                    >과학기술연합대학원대학교(UST)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/L2Z5dU1FQ2tIMFZhR3o5U3BvbVVidz09/badges/QldJbEdRbnFaRkx6S2oyVzJ3Rlg2dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-132.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Fall 2024 STAR TREK
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ust.ac.kr/kor/"
                                        target="_blank"
                                    >과학기술연합대학원대학교(UST)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/K2FLSG41UTI1bnRjWlYydmxuamcyZz09/badges/TGxJeUdZUnBMY2wzaUJJQnM2QmhaQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-131.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    위즈덤아너스:세계인과정
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.iscu.ac.kr/"
                                        target="_blank"
                                    >서울사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/K2FLSG41UTI1bnRjWlYydmxuamcyZz09/badges/aWFuWjV2a3pEWTZKajNmL3FPcHhZZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-130.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    생성형 AI 크리에이티브 디자이너
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.iscu.ac.kr/"
                                        target="_blank"
                                    >서울사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/K2FLSG41UTI1bnRjWlYydmxuamcyZz09/badges/MXcxMHRBYmZqNnk4WGdXdDB5R00wdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-129.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    컬러코디네이터
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.iscu.ac.kr/"
                                        target="_blank"
                                    >서울사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/akR3cFZ2ZGFTNzMwd2Fsam9zK0t1Zz09/badges/THlwMml6YmRvUkVEbHYwUE0zRXhvdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-128.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024 에듀플러스위크 미래교육박람회 교육체험증명
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="http://www.eduplusshow.co.kr/"
                                        target="_blank"
                                    >
                                        에듀플러스위크 미래교육박람회 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/akR3cFZ2ZGFTNzMwd2Fsam9zK0t1Zz09/badges/MzAxK1NQc3JoQ0tFcSt5UlcxRDRXQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-127.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024 에듀플러스위크 미래교육박람회 교사연수 이수
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="http://www.eduplusshow.co.kr/"
                                        target="_blank"
                                    >
                                        에듀플러스위크 미래교육박람회 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SGhUcTZCRWpNUjRXZitYNGhXVzd2dz09/badges/eDNLTTNLMmg3RXhmb3dJWmtzTnVzUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-126.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024 글로컬미래교육박람회 서울시교육청 교육관
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://www.sen.go.kr/sen/index.do"
                                        target="_blank"
                                    >
                                        서울특별시교육청</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SGhUcTZCRWpNUjRXZitYNGhXVzd2dz09/badges/ankraWdIek52OEhiYkQ2RFJveC9tdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-125.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    2024 에듀플러스위크교육박람회 서울시교육청 에듀테크 충전소
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://www.sen.go.kr/sen/index.do"
                                        target="_blank"
                                    >
                                        서울특별시교육청</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SEx6NDJKOWNWaWNQWno1dXJyUWlNZz09/badges/RTNpUEcyNm85NWl3MXRXbXQySmY5QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-124.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    K디지털기초역량훈련 파이썬 풀스택 MASTER
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://itup.co.kr/rv1/k-digital/main/couse"
                                        target="_blank"
                                    >잇업</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/SEx6NDJKOWNWaWNQWno1dXJyUWlNZz09/badges/SGtwTTFmNkRZQjNidFZpSUlWUlhTQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-123.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title">
                                    K디지털기초역량훈련 유니티 게임개발 MASTER
                                </div>

                                <div class="board-list__issuer">
                                    <a
                                        href="https://itup.co.kr/rv1/k-digital/main/couse"
                                        target="_blank"
                                    >잇업</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/NnJiWTZSUm4wb0I4Vm0xZ1Fzc3JtZz09/badges/a0czbjhtb0dqK2h3RlF5dmUzNk5sQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-121.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    KHU Valley Program 수상 (11기)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.khu.ac.kr/kor/user/main/view.do"
                                        target="_blank"
                                    >경희대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/NnJiWTZSUm4wb0I4Vm0xZ1Fzc3JtZz09/badges/S0VLcUdhREpXdzhrZVRLQ0llZEwvdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-121.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    KHU Valley Program 이수 (11기)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.khu.ac.kr/kor/user/main/view.do"
                                        target="_blank"
                                    >경희대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/Z2RlZ29tQzhobTJObGxpVVZxdUdrdz09/badges/aVBGMnI2YlV1b0taY3JyT2F0RTJ0UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-120.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    hunet Executive MBA (Hybrid Course)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hunet.co.kr/"
                                        target="_blank"
                                    >휴넷</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/Z2RlZ29tQzhobTJObGxpVVZxdUdrdz09/badges/dFdObzlHMXEwM1ZDc0JDMlNnK1AvQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-119.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    hunet Executive MBA (Online Course)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hunet.co.kr/"
                                        target="_blank"
                                    >휴넷</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/issuers/ZWxSOWxtZm1ic0JBYkpYL2w4MmQvdz09/badges/OG9CRGwrSXJJU3RqMnN0ZkZaNTZaUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-118.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    제10회SW사고력 올림피아드 응시자
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://edu.ggumeasy.com/?pn=product.view&cuid=436&pcode=P7517-X7316-C8075"
                                        target="_blank"
                                    >SW사고력 올림피아드 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/NDYwQTgwbVpEdWlPbUMreVpJdFJYdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-117.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2024 Y.O.P페스티벌 스비오락실
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sbyouth.or.kr/"
                                        target="_blank"
                                    >서울시립성북청소년센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/THVIeVlENXhia2hNN1dDbFRvNjZpUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-116.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2학기 새로운 시대가 원하는 인재_미래융합아카데미
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.yeonsung.ac.kr/ko/index.do"
                                        target="_blank"
                                    >연성대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/bWxnS2pieUJXdTZkNVVIVU9iZlVyQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-115.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2학기 지역이 원하는 인재_리빙랩
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.yeonsung.ac.kr/ko/index.do"
                                        target="_blank"
                                    >연성대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/QmJBOUdtRjg0QTc0RVpvZmI0UStlUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-114.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2학기 연성이 찾아낸 원석_고교생 경진대회/공모전
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.yeonsung.ac.kr/ko/index.do"
                                        target="_blank"
                                    >연성대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/ZEZ4Z0NkNCtxdXFld1VnbmdqeU1vdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-113.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2학기 스터디그룹 프로그램 수료증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://ictl.yonsei.ac.kr/"
                                        target="_blank"
                                    >연세대학교 교무처 교수학습혁신센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/eHBJZURoRVo5dWtBdnZiNGtaK3BHQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-112.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023학년도 연세 대학생활∙학습 노하우 공모전 상장
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://ictl.yonsei.ac.kr/"
                                        target="_blank"
                                    >연세대학교 교무처 교수학습혁신센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NGhwdjJrbWFjejIwUDV5cm9Bb1RxZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-111-2.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2022 온라인 튜터링 프로그램 수료증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://ictl.yonsei.ac.kr/"
                                        target="_blank"
                                    >연세대학교 교무처 교수학습혁신센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="false"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/alVwalNWVFlpajVEODZZajgybzVVZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-110.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    한양여자대학교 표준현장실습학기제(Co-op) "BRONZE"
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/cW1aaUhnUEpYL3dBYi91RUlDZmM3dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-109.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    한양여자대학교 학생 모니터링단
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/SnlPczNkS2hnbmYva25KUFF3dzJkZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-108.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    한양여자대학교 하이유 학습역량 향상수기 영상공모전 "대상"
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/b2lpLzVkUGIvUWdKeS9ORG1NdzVodz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-107.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    한양여자대학교 융합 마이크로 전공 과정 이수증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/czI1Y1FyRjZ4QW5TSXFJRmRjSkQ0UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-106.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    ESG 실천 서포터즈
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Mno3UW5tQU05czJOaU1nRFRTTkZQdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-105.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023 TELOS트랙 참여학생 우수사례
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ewha.ac.kr/ewha/index.do"
                                        target="_blank"
                                    >이화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/TlVHOWlaWGx3blRIRW1MVjRnVUVvdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-104.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2 도전학기제 우수사례
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ewha.ac.kr/ewha/index.do"
                                        target="_blank"
                                    >이화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/d2hFZVlVTU4xeGt2cHJhMmdyZEJKUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-103.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023 이화 DnA Lab 우수 소그룹
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ewha.ac.kr/ewha/index.do"
                                        target="_blank"
                                    >이화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Rzd0ZU52RHN5cC8yUXpNODB4eE5zZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-102.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Prompt Engineer_FUNDAMENTAL
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://udemy.wjtb.co.kr/"
                                        target="_blank"
                                    >웅진씽크빅</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/TjRqek95dE5PeEUyU0NlcU1VdXRIUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-101.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    휴넷L&D연구소 에듀테크 학습 설계 과정 마스터
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://blog.naver.com/edutech001"
                                        target="_blank"
                                    >주식회사 휴넷엘앤디연구소</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/ZkZHRmtkZ1lhSWZHUzIzNFVBdnBLUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-100.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HY Nanodegree - Business Analytics (Fall 2023)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교(HANYANG UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/b3dMVjlZcDdmSTU1akc1OHZoT1R4Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-099.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HY Nanodegree - Econommic Analytics (Fall 2023)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교(HANYANG UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/UmZUMzZ6YkZuVWI5ZVB3WDl2NXFjUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-098.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HRD 아카데미_HRD 아카데미 전략과 교육체계 수립
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kma.or.kr/main.do"
                                        target="_blank"
                                    >KMA한국능률협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/OWQ0Tm9ZTXVFRmRXQU41MFBsTTAzZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-097.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    DX 아카데미_생성 AI시대, 인공지능 이해와 ChatGPT 활용
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kma.or.kr/main.do"
                                        target="_blank"
                                    >KMA한국능률협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/QmhzWEVYZlBzUFdkV3hiVmFacVRDdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-096.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    비즈니스 스킬_일잘러로 성장하기 위한 커리어개발 잡 크래프팅
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kma.or.kr/main.do"
                                        target="_blank"
                                    >KMA한국능률협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NGZLU29GMUlUdnB5Z3ZndFZBbXBCQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-095.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    전략/기획 아카데미_신사업 타당성 분석 및 의사결정 실무
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kma.or.kr/main.do"
                                        target="_blank"
                                    >KMA한국능률협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/T1lYSXZVVnM1enRvcXZoaHhWcGR5QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-094.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    리더십 아카데미_고급관리자 역량개발
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kma.or.kr/main.do"
                                        target="_blank"
                                    >KMA한국능률협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/eWFHMzNjeVpJQWNvdnAyOERHY1VGZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-093.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HYCU 역량 탐험가
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hycu.ac.kr/user/index.do"
                                        target="_blank"
                                    >한양사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/amMvTU5PNW9lbVF6TG1VTXVPUmx3QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-092.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SC-PBL 실버
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hycu.ac.kr/user/index.do"
                                        target="_blank"
                                    >한양사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/N29BZ0VXYlVjNXhMbzdTMUZ4WEp0QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-091.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학습 나눔 챔피언
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hycu.ac.kr/user/index.do"
                                        target="_blank"
                                    >한양사이버대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/OWtyRmQyS1VwaGpieXlVZVFxb2dNQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-090.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-2 WK HONORS CLUB
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.wku.ac.kr/"
                                        target="_blank"
                                    >원광대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MXpuT1RRb2xPc2hualFrWEJkcWdKZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-089.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023 LAIDD 멘토링 프로젝트 암치료 약물 타겟 발굴
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.kpbma.or.kr/"
                                        target="_blank"
                                    >한국제약바이오협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Q1hJb2xsQTNsTXplcGdlaEdYbkZHQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-088.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023 LAIDD 멘토링 프로젝트 약물 반응 예측
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.kpbma.or.kr/"
                                        target="_blank"
                                    >한국제약바이오협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/dDFkTTl2VDVKT096bDBYRWUyaDJ1Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-087.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AI신약개발 기초 역량 인증 (화학&화학정보학)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.kpbma.or.kr/"
                                        target="_blank"
                                    >한국제약바이오협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/RHdGdUt6ajVpVDJaeUdWTVBiR0M1QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-086.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Design Thinking 프로세스 수업활용
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/WGlhUkhJeEVhNUtnWmN3a0Q0ZTlGdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-085.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    IC-PBL/캡스톤디자인 Best Class 공모전 [캡스톤디자인] 우수상
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/QXBlejZHY3VkOWdkZ3ZQZUJBcTNyUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-084.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    IC-PBL/캡스톤디자인 Best Class 공모전 [IC-PBL] 대상
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NytYSHRNZVlBSXdVU2RGRDZQczhUQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-083.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    코딩마스터스 그랜드마스터 (1차)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aivle.kt.co.kr/home/main/indexMain"
                                        target="_blank"
                                    >(주)케이티</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/em9BajdESk5ORVVTaVJYVTIwNTg5Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-082.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    코딩마스터스 마스터 (1차)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aivle.kt.co.kr/home/main/indexMain"
                                        target="_blank"
                                    >(주)케이티</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/K3ROQWZDTWpKc1BickZvYmhQU1FOdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-081.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    KT 에이블스쿨 수료증 (AI개발자트랙)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aivle.kt.co.kr/home/main/indexMain"
                                        target="_blank"
                                    >(주)케이티</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/TXRqbVZPcEU0eklndWZXVVpheHAxUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-080.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    KT 에이블스쿨 수료증 (DX컨설턴트트랙)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aivle.kt.co.kr/home/main/indexMain"
                                        target="_blank"
                                    >(주)케이티</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/RE1hK0RBY0JQTE9McmdRKyttU1VwUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-079.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Y.O.P Autumn Festival (2023)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sbyouth.or.kr/"
                                        target="_blank"
                                    >서울시립성북청소년센터</a>
                                </div>
                            </div>
                        </div>
                    </li>                    
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Q1QyVjhRdnRWaUtRcG5hS2lDNFJBQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-078.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    직업기초능력 자가진단
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://seongam.sen.hs.kr/"
                                        target="_blank"
                                    >성암국제무역고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/QmZac3BEQ0xuSmlrL3RET1ZwdDFwUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-077.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    직업기초능력
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://seongam.sen.hs.kr/"
                                        target="_blank"
                                    >성암국제무역고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/eE54OWMrODJqbTFzZ0tyUEZ0QzlJZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-076.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학교 역량 인증 프로그램
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://school.busanedu.net/automotive-h/"
                                        target="_blank"
                                    >부산자동차고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/RTM1UFRBNW5vSGp1QmNYZW1ER290QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-075.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    직업기초능력
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://school.busanedu.net/automotive-h/"
                                        target="_blank"
                                    >부산자동차고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/V0ZXYXBmSnhQY0VSS3d6R1kwc1A1UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-074.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    직업기초능력 디딤 인증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://dongahs.djsch.kr/main.do"
                                        target="_blank"
                                    >동아마이스터고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Zks4b1dqeVd3eTM5YVE1UVRJYXFxZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-073.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    외국어능력 디딤 인증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://dongahs.djsch.kr/main.do"
                                        target="_blank"
                                    >동아마이스터고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/eXpic1lYU0FMb0dzRGJtazN5ODJsdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-072.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학생자치역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aramhs.djsch.kr/main.do"
                                        target="_blank"
                                    >대전여자상업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/K0JTS1VhbC8wY2xWbU5uUXB3c0o1UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-071.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    교내대회역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aramhs.djsch.kr/main.do"
                                        target="_blank"
                                    >대전여자상업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/bEpGNWNneGFCZ24yQng3dDZjVG1ZQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-070.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    교과우수역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aramhs.djsch.kr/main.do"
                                        target="_blank"
                                    >대전여자상업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NWU4bXRicThxYWcyM3MyMHNGSjBXZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-069.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    First Step
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kmh.hs.kr/"
                                        target="_blank"
                                    >근명고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/UHptbDVKUlZvZmxZdGM3L3VraXJ4dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-068.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학습지원역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://gntour-h.gne.go.kr/gntour-h/main.do"
                                        target="_blank"
                                    >경남관광고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Vlg0MzhGMytyYjNzazU2dGhOVGJNdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-067.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    탐구활동역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://gntour-h.gne.go.kr/gntour-h/main.do"
                                        target="_blank"
                                    >경남관광고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/UlBlQnlwWlI5UTljbXNVTUtudjlxQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-066.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    미래핵심역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.pjsg.hs.kr/main.php"
                                        target="_blank"
                                    >세경고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/S2JESEY0RXF3ZWpSWXE5Vms4ZTg3QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-065.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AI활용역량
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.pjsg.hs.kr/main.php"
                                        target="_blank"
                                    >세경고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/VC80dzc2QXQ4d2VRQ3JkNFFvZm9kUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-064.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    전공 계발(멘토링)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://hongnong.gwe.hs.kr/main.do"
                                        target="_blank"
                                    >홍천농업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/aExVRTd0SWRPOXBGSitlR0s0NHhsQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-063.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    창의 인성(모범학생)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://hongnong.gwe.hs.kr/main.do"
                                        target="_blank"
                                    >홍천농업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/c2JhMzRObmVFYmdBd09Eb0R0MFNHdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-062.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    전공 계발(자격증-골드)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://hongnong.gwe.hs.kr/main.do"
                                        target="_blank"
                                    >홍천농업고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'etc' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--grey">
                            {{ $t('service.badgeList.category.etc') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/VkdVU0w4LzlZSGo4TW10VTBQbVU4UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-061.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AMG 2023 Bali - AttendanceBadge
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://mensa.id/"
                                        target="_blank"
                                    >Mensa Indonesia</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/U2lqYVFPeWREbGFOWm83a3dpQ0FFZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-060.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HTHT맞춤형교육전문가
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://educomasia.org/"
                                        target="_blank"
                                    >사단법인 아시아교육협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MDhNbjVsZkRzbEVORW1CN0tIb2hkdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-059.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    EXPERT
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.tekville.com/"
                                        target="_blank"
                                    >테크빌교육</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MW9Hb0ZWZUhZamtWWUJQOXkwdVBhUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-058.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    ADVANCED
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.tekville.com/"
                                        target="_blank"
                                    >테크빌교육</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/a3J0dE5ScHcwbjJVZTZBNCtJU29iUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-057.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    BASIC
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.tekville.com/"
                                        target="_blank"
                                    >테크빌교육</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/OTZYVmpvWkw0cktPeW53Skd3Yk0zdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-056.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023디지털새싹-Play! 헬로우 데이터 : 처음 만나는 데이터
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://corp.alpaco.co.kr/"
                                        target="_blank"
                                    >(주)알파코</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/b2ZKbkNqVXUvczVLTjd6UHIwNVp0UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-055.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023디지털새싹-Play! 점프업 AI 블록
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://corp.alpaco.co.kr/"
                                        target="_blank"
                                    >(주)알파코</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <!-- <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/RXdsb1RlelA3eE5hRzNHdC91bXRWdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-054.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HRD SPECIALIST - DX Enabler
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://corp.alpaco.co.kr/"
                                        target="_blank"
                                    >(주)알파코</a>
                                </div>
                            </div>
                        </div>
                    </li> -->
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/STk5NXhyTi96c0UrSDFiYk9aUzJtUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-053.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023디지털새싹-Play! 날아라 파이썬 드론
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://corp.alpaco.co.kr/"
                                        target="_blank"
                                    >(주)알파코</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/VHhHa1h3NGtBSGZ2SnNUWVE5eDVhZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-052.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    제2회 UST 산학연전문가 네트워크-생명·바이오헬스 포럼
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ust.ac.kr/kor/"
                                        target="_blank"
                                    >과학기술연합대학원대학교(UST)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/TW1CbXZjMjVHM2YrVE4zU2RVQnRiZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-051.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    NCS직무능력인증 2등급
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.baewha.ac.kr/main.do"
                                        target="_blank"
                                    >배화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/azJNVlExWHp5Z3RSRGpiNHF5ODhqQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-050.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    회계사무직 트랙이수인증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.baewha.ac.kr/main.do"
                                        target="_blank"
                                    >배화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/M0Q4eXNnQkc3eWhadHFZS0V0UG9CQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-049.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    배화역량인증 3급
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.baewha.ac.kr/main.do"
                                        target="_blank"
                                    >배화여자대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>



                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/SzhiR05oQ3JoS0FDeG1YbTdhelFoZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-048.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    ESG 캡스톤 디자인 전략
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/VDdCejFrd0JRdDcxV2E0bFZSZjZsZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-047.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Idea Thinking Process 이해 및 교수법 심화
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/cy9ZZGRxK1o2c1JCWWR3dUZ3VnFJQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-046.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    UST R&D 역량UP Summer Camp
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ust.ac.kr/kor/"
                                        target="_blank"
                                    >과학기술연합대학원대학교(UST)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Ukt5dVlDaVV6QWFxOThaSXAzdlRGZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-045.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    [디지털]서울청소년동행캠프
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.sbyouth.or.kr/"
                                        target="_blank"
                                    >서울시립성북청소년센터</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MFh2RnBpYnhjVUJiOGRMdTN6NVlpUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-044.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HTHT Entry(입문과정)증명서
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://educomasia.org/"
                                        target="_blank"
                                    >사단법인 아시아교육협회</a>
                                </div>
                            </div>
                        </div>
                    </li>   
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NXFnWkFHSTlVZ3hRRnA1VHpMTHB5Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-043.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023-1 WK HONORS CLUB
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.wku.ac.kr/"
                                        target="_blank"
                                    >원광대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>                 
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/RDJrSTRnWnVvbHdoL2JkNU5CZ01lUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-042.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    기술사업화 전문기관 역량강화 교육
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.innopolis.or.kr/inno"
                                        target="_blank"
                                    >연구개발특구진흥재단,한국연구산업협회</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MVBWeHBDRG94b1oxOUNhQTdBNThlQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-041.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SKHU 창업경진대회 수상
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.skhu.ac.kr/skhu/index.do"
                                        target="_blank"
                                    >성공회대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/Q29GK3dNMitjZEZaQm9Qa0NUUU4wQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-040.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Idea Thinking Process 이해 및 교수법
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.ic-pbl.com/"
                                        target="_blank"
                                    >IC-PBL공유·협력컨소시엄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/OGUvRDZuWTFwZU1naEVhSHdkT1FPdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-039.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Future 3급
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/QWZHeDJjd1dXR2JPbE1WMUJvNCtGdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-038.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HRD EXPO 2023 부스 방문 오픈배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/U2Q5b1krdEhZYjRiYmNZYmQ5QUlzQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-037.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023년 국외 한국어교원(K-티처) 프로그램
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://kcenter.korean.go.kr/kcenter/kteacher/info.do;jsessionid=BOTcCCjIcRoujETifK26Youc.node01?gubun=1"
                                        target="_blank"
                                    >국립국어원 (National Institute of Korean Language)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/WkpkVXNxeVpnajJNYVNtLzRuTytaQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-036.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스인문학(미래역량)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://cnsupertech-h.gne.go.kr/cnsupertech-h/main.do"
                                        target="_blank"
                                    >창녕슈퍼텍고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/SXUxTGZEa2Foam81SFQ1NE13b3d6Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-035.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    기능사 첫걸음
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://cnsupertech-h.gne.go.kr/cnsupertech-h/main.do"
                                        target="_blank"
                                    >창녕슈퍼텍고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/alBvMGJQYlFOSU1WNXJVVTBsK2w3dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-034.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    슈퍼배지 첫걸음
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://cnsupertech-h.gne.go.kr/cnsupertech-h/main.do"
                                        target="_blank"
                                    >창녕슈퍼텍고등학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NGZDWndLSTZ2UEhnRDJDZCtVeUY0UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-033.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Sharing HY TIP
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/LzVWVnF0NVdpYzRtdGN5cW1yWm80UT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-032.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Ready Associate Course 수료
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/c2Y3R1BtWWF6OEpOZ1lSUGY3aEVDUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-031.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Ready Basic Course 수료
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/UkdiMEs0aWJ4bmxHZ05FT1c4cEpSZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-030.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    한양여자대학교 학위증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hywoman.ac.kr/ko/index.do"
                                        target="_blank"
                                    >한양여자대학교(HANYANG WOMEN'S UNIVERSITY)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/YmlmaW81Nmk1UVJ2L2d0czZwSU1Mdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-029.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    휴넷 HRD 칼리지_ HRD Master 배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://hrd.hunet.co.kr/"
                                        target="_blank"
                                    >휴넷</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/cEFaSDBVbk5iaWJFOTNqWFBhL1k3dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-028.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    미래대학 콜로키움 3기
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://ikuc.org/index.jsp"
                                        target="_blank"
                                    >한국대학경쟁력연구원</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/NXdKenNvK1RsVVdmTTl3MlZIUjVWUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-027.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    ZOOM ZOOM English STEAM Camp
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.yoons.com/"
                                        target="_blank"
                                    >㈜현대영어사</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/ckR0RVlZSzhNcmtxN1M0b1ltWm43dz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-026.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    ZOOM ZOOM English Writing Camp
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.yoons.com/"
                                        target="_blank"
                                    >㈜현대영어사</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/ajdUVy9pUmdjNytCQk8yOHNLd0d0Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-024.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023년 오픈배지 발행 체험 증명
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/bU52dkxzdHRVdE1wNWxobzNwcGwxZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-023.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2023년 오픈배지 세미나 참가증명
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MjNFOEU2YlZlREFlZjYwNjFCQkd0QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-022.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    학생성공 e-포트폴리오 공모전 수상자
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.skku.edu/skku/index.do"
                                        target="_blank"
                                    >성균관대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MWdEOWpYbDJ2aXZpdEprWHpJVE9wdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-021.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SG MAPLE(SKKU Global Mentoring and Assisting PeoPLE)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.skku.edu/skku/index.do"
                                        target="_blank"
                                    >성균관대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/cXlrYlRhV3l1VnhFUFpzeEl4SXRjUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-020.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SKKU 글로벌창조적챌린저
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.skku.edu/skku/index.do"
                                        target="_blank"
                                    >성균관대학교</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/VGdyNW13MHB0NGNNVk41KzZXNVhGZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-019.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Professional
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/MUw4UVJXeWxmb001cTlQM3o1cW5qZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-018.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Associate
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>


                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/unAcquired/N3NyNEF1dGNpL1QwTXZqUERhQ2VQdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-017.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    AICE Basic
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://aice.study/main"
                                        target="_blank"
                                    >(주)케이티, (주)한국경제신문</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/R3VqSFdDOXluVUxrckJZRmpvWTZOUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-016.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스윤리전문가
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://metaversity.camp/"
                                        target="_blank"
                                    >사단법인 한국고등직업교육학회＆주식회사 마이크로러닝</a>
                                </div>
                            </div>
                        </div>
                    </li>
                    
                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/S2JhY3VSdjRUR1JRby9JcHRlaXN2QT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-015.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스교육전문가(2급)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://metaversity.camp/"
                                        target="_blank"
                                    >사단법인 한국고등직업교육학회＆주식회사 마이크로러닝</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/ODh4cExpZVZvYXA2S21WUVF5VXhFQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-014.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스교육전문가(1급)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://metaversity.camp/"
                                        target="_blank"
                                    >사단법인 한국고등직업교육학회＆주식회사 마이크로러닝</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/aDE5d1lzMmpId2J0YUliYlA3MUJPUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-013.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스콘텐츠크리에이터(2급)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://metaversity.camp/"
                                        target="_blank"
                                    >사단법인 한국고등직업교육학회＆주식회사 마이크로러닝</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/Y1kzN0FTcGpQemhWNGdHdFpCRzVOdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-012.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    메타버스콘텐츠크리에이터(1급)
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://metaversity.camp/"
                                        target="_blank"
                                    >사단법인 한국고등직업교육학회＆주식회사 마이크로러닝</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/bDlQSm1UTXJnK3ZhTVdQMTdQVUpXdz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-011.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    GOLD BADGE
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/YW5EVSt6S2dCdCtLWitLYVNMMW1NUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-010.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    SILVER BADGE
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/SFpYb2NMRE5uaVpnTDNDQ2hkcVJJQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-009.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    BRONZE BADGE
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/UmgxYW1lSVZobmVzSitiQ2xxMjhRQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-008.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    GREEN BADGE
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/Vi9PWkg4V3YxNmRyZ2JCV25yTU9Ldz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-007.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    VRWARE Edu 지도사 3급
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://vrware.co.kr/"
                                        target="_blank"
                                    >㈜글로브포인트</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/S3JzamVKbUhwTm9QVGtxZWIvejB0Zz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-006.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HY Meister Degree – Semiconductor
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'edu' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/Uk9KanFCaUhPLy9uQ2txMUhxcG9xZz09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-005.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    HY Meister Degree – Semiconductor
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.hanyang.ac.kr/"
                                        target="_blank"
                                    >한양대학교 교육혁신단 교육혁신팀(EDUCATION INNOVATION Division)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/WHR5WEc1NVN3Q1FnWDYrRk5PNWtNUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-004.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    2022 인천 이스포츠 코치양성교육 수료 인증
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="http://www.tml.kr/"
                                        target="_blank"
                                    >더매치랩</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'group' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/ns/portal/openbadge/public/assertions/detail/VHoxZTRCS0RuMVY0MzVTTm8wbEJpQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-003.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    Certificate of EDUCON 2022
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://www.educationkorea.kr/"
                                        target="_blank"
                                    >에듀콘 조직위원회</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="selectdCategory == 'corp' || selectdCategory == 'all'"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/MlNuYUlWSDJoVzJURjlkNk02Uk1wUT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-001.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    레코스(LecoS) 샘플배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="false"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--orange">
                            {{ $t('service.badgeList.category.corp') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://www.openbadge-global.com/api/v1.0/openBadge/v2/Wallet/Public/GetAssertionShare/RjJZOENCQzd2YjJzVGtJZ1NZZ1NGQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-002.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    네트러닝그룹 샘플배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="false"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--blue">
                            {{ $t('service.badgeList.category.edu') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://nlp.netlearning.co.jp/ns/portal/openbadge/#/public/assertions/detail/RjJZOENCQzd2YjJzVGtJZ1NZZ1NGQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-002.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    네트러닝그룹 샘플배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc)</a>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="false"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--green">
                            {{ $t('service.badgeList.category.group') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://nlp.netlearning.co.jp/ns/portal/openbadge/#/public/assertions/detail/RjJZOENCQzd2YjJzVGtJZ1NZZ1NGQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-002.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    네트러닝그룹 샘플배지
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    주식회사 레코스 (LecoS,Inc)
                                </div>
                            </div>
                        </div>
                    </li>

                    <li
                        v-if="false"
                        class="board-list__item"
                    >
                        <div class="catetory-name le-text-body3 catetory-name--grey">
                            {{ $t('service.badgeList.category.etc') }}
                        </div>

                        <div class="board-list__box">
                            <div class="board-list__thumb">
                                <a
                                    href="https://nlp.netlearning.co.jp/ns/portal/openbadge/#/public/assertions/detail/RjJZOENCQzd2YjJzVGtJZ1NZZ1NGQT09"
                                    target="_blank"
                                >
                                    <img :src="require(`@/assets/img/badge-list-thumb/badge-list-thumb-002.png`)">
                                </a>
                            </div>

                            <div class="board-list__info">
                                <div class="board-list__title le-text-body2">
                                    긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄 긴줄긴줄
                                </div>

                                <div class="board-list__issuer le-text-body3">
                                    <a
                                        href="https://lecos.co.kr/"
                                        target="_blank"
                                    >주식회사 레코스 (LecoS,Inc) 긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄긴줄</a>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <!-- // board-list -->

                <!-- ANCHOR: 배지 없음 -->
                <div
                    v-if="isBoardData"
                    class="no-data"
                >
                    {{ $t('board.noData') }}
                </div>
                <!-- // 배지 없음 -->
            </div>
        </section>
    </div>
    <!-- !SECTION: Template -->
</template>

<script>
import { getBadgesGalleryMetaInfo } from '@/utils/metaInfo.js';
import PageTitle from '@/components/PageTitle.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';

export default {
    name: 'BadgesGalleryPage',
    components: {
        PageTitle,
        Swiper,
        SwiperSlide
    },

    metaInfo() {
        return getBadgesGalleryMetaInfo(this);
    },

    // SECTION: Data Properties
    data: function () {
        return {
            selectdCategory: this.$route.query.category || 'all',
            isBoardData: null,

            swiperBoardTab: {
                freeMode: true,
                direction: 'horizontal',
                slidesPerView: 'auto',
                centeredSlides: false,
                spaceBetween: 0,
            },
            tabs: [
                { label: '전체', category: 'all', route: { name: 'BadgesGalleryPage', query: { category: 'all' } } },
                { label: '교육기관', category: 'edu', route: { name: 'BadgesGalleryPage', query: { category: 'edu' } } },
                { label: '기업', category: 'corp', route: { name: 'BadgesGalleryPage', query: { category: 'corp' } } },
                { label: '단체', category: 'group', route: { name: 'BadgesGalleryPage', query: { category: 'group' } } },
                { label: '그외', category: 'etc', route: { name: 'BadgesGalleryPage', query: { category: 'etc' } } }
            ],

            search: this.$route.query.search || '', // 검색어
            hasResults: true, // 검색 결과의 존재 여부
        };
    },
    // !SECTION: Data Properties

    // SECTION: Lifecycle Hooks
    watch: {
        $route(to, form) {
            if (to.query !== form.query) {
                this.selectdCategory = to.query.category || 'all';
                this.search = to.query.search || '';
                this.filterBadges();
            }
        },

        search() {
            // 검색어가 변경될 때 URL 쿼리 문자열을 업데이트
            this.updateQuery();
        }
    },

    created() {
        //
    },

    mounted() {
        this.filterBadges();
    },

    beforeDestroy() {
        //
    },
    // !SECTION: Lifecycle Hooks

    // SECTION: Methods
    methods: {
        /* ============================================================
         * ANCHOR: 주석
         * ============================================================ */
        updateCategoryAndFilter() {
            const boardListDataLength = document.querySelectorAll('.board-list li').length;
            this.isBoardData = boardListDataLength == 0;
            this.search = '';
            this.filterBadges();
            this.updateQuery();
        },

        shouldShowBadge(completeText) {
            const search = this.search.toLowerCase();
            const completeTextLower = completeText.toLowerCase();

            // 정규 표현식을 사용하여 연속된 문자열 검색
            const regex = new RegExp(search, 'i'); // 'i' 옵션은 대소문자를 무시하도록 설정
            return regex.test(completeTextLower);
        },

        // 검색어 입력 시 호출되는 메서드
        filterBadges() {     
            // 검색어가 변경될 때 shouldShowBadge 함수를 호출하고,
            // 배지의 표시 여부를 업데이트합니다.
            const badgeItems = document.querySelectorAll('.board-list__item');
            let hasResults = false; // 검색 결과 여부를 초기에 false로 설정

            badgeItems.forEach(item => {
                const badgeName = item.querySelector('.board-list__title').textContent;
                const issuerName = item.querySelector('.board-list__issuer').textContent;
                const shouldDisplay = this.shouldShowBadge(badgeName + ' ' + issuerName);
                item.style.display = shouldDisplay ? 'flex' : 'none';

                if (shouldDisplay) {
                    hasResults = true; // 검색 결과가 있을 경우 hasResults를 true로 설정
                }
            });

            this.hasResults = hasResults; // 검색 결과 여부를 데이터 속성에 업데이트
        },

        clearSearch() {
            this.search = '';
            this.filterBadges(); 
        },

        updateQuery() {
            // URL 쿼리 문자열을 업데이트
            this.$router.push({
                query: {
                    category: this.selectdCategory,
                    search: this.search || '', // 검색어 업데이트
                },
            }).catch(()=>{});
        }
    },
    // !SECTION: Methods
};
</script>
