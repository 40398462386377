var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sub-page"},[_c('PageTitle',{attrs:{"main-title":"Learning eco-System","sub-title":"오픈배지의 혁신,<br> 레코스가 앞장 섭니다.","bg-color":"blue9"}}),_c('section',{staticClass:"c-section bg-blue9 d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('v-row',{staticClass:"justify-end align-center"},[_c('v-col',{staticClass:"d-flex",class:{
                        'justify-end': _vm.$vuetify.breakpoint.lgAndUp,
                        'justify-center': _vm.$vuetify.breakpoint.mdAndDown,
                    },attrs:{"cols":"12","lg":"6","md":"5"}},[_c('v-img',{staticClass:"v-img-w-full",attrs:{"src":require('@/assets/img/about-company-1.png'),"max-width":"519","alt":""}})],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-col',{attrs:{"cols":"12","md":"1"}}):_vm._e(),_c('v-col',{class:{
                        'mt-15': _vm.$vuetify.breakpoint.smAndDown,
                    },attrs:{"cols":"12","lg":"5","md":"6"}},[_c('p',{staticClass:"le-text-body1"},[_vm._v(" (주)레코스는 2021년 8월, 국내 최초로 설립된 국제표준 디지털배지(오픈배지) 서비스 전문 기업으로 한국 및 아시아의 오픈배지 서비스를 주도하고 있습니다. ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 아시아 최초 1EdTech의 국제표준 오픈배지 2.0과 3.0 인증을 취득하였고 국내 및 아시아 최다 발행인과 수령인을 보유하고 있습니다. ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 학습의 에코 시스템을 제공하는 기관과 학습자의 만남의 장. ")]),_c('br'),_c('p',{staticClass:"le-text-body1"},[_vm._v(" 자신의 스킬을 발휘하고 싶은 분과 풍부한 지식을 가진 인재를 요구하는 분들의 만남의 장. ")]),_c('br'),_c('p',{staticClass:"le-heading6"},[_vm._v(" 오픈배지가 필요한 모든 순간에 레코스가 함께합니다. ")])])],1)],1)]),_c('section',{staticClass:"c-section d-flex justify-center"},[_c('div',{staticClass:"c-contanier"},[_c('h2',{staticClass:"text-center le-heading3 c-title"},[_vm._v(" 레코스 찾아오시는 길 ")]),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"cols":"12","lg":"8","md":"10"}},[_c('div',{staticStyle:{"width":"100%","height":"500px"},attrs:{"id":"map"}}),_c('div',{staticClass:"d-flex flex-column",class:{
                            'gap-y-10': _vm.$vuetify.breakpoint.mdAndUp,
                            'gap-y-9': _vm.$vuetify.breakpoint.smOnly,
                            'mt-13': _vm.$vuetify.breakpoint.smAndUp,
                            'mt-10 gap-y-6': _vm.$vuetify.breakpoint.xsOnly,
                        }},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" 서울시 마포구 마포대로 86, 4층 421호 (도화동, 창강빌딩) ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-2.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" 02-716-5571 ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-3.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" 버스 이용시 ")]),_c('p',{staticClass:"mt-3 le-text-body2"},[_vm._v(" 160, 260, 261, 463, 600, 173, 740, 604, 7013, 1711, 7613, 7611 - 공덕역 하차 ")])])]),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-5"},[_c('v-img',{attrs:{"src":require('@/assets/img/about-map-4.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 48 : (_vm.$vuetify.breakpoint.smOnly ? 48 : 40),"alt":""}})],1),_c('div',{staticClass:"pt-2"},[_c('p',{staticClass:"le-heading6"},[_vm._v(" 지하철 이용시 ")]),_c('ul',{staticClass:"mt-3 le-text-body2 list-type-1"},[_c('li',[_vm._v("5호선, 6호선 - 공덕역 8번 출구")]),_c('li',[_vm._v("공항철도, 경의선 - 공덕역 9번 출구")])])])])])])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }