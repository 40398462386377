var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-100 bg-gray7 d-flex align-center justify-center"},[_c('div',[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/img/404-item-1.png'),"width":_vm.$vuetify.breakpoint.mdAndUp ? 223 : (_vm.$vuetify.breakpoint.smAndDown ? 179 : 40),"alt":""}})],1)]),_c('p',{staticClass:"le-heading3 text-center",class:{
                'mt-10': _vm.$vuetify.breakpoint.smAndUp,
                'mt-8': _vm.$vuetify.breakpoint.xsOnly
            }},[_vm._v(" 죄송합니다."),_c('br'),_vm._v(" 페이지를 찾을 수 없습니다. ")]),_c('div',{staticClass:"d-flex align-center justify-center",class:{
                'mt-16': _vm.$vuetify.breakpoint.smAndUp,
                'mt-11': _vm.$vuetify.breakpoint.xsOnly
            }},[_c('v-btn',{staticClass:"text-white1",attrs:{"to":{ name: 'HomePage' },"color":"orange1","large":_vm.$vuetify.breakpoint.mdAndUp,"depressed":"","exact":""}},[_vm._v(" 메인으로 ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }